import ReactDom from 'react-dom';
import './LayouEntry';
import '../pages';
import { entryPoints } from './registerEntrypoint';
import { isDevEnv } from '@f2w/utils';
import { AppRouter } from './router';
const appRoot = document.getElementById('fwAppRoot');
if (appRoot) {
    ReactDom.render(AppRouter(), appRoot);
}
else {
    if (isDevEnv())
        console.log('render entrypoints', entryPoints);
    for (let meta of Object.values(entryPoints)) {
        ReactDom.render(meta.render(), meta.el);
    }
}
