import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { Button, ButtonToolbar, DropdownButton } from 'Components/Atoms/Button';
import { Icon } from 'Components/Atoms/Icon';
import { translate } from 'Services/Translator';
import Actions from 'Pages/Person/Overview/components/PersonDataTableActions';
import { Dropdown } from 'Atoms/Dropdown';
import { ExcelIcon2, PdfIcon2 } from 'Components/App/Icons';
import { PhoneType } from '@f2w/form';
const BaseTable = Table.factory()(() => ({
    tableId: 'Person',
    manualControl: true,
    BulkActions: ({ openRenterMirrorTenantListExportForm }) => {
        return (_jsx(ButtonToolbar, { children: _jsxs(DropdownButton, { label: translate('document.rent.renterMirror.general.title', {}, 'documents'), children: [_jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorTenantListExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.tenantListReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorTenantListExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.tenantListReportPdf.title', {}, 'documents')] })] }) }));
    },
    Actions: ({ onCreatePerson }) => (_jsx("div", { children: _jsxs(Button, { title: translate('person.new'), onClick: () => {
                onCreatePerson();
            }, children: [_jsx(Icon, { icon: 'plus' }), _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('new') })] }) })),
    RowActions: Actions,
    useSortBy: {
        disableMultiSort: false,
        initialState: {
            sortBy: [
                {
                    id: 'name',
                    desc: false
                }
            ]
        }
    },
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $actions: {
            sticky: 'left',
            style: {
                left: 52,
            }
        },
        name: {
            Header: translate('person.name'),
            accessor: 'name',
            useSortBy: {},
            width: 220,
            Cell: ({ onViewPerson, value, row: { original: { id } } }) => (_jsx("a", { href: "#", onClick: (e) => { e.preventDefault(); onViewPerson(id); }, children: value }))
        },
        email: {
            Header: translate('person.email'),
            accessor: 'email',
            Cell: ({ value }) => (_jsx("a", { href: 'mailto:' + value, children: value })),
        },
        phoneNumber: {
            Header: translate('person.phoneNumber'),
            accessor: 'phone',
            width: 180,
            Cell: ({ value }) => value ? _jsx("a", { href: `tel: ${value}`, children: PhoneType.useFormat(value) }) : _jsx(_Fragment, {})
        },
        street: {
            Header: translate('address.street'),
            accessor: ({ address }) => address
                ? `${address.street} ${address.houseNumber}`
                : '',
            useSortBy: {}
        },
        postalCode: {
            Header: translate('address.zip'),
            accessor: ({ address }) => address?.postalCode,
            useSortBy: {}
        },
        city: {
            Header: translate('address.city'),
            accessor: ({ address }) => address?.city,
            useSortBy: {}
        },
        country: {
            Header: translate('address.country'),
            accessor: ({ address }) => address?.country,
            Cell: ({ value }) => value ? translate(`country.${value}`) : '',
            useSortBy: {}
        }
    },
}));
export const PersonDataTable = Object.assign(BaseTable, {
    use: ({ onEditPerson, onDeletePerson, onCreatePerson, onViewPerson, listPersons, openRenterMirrorTenantListExportForm, getPersonAccountStatementReport }) => {
        return PersonDataTable.useTable({
            onCreatePerson,
            onViewPerson,
            onEditPerson,
            onDeletePerson,
            openRenterMirrorTenantListExportForm,
            getPersonAccountStatementReport
        }, () => ({
            getData: ({ request }) => listPersons(request),
        }));
    }
});
