import { Palette, sizeToken, tokenFromProps } from '@f2w/theme';
import { inObject, isObject } from '@f2w/utils';
export const ButtonColorTokens = {
    ...Palette.tokenMap,
};
export const buttonSizeToken = sizeToken.extend({
    xs: {
        styles: (prev) => ({
            ...prev,
        }),
    },
    sm: {
        styles: (prev) => ({
            ...prev,
        }),
    },
    md: {
        styles: (prev) => ({
            ...prev,
        }),
    },
    lg: {
        styles: (prev) => ({
            ...prev,
        }),
    },
});
export function getButtonVariant(...args) {
    const props = args.shift();
    let variant;
    if (inObject(buttonTokens, props))
        return props;
    if (isObject(props))
        variant = tokenFromProps(buttonTokens, props);
    if (!variant && args.length)
        return getButtonVariant(...args);
    return variant;
}
export function getButtonVariantStyles(...args) {
    const variantName = getButtonVariant(...args);
    return buttonTokens[variantName] ?? buttonTokens.fill;
}
const buttonTokens = {
    neutral: ({ palette: { neutral } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500 },
        hover: { color: color.$800 },
        disabled: { color: neutral.$300 },
    } : {
        base: { color: neutral.$500 },
        hover: { color: color.$500 },
        disabled: { color: neutral.$300 },
    }),
    link: ({ palette: { neutral: disabledColor } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500 },
        hover: { color: color.$800 },
        disabled: { color: disabledColor.$300 },
    } : {
        base: { color: color.$500 },
        hover: { color: color.$800 },
        disabled: { color: disabledColor.$300 },
    }),
    textFill: ({ palette: { neutral: disabledColor, white } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$800, background: 'transparent' },
        hover: { color: color.$50, background: color.$500 },
        active: { color: color.$50, background: color.$500 },
        disabled: { color: disabledColor.$300 },
    } : {
        base: { color: 'inherit', background: 'var(--fw-btn-background, transparent)' },
        hover: { color: white, background: color.$500 },
        active: { color: white, background: color.$500 },
        disabled: { color: disabledColor.$300 },
    }),
    linkFill: ({ palette: { neutral: disabledColor, white } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$800, background: 'transparent' },
        hover: { color: color.$800, background: color.$100 },
        active: { color: color.$50, background: color.$800 },
        disabled: { color: disabledColor.$300 },
    } : {
        base: { color: color.$500, background: 'var(--fw-btn-background, transparent)' },
        hover: { color: color.$600, background: color.$50 },
        active: { color: color.$600, background: color.$100 },
        disabled: { color: disabledColor.$300 },
    }),
    outline: ({ palette: { neutral: disabledColor } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500, background: 'transparent', borderColor: color.$500 },
        hover: { color: color.$600, background: color.$50, borderColor: color.$500 },
        active: { color: color.$700, background: color.$100, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    } : {
        base: { color: color.$500, background: 'var(--fw-btn-background, transparent)', borderColor: color.$500 },
        hover: { color: color.$600, background: color.$50, borderColor: color.$500 },
        active: { color: color.$700, background: color.$100, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    }),
    outlineFill: ({ palette: { neutral: disabledColor } }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: color.$500, background: 'transparent', borderColor: color.$500 },
        hover: { color: color.$700, background: color.$50, borderColor: color.$500 },
        active: { color: color.$50, background: color.$500, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    } : {
        base: { color: color.$500, background: 'var(--fw-btn-background, transparent)', borderColor: color.$500 },
        hover: { color: color.$700, background: color.$50, borderColor: color.$500 },
        active: { color: color.$50, background: color.$500, borderColor: color.$500 },
        disabled: { color: disabledColor.$300, borderColor: disabledColor.$300 },
    }),
    fill: ({ palette: { neutral: disabledColor }, palette }, { color, dark }) => color && buttonStyles(dark ? {
        base: { color: palette.white, background: color.$500, borderColor: color.$500 },
        hover: { color: palette.white, background: color.$600, borderColor: color.$600 },
        active: { color: palette.white, background: color.$600, borderColor: color.$600 },
        disabled: { color: disabledColor.$300, background: disabledColor.$100, borderColor: disabledColor.$100 },
    } : {
        base: { color: palette.white, background: color.$500, borderColor: color.$500 },
        hover: { color: palette.white, background: color.$600, borderColor: color.$600 },
        active: { color: palette.white, background: color.$600, borderColor: color.$600 },
        disabled: { color: disabledColor.$300, background: disabledColor.$100, borderColor: disabledColor.$100 },
    }),
};
export { buttonTokens as ButtonVariantTokens };
const SelectorTypes = {
    disabled: '.disabled, :disabled, [aria-disabled=true], [aria-busy=true]',
    active: ':active, .active, [aria-selected=true]',
    hover: ':hover',
    focus: ':focus, .focus',
};
const Selector = {
    join: (...names) => {
        return names.flatMap((name, b, c) => {
            return SelectorTypes[name] ?? [];
        }).join(',');
    },
    is: (...names) => {
        return `:is(${Selector.join(...names)})`;
    },
    not: (...names) => {
        return `:not(${Selector.join(...names)})`;
    },
    isNot: (...names) => {
        return `:not(${Selector.is(...names)})`;
    },
};
export { Selector as ButtonSelector };
function buttonStyles({ base, hover, focus, disabled, active, activeFocus }) {
    return {
        ...base,
        [`${Selector.is('hover')}`]: hover || base,
        [`${Selector.is('focus')}`]: focus || hover || base,
        [`${Selector.is('disabled')}`]: {
            opacity: .8,
            ...(disabled || base),
        },
        [`${Selector.isNot('disabled')}${Selector.is('active')}, .show > &.fw-dropdown-toggle`]: {
            ...(active || hover || base),
            [`${Selector.is('focus')}`]: activeFocus || focus || active || hover || base,
        },
    };
}
export const ButtonColorDeprecated = {
    '': ({ palette: { dark: color } }) => buttonStyles({
        base: { background: 'transparent', borderColor: 'transparent', color: color.$600 },
        hover: { background: 'transparent', borderColor: 'transparent', color: color.$400 },
        active: { background: 'transparent', borderColor: 'transparent', color: color.$700 },
        disabled: { background: 'transparent', borderColor: 'transparent', color: color.$100 },
    }),
    primary: (t) => buttonTokens.fill(t, { color: t.palette.primary }),
    'primary-outline': (t) => buttonTokens.outline(t, { color: t.palette.primary }),
    secondary: (t) => buttonTokens.fill(t, { color: t.palette.secondary }),
    'secondary-outline': (t) => buttonTokens.outline(t, { color: t.palette.secondary }),
    dark: (t) => buttonTokens.fill(t, { color: t.palette.dark }),
    'dark-outline': (t) => buttonTokens.outline(t, { color: t.palette.dark }),
    success: (t) => buttonTokens.fill(t, { color: t.palette.success }),
    warning: (t) => buttonTokens.fill(t, { color: t.palette.warning }),
    error: (t) => buttonTokens.fill(t, { color: t.palette.error }),
    'error-outline': (t) => buttonTokens.outline(t, { color: t.palette.error }),
    link: ({ palette }) => buttonStyles({
        base: {
            color: palette.primary.$500,
        },
        hover: {
            color: palette.primary.$700,
        },
    }),
};
