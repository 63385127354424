var ValueTypeName;
(function (ValueTypeName) {
    ValueTypeName["string"] = "string";
    ValueTypeName["number"] = "number";
    ValueTypeName["date"] = "date";
    ValueTypeName["boolean"] = "boolean";
    ValueTypeName["object"] = "object";
    ValueTypeName["array"] = "array";
})(ValueTypeName || (ValueTypeName = {}));
export {};
