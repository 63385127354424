import { translate } from "Services/App";
export var Gender;
(function (Gender) {
    Gender["MALE"] = "male";
    Gender["FEMALE"] = "female";
    Gender["UNSPECIFIED"] = "unspecified";
})(Gender || (Gender = {}));
export function translateGender(gender) {
    switch (gender) {
        case Gender.MALE:
            return translate('person.gender.male');
        case Gender.FEMALE:
            return translate('person.gender.female');
        default:
            return translate('person.gender.unspecified');
    }
}
