import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { ButtonToolbar } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { useTheme } from '@f2w/theme';
import { FlexRow } from 'Atoms/Layout/Flex';
import classnames from 'classnames';
export function Callout({ message, children, actions = children, type, ...props }) {
    const { color: _color, colorBg: _colorBg, icon: _icon, onClose, ...rest } = Callout.variants.use(type, props);
    return (_jsxs(Callout.Root, { ...rest, onClick: onClose, className: classnames({
            'fw-callout--close': !!onClose
        }), style: {
            '--callout-color': _color,
            '--callout-color-bg': _colorBg,
        }, children: [_jsxs(FlexRow, { inline: true, gap: 16, children: [_icon && _jsx("div", { className: 'fw-callout--icon', children: _jsx(Icon, { icon: _icon }) }), _jsx("div", { className: 'fw-callout--message', onClick: e => {
                            e.stopPropagation();
                        }, children: message })] }), actions && _jsx(ButtonToolbar, { variant: 'fill', color: 'dark', children: actions })] }));
}
(function (Callout) {
    Callout.variants = useTheme.variantFactory((theme) => ({
        color: theme.palette.primary.$500,
        colorBg: theme.palette.primary.$50,
    }), {
        success: t => ({
            icon: 'check',
            color: t.palette.success.$500,
            colorBg: t.palette.success.$50,
        }),
        warning: t => ({
            icon: 'alert-triangle',
            color: t.palette.warning.$500,
            colorBg: t.palette.warning.$50,
        }),
        error: t => ({
            icon: 'alert-circle',
            color: t.palette.error.$500,
            colorBg: t.palette.error.$50,
        }),
    });
    Callout.Root = styled.div(({ onClick: close, theme: { palette, typography }, }) => {
        return css `
          position: relative;
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: wrap;

          padding: 16px;
          max-width: 100%;
          gap: 24px;

          color: var(--callout-color, var(--fw-color-primary-50));
          background: var(--callout-color-bg, var(--fw-color-primary-50));
          transition: background-color ease-in-out 250ms;
          border-radius: 6px;

          > .fw-callout--close_icon {
              position: absolute;
              right: 0;
              top: 0;
              z-index: 100;
              padding: 6px 6px 15px 15px;
              font-size: 24px;
              color: ${palette.neutral.$500};
              transition: color ease-in-out 250ms;
          }
          ${ButtonToolbar.Root} {
            align-items: end;
            align-self: end;
          }
          &.fw-callout--close {
              &:hover {
                cursor: pointer;
                > .fw-callout--close_icon {
                  color: ${palette.neutral.$300};
                }
              }
          }

          > p {
            margin: 0
          }

          .fw-callout--message {
            ${typography.medium.fontStyles};
            cursor: default;
          }

          .fw-callout--icon {
            font-size: 20px;
            height: 20px;
            min-width: 1em;
            > svg {
                vertical-align: baseline;
            }
          }
        `;
    });
})(Callout || (Callout = {}));
