import { jsx as _jsx } from "react/jsx-runtime";
import { FinancialAccountStatementModal } from "Pages/Accounting/components/FinancialAccountStatementModal";
import { useEffect, useState } from "react";
import { FinancialAccountServices } from "Pages/Accounting/services";
export const FinancialAccountStatement = ({ statementData, onClose, initialDateRange, initialConfig, getFinancialAccountStatement = FinancialAccountServices.getFinancialAccountStatement, }) => {
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [financialAccountStatement, setFinancialAccountStatement] = useState();
    useEffect(() => {
        if (statementData) {
            void getFinancialAccountStatement(statementData.financialAccountId, statementData.fiscalYearId, dateRange).then(data => setFinancialAccountStatement(data));
        }
        else {
            setFinancialAccountStatement(undefined);
        }
    }, [statementData, dateRange]);
    return _jsx(FinancialAccountStatementModal, { financialAccountStatement: financialAccountStatement, onClose: () => {
            setFinancialAccountStatement(undefined);
            onClose();
        }, dateFilterConfig: {
            value: dateRange,
            onDateFilterChange: setDateRange,
            ...initialConfig
        } });
};
