import { useFormModal } from '@f2w/form';
import { translate } from 'Services/Translator';
import { AccountingRecordType } from 'Pages/Accounting/AccountingRecord/form/AccountingRecordType';
import { useAccountingPageContext } from '../../AccountingPageContext';
import { dispatchCustomEvent } from "Services/EventManager";
const useAccountingRecordForm = ({ onSave, createAccountingRecord, updateAccountingRecord, getAccountingRecordById, propertyId }) => {
    const { fiscalYears: maxDateRange } = useAccountingPageContext();
    return useFormModal({
        name: 'accounting-record-form',
        type: () => new AccountingRecordType({ maxDateRange }),
        title: ({ values }) => {
            return values?.id
                ? translate('accountingRecord.edit')
                : translate('accountingRecord.create');
        },
        onLoad: async (id) => {
            if (id) {
                return getAccountingRecordById(id);
            }
            return null;
        },
        onSave: (data, utils, type) => {
            const result = data.id
                ? updateAccountingRecord(data.id, data)
                : createAccountingRecord({ ...data, propertyId });
            result.then((response) => {
                data.id
                    ? dispatchCustomEvent('fw.accounting.recordUpdated', {
                        id: data.id,
                    })
                    : dispatchCustomEvent('fw.accounting.recordCreated', {
                        id: response.data.id,
                    });
            });
            return result;
        },
    });
};
export default useAccountingRecordForm;
