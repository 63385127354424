import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import AccountingRecordServices from "Pages/Accounting/AccountingRecord/services/accountingRecordServices";
import { translate } from "Services/Translator";
import FinancialAccountGroups from "Pages/Accounting/AccountingRecord/FinancialAccountGroups";
import EmptyRecordsFilter from "Pages/Accounting/AccountingRecord/EmptyRecordsFilter";
import BalanceSection from "Pages/Accounting/AccountingRecord/components/BalanceSection";
import { Div, FlexBox, FlexCol } from "Components/Atoms";
import OpeningBalanceProvider from "Pages/Accounting/OpeningBalance/OpeningBalanceContext";
import { useOpeningBalanceForm } from 'Pages/Accounting/OpeningBalance/OpeningBalanceForm';
import { Table } from '@f2w/data-table/table-types';
import { useAccountingPageContext } from '../AccountingPageContext';
import { FormModal } from '@f2w/form';
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from "Services/EventManager";
import { FinancialAccountStatement } from "Pages/Accounting/components/FinancialAccountStatement";
const IncomeStatementPage = ({ propertyId, fiscalYearId, isReportingFiscalYear, initialDateRange, accountingRecordServices }) => {
    const { fiscalYear } = useAccountingPageContext();
    const [dateFilter, setDateFilter] = useState(initialDateRange);
    const [incomeStatement, setIncomeStatement] = useState();
    const [filterEmpty, setFilterEmpty] = useState(true);
    const [statementData, setStatementData] = useState();
    const loadData = async () => {
        const incomeStatement = await accountingRecordServices.getIncomeStatement(propertyId, fiscalYearId, dateFilter);
        setIncomeStatement(incomeStatement);
    };
    const incomeForm = useOpeningBalanceForm({
        accountingRecordServices: AccountingRecordServices,
        onSaved: loadData
    });
    const updateOnOpeningBalanceUpdated = (e) => {
        loadData();
    };
    useEffect(() => {
        subscribeToCustomEvent('fw.accounting.openingBalanceUpdated', updateOnOpeningBalanceUpdated);
        return () => {
            unsubscribeFromCustomEvent('fw.accounting.openingBalanceUpdated', updateOnOpeningBalanceUpdated);
        };
    }, []);
    useEffect(() => {
        loadData();
    }, [dateFilter.from, dateFilter.to]);
    if (!incomeStatement) {
        return null;
    }
    const hasNotEmptyRevenue = incomeStatement.revenue.filter(accountGroup => !accountGroup.emptyGroup).length > 0;
    const hasNotEmptyExpenditure = incomeStatement.expenditure.filter(accountGroup => !accountGroup.emptyGroup).length > 0;
    return (_jsxs(OpeningBalanceProvider, { disableOpeningBalanceModification: isReportingFiscalYear, accountingRecordServices: AccountingRecordServices, openForm: incomeForm.openForm, children: [_jsxs(FlexBox, { gap: 50, children: [_jsxs(FlexCol, { flex: 1, children: [_jsx(Div, { mb: 20, style: { position: "relative", zIndex: 30 }, children: _jsx(Table.filters.DateRangeFilter, { filterValue: dateFilter, onChange: setDateFilter, min: fiscalYear?.starts, max: fiscalYear?.ends }) }), _jsx(EmptyRecordsFilter, { name: "income_select_account", onChange: setFilterEmpty })] }), _jsx(FlexCol, { flex: 1, children: _jsx(IncomeStatementBalanceSection, { balance: incomeStatement.balance }) })] }), _jsxs("div", { children: [(filterEmpty && !hasNotEmptyRevenue && !hasNotEmptyExpenditure)
                        && _jsx("div", { style: { marginTop: 50, textAlign: 'center' }, children: translate('accounting.noDataAvailable') }), (hasNotEmptyRevenue || !filterEmpty) && (_jsx(FinancialAccountGroups, { openFinancialAccountStatement: (financialAccountId) => setStatementData({
                            financialAccountId,
                            fiscalYearId
                        }), title: translate('accounting.group.ertrag'), accountGroups: incomeStatement.revenue, fiscalYear: incomeStatement?.fiscalYear, dateFilter: dateFilter, filterEmpty: filterEmpty })), (hasNotEmptyExpenditure || !filterEmpty) && (_jsx(FinancialAccountGroups, { openFinancialAccountStatement: (financialAccountId) => setStatementData({
                            financialAccountId,
                            fiscalYearId
                        }), title: translate('accounting.group.aufwand'), accountGroups: incomeStatement.expenditure, fiscalYear: incomeStatement?.fiscalYear, dateFilter: dateFilter, filterEmpty: filterEmpty }))] }), _jsx(FormModal, { modal: incomeForm, size: { maxWidth: '455px' } }), statementData && (_jsx(FinancialAccountStatement, { statementData: statementData, onClose: () => setStatementData(undefined), initialConfig: {
                    type: 'range',
                    min: fiscalYear?.starts,
                    max: fiscalYear?.ends
                }, initialDateRange: dateFilter }))] }));
};
const IncomeStatementBalanceSection = ({ balance }) => (_jsx(BalanceSection, { titledAmounts: [
        { title: translate('accounting.totalRevenue'), amount: balance.revenue },
        { title: translate('accounting.totalExpenditure'), amount: balance.expenditure },
        { title: translate('accounting.profit') + ' +/-', amount: balance.profit, important: true },
    ] }));
export default IncomeStatementPage;
