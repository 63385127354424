import client from "Utils/client";
import generateRoute, { generate } from "Services/Router";
export var RentServices;
(function (RentServices) {
    RentServices.getRentDetails = (rentId) => {
        return client
            .get(generateRoute('api.rents.details', { rent: rentId }))
            .then(response => response.data);
    };
    RentServices.updateRent = (rentId, data) => {
        return client
            .post(generateRoute('api.rents.edit', { rent: rentId }), data)
            .then(response => response.data);
    };
    RentServices.downloadFile = (response) => {
        const fileName = response.headers['X-Download-File-Name'.toLowerCase()];
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURI(fileName));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    RentServices.downloadRenterMirrorDateReport = async (data) => {
        const { type, anonymized, ...payload } = data;
        const response = await client.get(generateRoute('api.rents.export.dateReport.' + type, {
            ...payload,
            anonymized: anonymized ? 1 : 0,
            vacancyOnly: data.vacancyOnly ? 1 : 0,
        }), { responseType: 'blob' });
        RentServices.downloadFile(response);
    };
    RentServices.downloadRenterMirrorDateRangeReport = async (data) => {
        const { type, anonymized, ...payload } = data;
        const response = await client.get(generateRoute('api.rents.export.dateRangeReport.' + type, { ...payload, anonymized: anonymized ? 1 : 0 }), { responseType: 'blob' });
        RentServices.downloadFile(response);
    };
    RentServices.downloadRenterMirrorTenantListReport = async (data) => {
        const { type, ...payload } = data;
        const response = await client.get(generateRoute('api.rents.export.tenantList.' + type, { ...payload }), { responseType: 'blob' });
        RentServices.downloadFile(response);
    };
    RentServices.getCalculatedRentPotentialForDate = async (rentId, date) => {
        const route = generate('api.rents.getCalculatedRentPotentialForDate', {
            date: date,
            rent: rentId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    RentServices.deleteRent = (id) => {
        const route = generate('api.rents.delete', {
            rent: id
        });
        return client.delete(route);
    };
    RentServices.getLastAvailableReferenceInterestRateDate = async () => {
        const route = generate('api.referenceInterestRates.getLastAvailableDate');
        return client
            .get(route)
            .then(response => response.data);
    };
})(RentServices || (RentServices = {}));
export default RentServices;
