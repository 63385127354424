import generateRoute from "Services/Router";
import client from "Utils/client";
import axios from "axios";
import { sleep } from 'Utils/delay';
export const getPropertyDetails = (propertyId) => {
    const route = generateRoute('api.property.details', {
        id: propertyId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getPropertyViewDropdownData = () => {
    const route = generateRoute('property.viewDropdownData');
    return client
        .get(route)
        .then(response => response.data);
};
export const getBuildingDetails = (buildingId) => {
    const route = generateRoute('api.building.details', {
        id: buildingId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getRentalObjectDetails = (rentalObjectId) => {
    const route = generateRoute('api.rentalObject.details', {
        id: rentalObjectId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getRentalObjectViewDropdownData = async () => {
    const route = generateRoute('rentalObjects.viewDropdownData');
    return client
        .get(route)
        .then(response => response.data);
};
export const getContractDetails = (contractId) => {
    const route = generateRoute('api.contracts.details', {
        id: contractId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getContractForBreadcrumbs = (contractId) => {
    const route = generateRoute('api.contracts.breadcrumbs', {
        id: contractId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const updateProperty = async (propertyId, data) => {
    const route = generateRoute('property.update', {
        id: propertyId
    });
    return client
        .post(route, data)
        .then(response => response.data);
};
export const updateBuilding = (buildingId, data2) => {
    const route = generateRoute('api.building.update', {
        id: buildingId
    });
    const data = {
        buildYear: String(data2.buildYear),
        lastRenovation: String(data2.lastRenovation),
        taxValue: String(data2.taxValue),
        insuranceValue: String(data2.insuranceValue),
        totalInvestment: String(data2.totalInvestment),
    };
    return client.post(route, {
        ...data2,
        buildYear: parseInt(data.buildYear),
        lastRenovation: parseInt(data.lastRenovation),
        taxValue: parseFloat(data.taxValue.replace ? data.taxValue.replace("'", "") : data.taxValue),
        insuranceValue: parseFloat(data.insuranceValue.replace ? data.insuranceValue.replace("'", "") : data.insuranceValue),
        totalInvestment: parseFloat(data.totalInvestment.replace ? data.totalInvestment.replace("'", "") : data.totalInvestment)
    });
};
export const updateRentalObject = (rentalObjectId, data) => {
    const route = generateRoute('rentalObject.update', {
        id: rentalObjectId
    });
    return client.post(route, {
        ...data,
        area: parseFloat(data.area),
        volume: parseFloat(data.volume),
        isAncillaryExpenseRelevant: data.isAncillaryExpenseRelevant
    });
};
export const deleteProperty = (propertyId) => {
    const route = generateRoute('api.properties.delete', {
        property: propertyId
    });
    return client.delete(route);
};
export const deleteBuilding = (buildingId) => {
    const route = generateRoute('api.buildings.delete', {
        building: buildingId
    });
    return client.delete(route);
};
export const deleteRentalObject = (rentalObjectId) => {
    const route = generateRoute('api.rentalObjects.delete', {
        rentalObject: rentalObjectId
    });
    return client.delete(route);
};
export const deleteRentalObjectRoom = (rentalObjectRoomId) => {
    const route = generateRoute('rentalObjectRoom.delete', {
        rentalObjectRoom: rentalObjectRoomId
    });
    return client.delete(route);
};
export const uploadPropertyImages = (propertyId, images) => {
    const route = '/_uploader/property_images/upload';
    const formData = new FormData();
    for (const image of images) {
        formData.append(image.name, image);
    }
    return axios
        .post(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            propertyId
        }
    })
        .then(response => response.data);
};
export const uploadBuildingImages = (buildingId, images) => {
    const route = '/_uploader/building_images/upload';
    const formData = new FormData();
    for (const image of images) {
        formData.append(image.name, image);
    }
    return axios
        .post(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            buildingId
        }
    })
        .then(response => response.data);
};
export const uploadRentalObjectImages = (rentalObjectId, images) => {
    const route = '/_uploader/rental_object_images/upload';
    const formData = new FormData();
    for (const image of images) {
        formData.append(image.name, image);
    }
    return axios
        .post(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            rentalObjectId
        }
    })
        .then(response => response.data);
};
export const deletePropertyImage = (propertyId, imageId) => {
    const route = generateRoute('property.deleteImage', {
        propertyId: propertyId,
        imageId: imageId
    });
    return axios.delete(route);
};
export const deleteBuildingImage = (buildingId, imageId) => {
    const route = generateRoute('building.deleteImage', {
        buildingId: buildingId,
        imageId: imageId
    });
    return axios.delete(route);
};
export const deleteRentalObjectImage = (rentalObjectId, imageId) => {
    const route = generateRoute('rentalObject.deleteImage', {
        rentalObjectId: rentalObjectId,
        imageId: imageId
    });
    return axios.delete(route);
};
export const editContractGeneralData = (id, data) => {
    const route = generateRoute('api.contracts.edit.general', {
        contractId: id
    });
    return client
        .post(route, data)
        .then(response => response.data);
};
export const editContractAncillaryExpensesData = (id, data) => {
    const route = generateRoute('api.contracts.edit.ancillaryExpenses', {
        contractId: id
    });
    return client
        .post(route, data)
        .then(response => response.data);
};
export const editContractPersonsData = (id, data) => {
    const route = generateRoute('api.contracts.edit.people', {
        contractId: id
    });
    return client
        .post(route, data)
        .then(response => response.data);
};
export const editContractDepositData = (id, data) => {
    const route = generateRoute('api.contracts.edit.deposit', {
        contractId: id
    });
    return client
        .post(route, data)
        .then(response => response.data);
};
export const getRentalObjectAbstractRooms = async () => {
    const route = generateRoute('api.rentalObjects.abstractRooms');
    return client
        .get(route)
        .then(res => res.data);
};
export const addRentalObjectRoom = async (id, abstractRoomId) => {
    const route = generateRoute('api.rentalObjects.addRoom', {
        rentalObject: id,
        abstractRoom: abstractRoomId
    });
    return client
        .post(route)
        .then(response => response.data);
};
export const getRentalObjectRoomDetails = async (id) => {
    const route = generateRoute('api.rentalObjectRooms.details', {
        room: id,
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const updateRentalObjectRoom = async (id, data) => {
    const route = generateRoute('api.rentalObjectRooms.update', {
        room: id,
    });
    return client
        .put(route, data);
};
export const getBreadcrumbsForObject = async (object, id) => {
    const route = generateRoute('api.breadcrumbs.getForObject', {
        id,
        objectClass: object
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const validateContractGeneralData = async (id, data) => {
    await sleep(1000);
    return {
        valid: false,
        problems: [
            {
                type: 'modificationsWillBeRemoved',
                payload: [
                    {
                        start: '2023-10-10',
                        rentNet: 1233,
                        rentGross: 1400,
                    },
                    {
                        start: '2023-04-15',
                        rentNet: 1500,
                        rentGross: 3000,
                    }
                ]
            }
        ]
    };
};
