import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/App';
import { BaseDataServices } from './services';
import { RentalObjectDataTableType } from './table/RentalObjectDataTableType';
import { useOpenRentalObject } from './hooks/useOpenRentalObject';
import { PersonDetailsServices } from 'Pages/Person/Details/services/personDetailsServices';
import { PersonModal } from "Pages/Person/components/PersonModal";
import { useObjectDeleteApi, useObjectViewModalContext } from "Components/ObjectViewModal";
export const RentalObjectPage = ({ personServices = PersonDetailsServices, ...rest }) => {
    useOpenRentalObject(rest);
    const { deleteRentalObject } = useObjectDeleteApi();
    const personModalApi = useRef();
    const table = RentalObjectDataTableType.useTable({
        onDeleteRentalObject: deleteRentalObject
    }, () => ({
        manualControl: true,
        openPersonInfoModal: (personId) => personModalApi.current?.openModal(personId),
        getData: ({ request }) => BaseDataServices.getBaseDataRentalObjectsList(request),
    }));
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            table.refresh();
        }
    }, [activeView]);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: table, variant: 'content', title: translate("rentalObjectPage.title"), inlineFilters: true }), _jsx(PersonModal, { api: personModalApi, onClose: () => table.refresh() })] }));
};
