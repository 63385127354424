import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useEventCallback } from '@restart/hooks';
import { BaseTheme } from '../base';
export function InputField({ field: valueType, Label: labelProps, Error: errorProps, Widget, children, as, ...rest }) {
    const commonProps = {
        field: valueType,
    };
    const props = InputField.useProps(valueType, {});
    return (_jsxs(InputField.Row, { id: `${valueType.id}--row`, as: as, ...rest, ...commonProps, children: [_jsx(InputField.Label, { ...labelProps, ...commonProps }), Widget ? Widget({
                ...props,
                ...commonProps
            }) : children, _jsx(InputField.Error, { ...errorProps, ...commonProps })] }));
}
(function (InputField) {
    function useProps(valueType, props) {
        const name = valueType.path;
        const value = valueType.humanValue ?? '';
        const $size = valueType.theme?.size;
        const $variant = valueType.meta.validated
            ? (valueType.meta.valid ? 'success' : 'error')
            : (valueType.meta.touched && valueType.meta.validating ? 'validating' : props?.$variant || 'default');
        const onChange = useEventCallback((value, event) => {
            valueType.updateValue(value, true);
        });
        const onBlur = useEventCallback((event) => {
            valueType.setTouched(true, true);
        });
        return {
            id: valueType.widgetId,
            name,
            value,
            onBlur,
            onChange,
            $size,
            $variant,
            disabled: valueType.isDisabled,
            readOnly: valueType.options.readonly,
        };
    }
    InputField.useProps = useProps;
    InputField.Row = styled(BaseTheme.Row) ``;
    InputField.Label = styled(BaseTheme.Label) `
      //display: flex;
      //gap: 3px;
      //width: 100%;
      margin: 0 0 8px;
      ${p => p.theme.typography.small.fontStyles};
    `;
    InputField.Error = styled(BaseTheme.Error) ``;
})(InputField || (InputField = {}));
