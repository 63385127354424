import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ModalNew } from "Components/Modal";
import { UseStyled } from "@f2w/theme";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { Icon } from "Atoms/Icon";
import { DateType, EmailType, PhoneType } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button, ButtonToolbar, Spinner } from "Atoms/index";
import { PersonDetailsServices } from "Pages/Person/Details/services/personDetailsServices";
const Avatar = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    backgroundColor: palette.neutral.$100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.neutral.$500,
    fontWeight: 'normal'
}));
const InfoIcon = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    backgroundColor: palette.primary.$50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.primary.$500,
    fontWeight: 'normal'
}));
export const PersonShortInfoModal = ({ id, data: initialData, onClose, openPersonDetails, getShortInfo = PersonDetailsServices.getShortInfo }) => {
    const [data, setData] = useState(initialData);
    useEffect(() => {
        if (id && !initialData) {
            void getShortInfo(id).then(data => setData(data));
        }
        else {
            setData(initialData);
        }
    }, [id]);
    if (!data) {
        if (id) {
            return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth: '600px', height: '300px' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose }), _jsx(ModalNew.Body, { children: _jsx(FlexRow, { alignCenter: true, justifyCenter: true, children: _jsx(Spinner, {}) }) })] }));
        }
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth: '600px' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalNew.Title, { children: _jsxs(FlexRow, { gap: 15, alignItems: 'center', children: [_jsx(Avatar, { children: _jsx(Text, { body: 400, children: data.initials }) }), _jsx("div", { children: data.name })] }) }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexRow, { gap: 15, children: [_jsxs(FlexCol, { gap: 40, size: "50%", children: [_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'map-pin' }) }), _jsx(FlexCol, { children: data.address
                                                ? (_jsxs(_Fragment, { children: [_jsxs(Text, { body: 400, children: [data.address.street, " ", data.address.houseNumber] }), _jsxs(Text, { body: 400, children: [data.address.postalCode, " ", data.address.city] })] }))
                                                : _jsx(_Fragment, { children: translate('notAvailable') }) })] }), _jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'phone' }) }), _jsx(Text, { body: 400, children: data.phoneNumber ? PhoneType.format(data.phoneNumber) : translate('notAvailable') })] }), _jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'mail' }) }), _jsx(Text, { body: 400, children: data.emailAddress ? EmailType.format(data.emailAddress) : translate('notAvailable') })] })] }), _jsxs(FlexCol, { gap: 40, size: "50%", children: [_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'gift' }) }), _jsx(Text, { body: 400, children: data.dateOfBirth ? DateType.format(data.dateOfBirth) : translate('notAvailable') })] }), data.secondPhoneNumber && (_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'phone' }) }), _jsx(Text, { body: 400, children: PhoneType.format(data.secondPhoneNumber) })] })), data.secondEmailAddress && (_jsxs(FlexRow, { gap: 16, alignItems: 'center', children: [_jsx(InfoIcon, { children: _jsx(Icon, { size: 24, icon: 'mail' }) }), _jsx(Text, { body: 400, children: EmailType.format(data.secondEmailAddress) })] }))] })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { variant: 'outline', color: 'neutral', onClick: onClose, children: translate('btn.close') }), _jsx(Button, { onClick: () => {
                                onClose();
                                openPersonDetails(data.id);
                            }, children: translate('dunning.btn.openPersonDetails') })] }) })] }));
};
