import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PatchNoteStatus } from "Pages/Administration/PatchNotes/enums";
import { Table } from "@f2w/data-table/table-types";
import { Div } from "Atoms/Layout";
import moment from "moment";
import { Badge } from "Atoms/Badge";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { translateAdmin } from "Services/App";
import { Icon } from "Atoms/Icon";
import { Button } from "Atoms/Button";
const BaseTable = Table.factory()(() => ({
    manualControl: true,
    tableId: 'patch-notes-table',
    usePagination: {},
    useSortBy: {},
    Actions: ({ createPatchNote }) => {
        return _jsx(Button.New, { onClick: createPatchNote });
    },
    RowActions: ({ row: { original: { id, status } }, publishPatchNote, unpublishPatchNote, viewPatchNote, editPatchNote, deletePatchNote }) => {
        return _jsxs(ActionsDropdown, { id: id, quickActions: [
                {
                    icon: 'eye',
                    title: translateAdmin('patchNotes.view.title'),
                    onClick: () => viewPatchNote(id)
                },
                {
                    icon: 'edit',
                    title: translateAdmin('btn.edit'),
                    onClick: () => editPatchNote(id)
                }
            ], children: [_jsxs(ActionsDropdown.Link, { onClick: () => status === PatchNoteStatus.DRAFT ? publishPatchNote(id) : unpublishPatchNote(id), children: [_jsx(Icon, { icon: status === PatchNoteStatus.DRAFT ? 'share-2' : 'x-circle' }), " ", status === PatchNoteStatus.DRAFT
                            ? translateAdmin('patchNotes.btn.publish')
                            : translateAdmin('patchNotes.btn.unpublish')] }), _jsxs(ActionsDropdown.Link, { onClick: () => deletePatchNote(id), confirm: translateAdmin('patchNotes.delete.confirm.message'), children: [_jsx(Icon, { icon: 'trash' }), " ", translateAdmin('btn.delete')] })] });
    },
    columns: {
        title: {
            Header: 'Title',
            Cell: ({ value }) => {
                return _jsx(Div, { dangerouslySetInnerHTML: { __html: value } });
            }
        },
        leadText: {
            Header: 'Lead Text',
            Cell: ({ value }) => {
                return _jsx(Div, { dangerouslySetInnerHTML: { __html: value } });
            }
        },
        createdAt: {
            Header: 'Date/Time',
            Cell: ({ value }) => {
                return _jsx(Div, { style: { whiteSpace: 'nowrap' }, children: moment(value).format('DD.MM.YYYY HH:mm') });
            }
        },
        status: {
            Header: 'Status',
            Cell: ({ value }) => {
                if (value === PatchNoteStatus.DRAFT) {
                    return _jsx(Badge, { "$color": 'primary', children: value.charAt(0).toUpperCase() + value.slice(1) });
                }
                return _jsx(Badge, { "$color": 'success', children: value.charAt(0).toUpperCase() + value.slice(1) });
            }
        }
    }
}));
export const PatchNoteDataTable = Object.assign(BaseTable, {
    use({ getPatchNotes, viewPatchNote, createPatchNote, editPatchNote, publishPatchNote, unpublishPatchNote, deletePatchNote }) {
        return PatchNoteDataTable.useTable({
            viewPatchNote,
            createPatchNote,
            editPatchNote,
            publishPatchNote,
            unpublishPatchNote,
            deletePatchNote
        }, {
            getData: async ({ request }) => getPatchNotes(request)
        });
    }
});
