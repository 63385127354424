import { jsx as _jsx } from "react/jsx-runtime";
import { FormControl } from 'Atoms/Input';
import { SimpleType } from './BaseSimpleType';
import { DateValueType } from '../../core';
import { InputField } from '../../theme';
export class DateType extends SimpleType {
    specs = this._getSpecs();
    constructor(options) {
        super(options);
        this.__construct();
    }
    _getSpecs() {
        return new DateValueType({
            defaultOptions: () => ({
                type: 'day',
            }),
        });
    }
    _renderForm(valueType, props) {
        const controlProps = {
            minDate: valueType.options.min,
            maxDate: valueType.options.max,
            startDate: valueType.options.startDate,
            endDate: valueType.options.endDate,
            type: valueType.options.type,
            variant: valueType.options.variant,
            selectsStart: valueType.options.selectsStart,
            selectsEnd: valueType.options.selectsEnd,
            includeDates: valueType.options.includeDates,
            includeDateIntervals: valueType.options.includeDateIntervals,
            excludeDates: valueType.options.excludeDates,
            excludeDateIntervals: valueType.options.excludeDateIntervals,
            placeholder: valueType.options.placeholder,
        };
        return _jsx(InputField, { ...props, Widget: ({ field, value, ...props }) => {
                return (_jsx(FormControl.DatePicker, { value: field.value, ...props, ...controlProps }));
            } });
    }
}
