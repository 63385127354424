import { OrFunction, orFunction } from 'Utils/types';
import { CompoundState } from './manager';
import { ValidationError } from './specs';
export class BaseValue {
    _type;
    constructor(type, props) {
        this._type = type;
        this._props = {
            ...props
        };
    }
    get typeName() {
        return this.specs.__fw_type_name;
    }
    get specs() {
        return this._type.specs;
    }
    get options() {
        return { ...this.specs.options };
    }
    get meta() {
        return this._state.meta;
    }
    get data() {
        return this._state.data;
    }
    get context() {
        return this.parent?.context ?? this?._props.context;
    }
    get theme() {
        return { ...this.parent?.theme, ...this._props.theme };
    }
    get isRoot() {
        return !this.parent;
    }
    root() {
        return (this.parent ? this.parent.root() : this);
    }
    getRoot() {
        return (this.parent ? this.parent.root() : this);
    }
    get parent() {
        return this._state.parent;
    }
    getParent() {
        return this.parent;
    }
    get key() {
        return this._state.key ?? '';
    }
    get name() {
        return this.path;
    }
    get path() {
        const _parent = this.parent?.path;
        return _parent ? `${_parent}[${this.key}]` : this.key;
    }
    get id() {
        if (!this.parent)
            return 'fw-form';
        return `${this.parent?.id}-${this.key}`;
    }
    get widgetId() {
        return `${this.id}--widget`;
    }
    get showLabel() {
        return !this.options.hideLabel;
    }
    get label() {
        return this.options.label ?? null;
    }
    get isRequired() {
        return this.options.required;
    }
    get isValid() {
        return this.meta.valid;
    }
    get isValidated() {
        return this.meta.validated;
    }
    get isTouched() {
        return this.meta.touched;
    }
    get isVisible() {
        return this.options.hidden !== true && (OrFunction(this.options.isVisible, this) ?? true);
    }
    get isDisabled() {
        return this.options.hidden === true
            || (this.options.disabled ??
                OrFunction(this.options.isDisabled, this));
    }
    get touched() {
        return this._state.data.touched;
    }
    get initialValue() {
        return this._state.initialValue;
    }
    get value() {
        return this._state.value;
    }
    get formattedValue() {
        return this._state.data.formattedValue;
    }
    get humanValue() {
        return this._state.data.humanValue;
    }
    get error() {
        return this._state.data.error;
    }
    use(props) {
        return this;
    }
    set initialValue(initialValue) {
        this.setValue(initialValue);
    }
    set value(value) {
        this.updateValue(value, true);
    }
    set label(value) {
        this.options.label = value;
        this._handleUpdate();
    }
    setTheme(theme) {
        this._props.theme = theme;
        return this;
    }
    updateOptions(options, skipUpdate) {
        this.specs.update(options);
        this._handleUpdate();
    }
    updateMeta(props, skipUpdate) {
        if (this._state.update(props)) {
            this._handleUpdate(skipUpdate);
            return true;
        }
    }
    async submit() {
        return this._submit();
    }
    async save() {
        await this._submit();
        return this;
    }
    async _submit() {
        this.setTouched(true, false, true);
        await this.validate(true, true);
        this._dispatchUpdate();
        if (!this.meta.valid) {
            ValidationError.throwError('Validation error');
        }
        return this;
    }
    _shouldValidate() {
        if (this.options.isDisabled != null) {
            return !orFunction(this.options.isDisabled, this);
        }
        return !this.meta.disabled;
    }
    render(props) {
        return this.specs.render({ ...props, field: this });
    }
    _handleUpdate(skipRender, shouldValidate) {
        return this._handleUpdate2({ shouldValidate, skipRender });
    }
    _handleUpdate2(p) {
        p?.skipRender || this._dispatchUpdate();
        if (p?.shouldValidate)
            return this.validate(true, p?.skipRender);
    }
    _dispatchUpdate() {
        const dispatch = this.context?.dispatchUpdate;
        if (!dispatch) {
            console.warn('No dispatchUpdate function provided.');
        }
        else {
            dispatch();
        }
    }
    _isCompound() {
        return (this._state instanceof CompoundState);
    }
    __dump() {
        let dump = {
            path: this.path,
        };
        if (this._isCompound()) {
            return {
                ...dump,
                ...this.meta,
                meta: this._state._dumpMeta(),
                children: Object.fromEntries(this.map((child, key) => ([key, child.__dump()])))
            };
        }
        return {
            ...dump,
            ...this.data,
        };
    }
    __dumpProps() {
        const id = this.key;
        let dump = {
            isVisible: this.isVisible,
            isDisabled: this.isDisabled,
            ...this.meta,
        };
        if (this._isCompound()) {
            let children = {};
            dump[`INDEX       `] = this._state._dumpMeta();
            dump[`-------------------------------------------`] = children;
            this.forEach(child => {
                const id = child.key;
                children[`--- ${child._dumpKey} ------------------------`] = {
                    ...child.__dumpProps()
                };
            });
        }
        return dump;
    }
    get _dumpKey() {
        const _parent = this.parent?._dumpKey;
        return _parent ? `${_parent}/${this.key}` : this.key;
    }
}
function formatKey() { }
BaseValue.prototype.__is_fw_value = true;
