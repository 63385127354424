import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import GlobalTemplateConfig from './Components/GlobalTemplateConfig';
import { Button, Icon } from 'Components/Atoms';
import { LegacyCloneTemplateModal } from "Pages/DocumentTemplates/View/Components/LegacyCloneTemplateModal";
import DocumentTemplateServices from "Pages/DocumentTemplates/services/documentTemplateServices";
const sortFn = (a, b) => {
    if (a.templateInstanceKeyTrans < b.templateInstanceKeyTrans)
        return -1;
    if (a.templateInstanceKeyTrans > b.templateInstanceKeyTrans)
        return 1;
    return 0;
};
const DocumentTemplatesPage = ({ templates, globalConfig }) => {
    const [templateToClone, setTemplateToClone] = React.useState();
    const [templateMap, setTemplateMap] = React.useState(new Map());
    const openModalFor = React.useCallback((templateToClone) => setTemplateToClone(templateToClone), []);
    const onModalClose = React.useCallback(() => setTemplateToClone(undefined), []);
    const onCloneSuccess = React.useCallback((newTemplate) => {
        const modifiedTemplateMap = new Map(templateMap);
        modifiedTemplateMap.set(newTemplate.baseTemplateKey, [
            ...modifiedTemplateMap.get(newTemplate.baseTemplateKey),
            newTemplate
        ].sort(sortFn));
        setTemplateMap(modifiedTemplateMap);
        onModalClose();
    }, [templateMap]);
    const onDeleteTemplate = React.useCallback((templateToDelete) => {
        DocumentTemplateServices
            .deleteMandateDocumentTemplate(templateToDelete.id)
            .then(() => {
            const modifiedTemplateMap = new Map(templateMap);
            const baseKey = templateToDelete.baseTemplateKey;
            if (modifiedTemplateMap.has(baseKey)) {
                modifiedTemplateMap.set(baseKey, [
                    ...modifiedTemplateMap.get(baseKey).filter(template => template.id !== templateToDelete.id)
                ]);
            }
            setTemplateMap(modifiedTemplateMap);
        });
    }, [templateMap]);
    React.useEffect(() => {
        const newTemplateMap = new Map();
        templates.forEach((template) => {
            if (newTemplateMap.has(template.baseTemplateKey)) {
                newTemplateMap.set(template.baseTemplateKey, [
                    ...newTemplateMap.get(template.baseTemplateKey),
                    template
                ].sort(sortFn));
            }
            else {
                newTemplateMap.set(template.baseTemplateKey, [template]);
            }
        });
        setTemplateMap(newTemplateMap);
    }, [templates]);
    const mappedTemplates = React.useMemo(() => {
        const mappedGroupedTemplates = [];
        templateMap.forEach((templates, baseTemplateKey) => {
            const mappedTemplates = templates.map((template) => {
                const params = {
                    instanceKey: template.templateInstanceKey,
                };
                if (template.mandate) {
                    params['mandateId'] = template.mandate.id;
                }
                const editRoute = generateRoute('documentTemplate.edit', params);
                const mandateText = `(${template.mandate
                    ? template.mandate.name
                    : translate('template.global')})`;
                return (_jsxs("tr", { children: [_jsxs("td", { children: [template.templateInstanceKeyTrans, " ", mandateText] }), _jsxs("td", { children: [_jsx(Button, { variant: 'outline', "$size": 'md', style: { padding: '8px', marginRight: '0.5rem' }, href: editRoute, title: translate('btn.editTemplate'), children: _jsx(Icon, { icon: 'edit' }) }), _jsx(Button, { variant: 'outline', "$size": 'md', style: { padding: '8px', marginRight: '0.5rem' }, title: translate('btn.cloneTemplate'), onClick: () => openModalFor({
                                        id: template.id,
                                        templateInstanceKey: template.templateInstanceKey,
                                        caption: template.caption
                                    }), children: _jsx(Icon, { icon: 'copy' }) }), template.mandate &&
                                    _jsx(Button, { variant: 'outline', "$size": 'md', style: { padding: '8px' }, title: translate('btn.deleteTemplate'), onClick: () => onDeleteTemplate(template), children: _jsx(Icon, { icon: 'trash' }) })] })] }, template.id));
            });
            const cardElement = (_jsxs("div", { className: 'card mb-4', style: { boxShadow: "0 0 10px #0000001a", borderRadius: 8 }, children: [_jsx("div", { id: `heading-${baseTemplateKey}`, className: 'card-header', style: { background: "transparent" }, children: _jsx("div", { className: 'card-title d-flex collapsed align-items-center', "data-toggle": 'collapse', "data-target": `#collapse-${baseTemplateKey}`, "aria-expanded": 'false', "aria-controls": `collapse-${baseTemplateKey}`, children: _jsx("h4", { className: 'mb-0', children: translate(baseTemplateKey) }) }) }), _jsx("div", { id: `collapse-${baseTemplateKey}`, className: 'collapse', "aria-labelledby": `heading-${baseTemplateKey}`, "data-parent": '#documentTemplateAccordion', children: _jsx("div", { className: 'card-body row', children: _jsx("div", { className: 'col-md-12', children: _jsxs("table", { className: 'table table-striped', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: translate('template.document') }), _jsx("th", { children: translate('table.actions') })] }) }), _jsx("tbody", { children: mappedTemplates })] }) }) }) })] }, baseTemplateKey));
            mappedGroupedTemplates.push(cardElement);
        });
        return mappedGroupedTemplates;
    }, [templateMap]);
    return (_jsxs("div", { children: [_jsxs("div", { className: 'row', children: [_jsxs("div", { className: 'col-md-8 pr-5', children: [_jsx("h2", { className: 'kt-portlet__head-title border-0', children: translate('templates') }), _jsx("div", { className: 'form-text text-muted mt-1 mb-5', children: translate('templates.help') }), _jsx("div", { id: 'documentTemplateAccordion', className: 'accordion', children: mappedTemplates })] }), _jsx("div", { className: 'col-md-4', children: _jsx(GlobalTemplateConfig, { globalConfig: globalConfig }) })] }), _jsx(LegacyCloneTemplateModal, { onClose: onModalClose, onCloneSuccess: onCloneSuccess, templateToClone: templateToClone })] }));
};
export default DocumentTemplatesPage;
