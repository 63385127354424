import { jsx as _jsx } from "react/jsx-runtime";
import { defer } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';
import * as View from './route';
export function wizardRoute(config, override) {
    config = { ...config, ...override };
    const load = async (args, ctx) => {
        const loadData = await config.load(args, ctx);
        const data = {
            ...loadData,
            id: loadData?.id ?? config.id,
        };
        console.debug('-- LOAD - Root -- ', { data, ...args, ctx });
        return data;
    };
    const route = {
        id: `wizard_config_${config.id}`,
        path: config.path,
        element: _jsx(View.BaseController, { config: config }),
        errorElement: _jsx(View.ErrorController, {}),
        children: [
            {
                element: _jsx(View.RootController, {}),
                errorElement: _jsx(View.ErrorController, {}),
                shouldRevalidate: (args) => !equal({ ...args.currentParams, '*': null }, { ...args.nextParams, '*': null }),
                loader: async (args, ctx) => {
                    const data = load(args, ctx);
                    return defer({ data });
                },
                children: [
                    {
                        index: true,
                        element: _jsx(View.StepController, {}),
                    },
                    {
                        path: '*',
                        element: _jsx(View.StepController, {}),
                    },
                ]
            }
        ]
    };
    console.debug('-- ROUTE - cleared -- ', route);
    return route;
}
