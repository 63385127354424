import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from "@f2w/data-table/table-types";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { translate, translateAdmin } from "Services/App";
import { useEffect, useState } from "react";
import { Button } from "Atoms/Button";
import { EmailType } from "@f2w/form";
import { Select } from "Atoms/Select";
import { Icon } from "Atoms/Icon";
const BaseTable = Table.factory()(() => ({
    tableId: 'feature-flag-data-table',
    manualControl: true,
    useFilters: {},
    usePagination: {},
    useGlobalFilter: {},
    Actions: ({ createFeatureFlag }) => (_jsx("div", { children: _jsx(Button.New, { onClick: createFeatureFlag, title: translateAdmin('featureFlag.btn.new') }) })),
    RowActions: ({ row: { original: { id }, original }, updateFeatureFlag, deleteFeatureFlag }) => (_jsx(ActionsDropdown, { id: id, quickActions: [{
                icon: 'edit',
                title: translate('btn.edit'),
                onClick: () => updateFeatureFlag(original)
            }], children: _jsxs(ActionsDropdown.Link, { confirm: translateAdmin('featureFlag.delete.areYouSure'), onClick: () => deleteFeatureFlag(id), children: [_jsx(Icon, { icon: 'trash' }), " ", translateAdmin('btn.delete')] }) })),
    columns: {
        $actions: {},
        email: {
            Header: translateAdmin('featureFlag.email'),
            Cell: ({ value }) => EmailType.format(value)
        },
        feature: {
            Header: translateAdmin('featureFlag.feature'),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter }, getAvailableFeatures }) => {
                    const [features, setFeatures] = useState([]);
                    useEffect(() => {
                        getAvailableFeatures()
                            .then(data => setFeatures(data.map(feature => ({
                            value: feature,
                            label: feature
                        }))));
                    }, []);
                    return (_jsx(Select, { useMaxContentWidth: true, value: filterValue, options: features, isMulti: true, isClearable: true, onChange: (selectedOptions) => setFilter(selectedOptions.map(selectedOption => selectedOption.value)), placeholder: translateAdmin('featureFlag.selectFeature') }));
                }
            }
        },
    }
}));
export const FeatureFlagDataTable = Object.assign(BaseTable, {
    use({ createFeatureFlag, updateFeatureFlag, deleteFeatureFlag, getAvailableFeatures, listFeatureFlags }) {
        return FeatureFlagDataTable.useTable({
            createFeatureFlag,
            updateFeatureFlag,
            deleteFeatureFlag,
            getAvailableFeatures
        }, {
            getData: async ({ request }) => listFeatureFlags(request)
        });
    }
});
