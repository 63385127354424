import { forEachObject, isUndefined, objectKeys } from '@f2w/utils';
import { UpdateQueue } from './descriptors';
export class BaseState {
    _userConfig;
    _own = Object.create(null);
    props = Object.create(null);
    _updateQueue = UpdateQueue.create((props) => {
        this._userConfig?.onChange?.(props);
    });
    constructor(config) {
        this._userConfig = config;
    }
    get key() {
        return this.props.key ?? '';
    }
    get parent() {
        return this.props.parent;
    }
    init(props) {
        this._updateProps(props);
    }
    set(key, value) {
        return this.update({ [key]: value });
    }
    update(data) {
        const updatedKeys = objectKeys(data).flatMap(key => {
            return this._updateData(key, data[key]);
        });
        if (this._updateQueue.length) {
            updatedKeys?.length && this.updateParent(updatedKeys);
            this._updateQueue.flush();
            return true;
        }
    }
    _updateData(key, value, force) {
        const propConfig = this._config.props[key];
        if (propConfig.set && (propConfig.getValue || propConfig.getMetaValue)) {
            const prevValue = propConfig.getValue ? propConfig.getValue() : propConfig.getMetaValue();
            if (force || prevValue !== value) {
                propConfig.set(value);
                const event = { propName: key, value, prevValue, force };
                propConfig.onChange?.(event);
                this._updateQueue.push(event);
                return [key];
            }
        }
    }
    _updateProps(props) {
        return objectKeys({ ...props }).filter(key => {
            if (!isUndefined(props[key]) && this.props[key] !== props[key]) {
                this.props[key] = props[key];
                return true;
            }
        });
    }
    updateParent(keys, ignoredKeys) {
        return this._parent?.updateIndexes({
            childrenOrKeys: [this.key],
            keys: keys,
            ignoredKeys,
        });
    }
    _hasOwn(key) {
        return key in this._own;
    }
    get _parent() {
        return this.props.parent?.['_state'];
    }
    _self() {
        return this._userConfig.getSelf();
    }
    _type() {
        return this._self().specs;
    }
    _cast(v, ignoreDefaults) {
        return this._type().cast(v, { ignoreDefaults });
    }
    _dispatchUpdate(p) {
        this._self()['_handleUpdate2'](p);
    }
    static createManager(config) {
        const data = Object.create(null);
        const meta = Object.create(null);
        forEachObject(config, (key, configProp) => {
            const defaults = {
                configurable: false,
                enumerable: true,
            };
            if (configProp.getValue) {
                Object.defineProperty(data, key, { ...defaults, get: () => configProp.getValue() });
            }
            if (configProp.getMetaValue) {
                Object.defineProperty(meta, key, { ...defaults, get: () => configProp.getMetaValue() });
            }
        });
        return {
            props: config,
            data: data,
            meta: meta,
        };
    }
}
