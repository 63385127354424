import generateRoute from "Services/Router";
import client from "Utils/client";
export var PersonDetailsServices;
(function (PersonDetailsServices) {
    PersonDetailsServices.getPersonById = async (id) => {
        const route = generateRoute('api.person', {
            person: id
        });
        return client
            .get(route)
            .then(response => response.data)
            .then((data) => {
            if (!data.corporatePerson) {
                data.corporatePerson = {
                    companyName: undefined,
                    vatId: undefined,
                };
            }
            return data;
        });
    };
    PersonDetailsServices.updatePerson = async (personId, person) => {
        const route = generateRoute('api.persons.update', {
            id: personId
        });
        return client
            .put(route, person)
            .then(response => response.data);
    };
    PersonDetailsServices.createPersonAddress = async (createData, personId) => {
        const route = generateRoute('api.persons.createAddress', {
            person: personId
        });
        const { data } = await client.post(route, createData);
        return data;
    };
    PersonDetailsServices.updatePersonAddress = async (updateData, personId, addressId) => {
        const route = generateRoute('api.persons.updateAddress', {
            person: personId,
            address: addressId,
        });
        const { data } = await client.put(route, updateData);
        return data;
    };
    PersonDetailsServices.deletePersonAddress = async (personId, addressId) => {
        const route = generateRoute('api.persons.deleteAddress', {
            person: personId,
            address: addressId,
        });
        return client.delete(route);
    };
    PersonDetailsServices.updatePersonWaitingListNotes = (personId, notes) => {
        const route = generateRoute('api.persons.updateWaitingListNotes', {
            person: personId
        });
        return client.put(route, { notes });
    };
    PersonDetailsServices.getShortInfo = async (personId) => {
        const route = generateRoute('api.persons.shortInfo', {
            person: personId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
})(PersonDetailsServices || (PersonDetailsServices = {}));
