import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon } from 'Components/App/Icons';
import { translate } from 'Services/Translator';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
const Actions = ({ row, openUpsertForm, deleteServiceProvider, cell: { value } }) => {
    return (_jsx(ActionsDropdown, { id: row.id, quickActions: [{
                icon: 'edit',
                title: translate('btn.edit'),
                onClick: () => openUpsertForm(row.id)
            }], children: _jsxs(ActionsDropdown.Link, { onClick: () => deleteServiceProvider(value.id), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }) }));
};
export default Actions;
