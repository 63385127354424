import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { sizeToken } from '@fw/theme';
import { InputTheme } from 'Atoms/Input/InputTheme';
import { components } from 'react-select';
export function useVars({ $color, $variant = $color, $size, $inline, alignMenu = 'left', useMaxContentWidth, ...rest }) {
    const inputVars = InputTheme.useVars({ $variant, $size }, selectSizeToken);
    const vars = useMemo(() => {
        const SizeVariants = {
            xs: {
                padding: '8px 12px'
            },
            sm: {
                padding: '12px 8px'
            },
            md: {
                padding: '12px 12px'
            },
            lg: {
                padding: '12px 16px'
            },
        };
        const menuListStyles = SizeVariants[inputVars.$size] ?? SizeVariants.md;
        return {
            ...inputVars,
            menuListStyles,
            $inline: $inline ?? false,
            alignMenu,
            useMaxContentWidth: (useMaxContentWidth ?? !rest.isMulti),
        };
    }, [inputVars, $inline, alignMenu, useMaxContentWidth]);
    return [vars, rest];
}
export const selectComponents = {
    ...components,
    MenuList: ({ children, ...props }) => (_jsx(components.MenuList, { ...props, innerProps: {
            id: props.selectProps.menuId || `${props.selectProps.id}-select`,
            ...props?.innerProps,
            role: 'listbox',
            'aria-expanded': true,
        }, children: children })),
    Option: ({ children, ...props }) => (_jsx(components.Option, { ...props, innerProps: {
            ...props?.innerProps,
            role: 'option',
            'aria-disabled': props.isDisabled
        }, children: children })),
};
export const selectSizeToken = sizeToken.extend({
    xs: {
        styles: (prev) => ({
            ...prev,
            paddingX: 8,
            paddingY: 2,
        }),
    },
    sm: {
        styles: (prev) => ({
            ...prev,
            paddingX: 12,
            paddingY: 4,
        }),
    },
    md: {
        styles: (prev) => ({
            ...prev,
            paddingX: 16,
            paddingY: 6,
        }),
    },
    lg: {
        styles: (prev) => ({
            ...prev,
            paddingX: 20,
            paddingY: 8,
        }),
    },
});
