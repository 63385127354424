import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const defaultTemplate = {
    Base: ({ children, config }) => {
        return _jsx(_Fragment, { children: children || _jsx(_Fragment, { children: "loading..." }) });
    },
    Error: ({ children, config }) => {
        return _jsxs(_Fragment, { children: [_jsx("b", { children: "Error:" }), " ", children] });
    },
    Root: ({ children, wizard, loading }) => {
        return _jsx(_Fragment, { children: wizard ? children : 'loading...' });
    },
    Step: ({ children, wizard }) => {
        return _jsx(_Fragment, { children: children });
    },
};
