import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from '@f2w/view';
import { FormError } from '@f2w/form';
import { PropertyViewFormType } from 'Components/ObjectViewModal/forms/PropertyViewFormType';
import { translate } from 'Services/Translator';
import { PropertyTemplate } from '../components/PropertyView/PropertyTemplate';
import { MortgageType } from 'Components/ObjectViewModal/types';
export const usePropertyView = (loadData, updateProperty, updatePropertyNameForBreadcrumb, getQuickschildIntegration) => useViewType(() => new PropertyViewFormType(), {
    id: 'property-view',
    DefaultTemplate: props => _jsx(PropertyTemplate, { ...props }),
    load: async ({ id }) => {
        const [data, quickschildIntegration] = await Promise.all([
            loadData(id),
            getQuickschildIntegration()
                .catch((e) => {
                console.log('Error loading quickschild', e);
                return {};
            }),
        ]);
        return {
            id,
            ...data,
            quickschildIntegrationEnabled: quickschildIntegration?.active,
            generalLeft: {
                ...data,
                quickschildIntegrationEnabled: quickschildIntegration?.active,
                name: data.name,
                egrid: data.egrid,
                description: data.description,
                active: data.active,
                hasVat: data.vatObligation,
                city: data.city,
                zip: data.zipCode,
                ancillaryCostSettlementDate: data.ancillaryCostSettlementDate,
                defaultRentBankAccountId: data.defaultRentBankAccount?.id,
                facilityManagerId: data.facilityManager?.id,
                mandateId: data.mandate.id,
                managerId: data.manager.id,
                notes: data.notes,
                mortgages: data.mortgages.map((mortgage) => ({
                    ...mortgage,
                    type: mortgage?.type ?? MortgageType.FIXED,
                    interest: mortgage?.interest ?? 0,
                    value: mortgage?.value ?? 0,
                    amortisationAmount: mortgage?.amortisationAmount ?? 0,
                }))
            }
        };
    },
    saveError: (error) => {
        if (!('response' in error)) {
            return new Error(error);
        }
        const { response: { data, status } } = error;
        if ([422, 400].includes(status) || data?.message) {
            return new FormError(data.message);
        }
        return new FormError(data);
    },
    save: async () => { },
}, () => ({
    generalLeft: {
        name: translate('baseData.property.edit'),
        showHeader: false,
        Form: ({ type }) => type.renderChild('generalLeft'),
        save: async (values, view) => {
            const data = view.type.getChild('generalLeft').getResolvedValue();
            return updateProperty(values.id, data)
                .then((savedData) => {
                updatePropertyNameForBreadcrumb(values.id, data.name);
                view.updateData({
                    ...savedData,
                    generalLeft: { ...data }
                });
            });
        }
    },
    generalRight: {
        showHeader: false
    },
}));
