import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { Button } from 'Atoms/Button/Button';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { BaseInput } from '../base';
import './theme';
import { FlexRow } from 'Atoms/Layout/Flex';
import { DateManager } from './helpers';
import { Select } from 'Atoms/Select/Select';
import classnames from 'classnames';
import { range } from '@f2w/utils';
import useMergedRefs from '@restart/hooks/useMergedRefs';
function useDatePicker({ type, value: propValue, selected: propSelected = propValue, min: _min, max: _max, minDate: propMinDate = _min, maxDate: propMaxDate = _max, startDate: propStartDate, endDate: propEndDate, ...restProps }) {
    const manager = useMemo(() => DateManager({
        minDate: propMinDate, maxDate: propMaxDate
    }), [propMinDate, propMaxDate]);
    const startDate = useMemo(() => manager.castDate(propStartDate), [manager, propStartDate]);
    const endDate = useMemo(() => manager.castDate(propEndDate), [manager, propEndDate]);
    const { selectsStart, selectsEnd, selectsRange, isClearable, } = restProps;
    const selected = useMemo(() => propSelected ? manager.castDate(propSelected) : selectsEnd ? endDate : startDate, [manager, propSelected, selectsStart, selectsEnd, endDate, startDate]);
    const meta = useMemo(() => {
        const rangeType = (() => {
            if (selectsStart)
                return 'start';
            if (selectsEnd)
                return 'end';
            if (selectsRange)
                return 'range';
        })();
        return {
            ...manager,
            selected,
            startDate,
            endDate,
            isClearable,
            type,
            isStart: selectsStart,
            isEnd: selectsEnd,
            isRange: selectsRange,
            valueType: type,
            rangeType,
        };
    }, [
        manager,
        selected?.valueOf(),
        startDate?.valueOf(),
        endDate?.valueOf(),
        isClearable,
        type,
        selectsStart,
        selectsEnd,
        selectsRange,
    ]);
    function getTypeProps(meta) {
        switch (meta.type) {
            case 'year':
                return {
                    dateFormat: 'yyyy',
                    showYearPicker: true,
                    yearItemNumber: 16,
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.YearsNav, { ...props, children: props?.date?.getFullYear() })),
                };
            case 'quarter':
                return {
                    dateFormat: 'MMMM yyyy',
                    showQuarterYearPicker: true,
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.YearsNav, { ...props, children: _jsx(DatePickerHeader.YearSelect, { ...props, dateManager: meta }) })),
                };
            case 'month':
                return {
                    dateFormat: 'MMMM yyyy',
                    showMonthYearPicker: true,
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.YearsNav, { ...props, children: _jsx(DatePickerHeader.YearSelect, { ...props, dateManager: meta }) })),
                };
            default:
                return {
                    dateFormat: 'dd.MM.yyyy',
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.NavMonths, { ...props, children: _jsxs(FlexRow, { justifySpaceEvenly: true, w: '100%', children: [_jsx(DatePickerHeader.YearSelect, { ...props, dateManager: meta }), _jsx(DatePickerHeader.MonthSelect, { ...props, dateManager: meta })] }) })),
                };
        }
    }
    const typeProps = useMemo(() => getTypeProps(meta), [meta]);
    const props = {
        dateFormat: 'dd.MM.yyyy',
        ...typeProps,
        ...restProps,
        selected,
        minDate: manager.minDate,
        maxDate: manager.maxDate,
        startDate,
        endDate,
    };
    return [
        props,
        meta,
    ];
}
const adjustEndDate = (date, meta) => {
    if (date instanceof Date) {
        if (meta.type === 'month')
            return moment(new Date(date.getFullYear(), date.getMonth(), 1))
                .add(1, 'month')
                .subtract(1, 'day')
                .toDate();
        if (meta.type === 'year')
            return new Date(date.getFullYear(), 11, 31);
    }
    return date;
};
export const DatePicker = forwardRef(({ className, onChange: _onChange, $variant, $size, variant, minWidth, ...iProps }, ref) => {
    const [props, manager] = useDatePicker(iProps);
    const onChange = useCallback((value, event) => {
        const { isClearable } = manager;
        if (Array.isArray(value)) {
            const from = manager.castDate(value[0]);
            const to = manager.castDate(adjustEndDate(value[1], manager));
            value = [from, to];
        }
        else {
            if (manager.isEnd) {
                value = (manager.castDate(adjustEndDate(value, manager)) || (isClearable ? undefined : manager.maxDate));
            }
            else if (manager.isStart) {
                value = (manager.castDate(value) || (isClearable ? undefined : manager.minDate));
            }
        }
        _onChange?.(value, event, manager);
    }, [manager]);
    const onBlur = useCallback((event) => {
        pickerRef.current?.handleCalendarClickOutside();
    }, [onChange, props.dateFormat]);
    const pickerRef = useRef();
    const mergedRef = useMergedRefs(pickerRef, ref);
    return (_jsx(ReactDatePicker, { ...{
            ref: mergedRef,
            popperProps: { strategy: 'fixed' },
            autoComplete: 'off',
            calendarStartDay: 1,
            preventOpenOnFocus: true,
            calendarClassName: classnames('fw-menu', `fw-menu--${variant ?? 'light'}`, {
                'fw-menu--inline': props.inline,
            }),
            ...props,
            onChange,
            onBlur,
            customInputRef: 'ref',
            customInput: _jsx(BaseInput, { ...{
                    style: minWidth ? { maxWidth: 160 } : {},
                    className,
                    icon: 'calendar',
                    $variant,
                    $size,
                } }),
        } }));
});
var DatePickerHeader;
(function (DatePickerHeader) {
    const BaseNav = ({ size = 'md', variant = 'linkFill', color, prev, next, children, ...props }) => (_jsx(Button.ButtonTheme, { size: size, variant: variant, color: color, children: _jsxs(FlexRow, { justifySpaceBetween: true, alignCenter: true, ...props, children: [_jsx(Button, { label: '<', ...prev, disabled: !prev || prev?.disabled }), children, _jsx(Button, { label: '>', ...next, disabled: !next || next?.disabled })] }) }));
    DatePickerHeader.NavMonths = ({ theme, children, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => _jsx(BaseNav, { ...theme, prev: { onClick: decreaseMonth, disabled: prevMonthButtonDisabled }, next: { onClick: increaseMonth, disabled: nextMonthButtonDisabled }, children: children });
    DatePickerHeader.YearsNav = ({ theme, children, decreaseYear, increaseYear, prevYearButtonDisabled, nextYearButtonDisabled, }) => _jsx(BaseNav, { ...theme, prev: { onClick: decreaseYear, disabled: prevYearButtonDisabled }, next: { onClick: increaseYear, disabled: nextYearButtonDisabled }, children: children });
    function renderSelect(props) {
        return (_jsx(Select, { "$inline": true, menuShouldBlockScroll: true, ...props }));
    }
    DatePickerHeader.YearSelect = ({ dateManager: { minDate, maxDate }, date, changeYear, $size = 'xs', }) => {
        const currentYear = date?.getFullYear();
        const yearOptions = useMemo(() => {
            const minYear = minDate.getFullYear();
            const maxYear = maxDate.getFullYear();
            return range(maxYear - minYear + 1, minYear).map(year => ({
                value: year,
                label: year,
            }));
        }, [minDate, maxDate]);
        const value = useMemo(() => currentYear && yearOptions.find(o => o.value == currentYear), [yearOptions, currentYear]);
        return renderSelect({
            options: yearOptions,
            value,
            isSearchable: true,
            $size,
            onChange: (option) => {
                changeYear(option?.value);
            },
        });
    };
    DatePickerHeader.MonthSelect = ({ dateManager: { minDate, maxDate, castDate }, monthDate: date, changeMonth, $size = 'xs', }) => {
        const currentYear = date?.getFullYear();
        const currentMonth = date?.getMonth();
        const monthOptions = useMemo(() => {
            return moment.months().map((label, value) => ({
                value, label
            }));
        }, [minDate, maxDate]);
        const value = useMemo(() => {
            return currentMonth && monthOptions.find(o => o.value == currentMonth);
        }, [monthOptions, currentMonth]);
        return renderSelect({
            options: monthOptions,
            value,
            isSearchable: true,
            $size,
            onChange: (option) => {
                changeMonth(option?.value);
            },
        });
    };
})(DatePickerHeader || (DatePickerHeader = {}));
