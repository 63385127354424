import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import '@f2w/polyfills';
import { AppProvider } from '../provider';
export const OldAppLayout = ({ children, noWrap = false, className = "fw-v2", ...props }) => {
    if (noWrap) {
        return _jsx(RootLayout, { children: children });
    }
    return (_jsx("div", { className: className, ...props, children: _jsx(RootLayout, { children: children }) }));
};
const RootLayout = ({ children }) => {
    return _jsx(_Fragment, { children: _jsx(AppProvider, { children: children }) });
};
