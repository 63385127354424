import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { translate } from 'Services/Translator';
import { Col, Row } from 'Atoms/Grid';
import { StringValueType } from '../../core';
import { BaseFormTypeOld, BaseSelectType, StringType } from '../base';
import { generate } from 'Services/Router';
import client from 'Utils/client';
import { convertOptionsToValues } from 'Utils/select';
import { isStr, OrFunction } from '@f2w/utils';
export class AddressType extends BaseFormTypeOld {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: translate('person.address'),
        };
    }
    buildFields() {
        return {
            street: AddressType.street({
                ...this.specs.options.street,
            }),
            houseNumber: AddressType.streetNumber({
                ...this.specs.options.houseNumber,
            }),
            zip: AddressType.zip({
                ...this.specs.options.zip,
            }),
            city: AddressType.city({
                ...this.specs.options.city,
            }),
            country: AddressType.country({
                ...this.specs.options.country,
            }),
        };
    }
    _renderWidget({ field }) {
        return AddressType.render(field.children);
    }
}
(function (AddressType) {
    function street(options) {
        return new Street(options);
    }
    AddressType.street = street;
    function streetNumber(options) {
        return new StreetNumber(options);
    }
    AddressType.streetNumber = streetNumber;
    function zip(options) {
        return new Zip(options);
    }
    AddressType.zip = zip;
    function city(options) {
        return new City(options);
    }
    AddressType.city = city;
    function country(options) {
        return new Country(options);
    }
    AddressType.country = country;
    function render(fields) {
        const { houseNumber, street, city, country, zip } = fields;
        return _jsxs(_Fragment, { children: [street && houseNumber && (_jsxs(Row, { gap: 10, children: [_jsx(Col, { children: street.render() }), _jsx(Col, { xs: 2, minw: 90, maxw: 110, children: houseNumber.render() })] })), (zip || city || country) && (_jsxs(Row, { gap: 10, children: [zip && _jsx(Col, { xs: 2, minw: 110, maxw: 140, children: zip.render() }), city && _jsx(Col, { children: city.render() }), country && _jsx(Col, { xs: 3, minw: 140, maxw: 180, children: country.render() })] }))] });
    }
    AddressType.render = render;
    class Street extends StringType {
        constructor(options) {
            super({
                label: translate('address.street'),
                ...options,
                constraints: {
                    max: 70,
                    ...options?.constraints,
                },
                autocomplete: (valueType) => ({
                    getItems: async (value) => Street.search(value),
                    getItemValue: (item) => item,
                }),
            });
        }
        static async search(term, limit = 50) {
            const route = generate('api.street.autocomplete');
            const { data } = await client.get(route, {
                params: { limit, term },
            });
            return data.results;
        }
        ;
    }
    AddressType.Street = Street;
    class StreetNumber extends StringType {
        constructor(options) {
            super({
                label: translate('address.housenumber'),
                ...options,
                constraints: {
                    max: 10,
                    ...options?.constraints,
                },
            });
        }
    }
    AddressType.StreetNumber = StreetNumber;
    class Zip extends StringType {
        constructor(options) {
            super({
                label: translate('address.zip'),
                ...options,
                constraints: {
                    max: 10,
                    ...options?.constraints,
                },
                autocomplete: valueType => ({
                    getItems: async (value) => Zip.search(value),
                    getItemValue: (item) => item.postalCode,
                    getItemLabel: (item) => `${item.postalCode} - ${item.city}`,
                    onSelect: (item) => {
                        (options?.getCityValue?.(valueType) ?? valueType.parent?.get?.('city'))
                            ?.updateValue(item.city, true);
                    },
                }),
            });
        }
        static async search(term, limit = 50) {
            const route = generate('api.postalCode.autocomplete');
            const { data } = await client.get(route, {
                params: { limit, term },
            });
            return data.results;
        }
    }
    AddressType.Zip = Zip;
    class City extends StringType {
        constructor(options) {
            super({
                label: translate('address.city'),
                ...options,
                constraints: {
                    max: 50,
                    ...options?.constraints,
                },
                autocomplete: (valueType) => ({
                    getItems: async (value) => City.search(value),
                    getItemValue: (item) => item.city,
                    getItemLabel: (item) => `${item.postalCode} - ${item.city}`,
                    onSelect: (item) => {
                        (options?.getZipValue?.(valueType) ?? valueType.parent?.get?.('zip'))
                            ?.updateValue(item.postalCode, true);
                    },
                }),
            });
        }
        static async search(term, limit = 50) {
            const route = generate('api.city.autocomplete');
            const { data } = await client.get(route, {
                params: { limit, term },
            });
            return data.results;
        }
    }
    AddressType.City = City;
    class Country extends BaseSelectType {
        specs = StringValueType.define({
            parse: (val) => convertOptionsToValues(val),
            defineOptions: () => ({}),
            defaultOptions: () => ({
                placeholder: translate('select.placeholder'),
                choices: Object.keys(CountryCode).map(key => ({
                    value: key,
                    label: isStr(key) ? translate(`country.${key}`) : '',
                })),
                label: translate('address.country'),
                required: true,
                nullable: false,
                defaultValue: CountryCode.CH,
                constraints: {
                    oneOf: [
                        Object.values(CountryCode),
                        null,
                    ],
                }
            }),
        });
        constructor(options) {
            super(options);
            this.__construct();
        }
        getChoiceOptionsProps(valueType, { loadChoices, choices, allowed }) {
            const options = useMemo(() => {
                const options = OrFunction(choices, valueType) ?? [];
                if (allowed) {
                    return options.filter((c) => allowed.includes(c.value));
                }
                return options;
            }, []);
            return {
                options
            };
        }
    }
    AddressType.Country = Country;
    let CountryCode;
    (function (CountryCode) {
        CountryCode["AD"] = "AD";
        CountryCode["AE"] = "AE";
        CountryCode["AF"] = "AF";
        CountryCode["AG"] = "AG";
        CountryCode["AI"] = "AI";
        CountryCode["AL"] = "AL";
        CountryCode["AM"] = "AM";
        CountryCode["AO"] = "AO";
        CountryCode["AQ"] = "AQ";
        CountryCode["AR"] = "AR";
        CountryCode["AS"] = "AS";
        CountryCode["AT"] = "AT";
        CountryCode["AU"] = "AU";
        CountryCode["AW"] = "AW";
        CountryCode["AX"] = "AX";
        CountryCode["AZ"] = "AZ";
        CountryCode["BA"] = "BA";
        CountryCode["BB"] = "BB";
        CountryCode["BD"] = "BD";
        CountryCode["BE"] = "BE";
        CountryCode["BF"] = "BF";
        CountryCode["BG"] = "BG";
        CountryCode["BH"] = "BH";
        CountryCode["BI"] = "BI";
        CountryCode["BJ"] = "BJ";
        CountryCode["BL"] = "BL";
        CountryCode["BM"] = "BM";
        CountryCode["BN"] = "BN";
        CountryCode["BO"] = "BO";
        CountryCode["BQ"] = "BQ";
        CountryCode["BR"] = "BR";
        CountryCode["BS"] = "BS";
        CountryCode["BT"] = "BT";
        CountryCode["BV"] = "BV";
        CountryCode["BW"] = "BW";
        CountryCode["BY"] = "BY";
        CountryCode["BZ"] = "BZ";
        CountryCode["CA"] = "CA";
        CountryCode["CC"] = "CC";
        CountryCode["CD"] = "CD";
        CountryCode["CF"] = "CF";
        CountryCode["CG"] = "CG";
        CountryCode["CH"] = "CH";
        CountryCode["CI"] = "CI";
        CountryCode["CK"] = "CK";
        CountryCode["CL"] = "CL";
        CountryCode["CM"] = "CM";
        CountryCode["CN"] = "CN";
        CountryCode["CO"] = "CO";
        CountryCode["CR"] = "CR";
        CountryCode["CU"] = "CU";
        CountryCode["CV"] = "CV";
        CountryCode["CW"] = "CW";
        CountryCode["CX"] = "CX";
        CountryCode["CY"] = "CY";
        CountryCode["CZ"] = "CZ";
        CountryCode["DE"] = "DE";
        CountryCode["DJ"] = "DJ";
        CountryCode["DK"] = "DK";
        CountryCode["DM"] = "DM";
        CountryCode["DO"] = "DO";
        CountryCode["DZ"] = "DZ";
        CountryCode["EC"] = "EC";
        CountryCode["EE"] = "EE";
        CountryCode["EG"] = "EG";
        CountryCode["EH"] = "EH";
        CountryCode["ER"] = "ER";
        CountryCode["ES"] = "ES";
        CountryCode["ET"] = "ET";
        CountryCode["FI"] = "FI";
        CountryCode["FJ"] = "FJ";
        CountryCode["FK"] = "FK";
        CountryCode["FM"] = "FM";
        CountryCode["FO"] = "FO";
        CountryCode["FR"] = "FR";
        CountryCode["GA"] = "GA";
        CountryCode["GB"] = "GB";
        CountryCode["GD"] = "GD";
        CountryCode["GE"] = "GE";
        CountryCode["GF"] = "GF";
        CountryCode["GG"] = "GG";
        CountryCode["GH"] = "GH";
        CountryCode["GI"] = "GI";
        CountryCode["GL"] = "GL";
        CountryCode["GM"] = "GM";
        CountryCode["GN"] = "GN";
        CountryCode["GP"] = "GP";
        CountryCode["GQ"] = "GQ";
        CountryCode["GR"] = "GR";
        CountryCode["GS"] = "GS";
        CountryCode["GT"] = "GT";
        CountryCode["GU"] = "GU";
        CountryCode["GW"] = "GW";
        CountryCode["GY"] = "GY";
        CountryCode["HK"] = "HK";
        CountryCode["HM"] = "HM";
        CountryCode["HN"] = "HN";
        CountryCode["HR"] = "HR";
        CountryCode["HT"] = "HT";
        CountryCode["HU"] = "HU";
        CountryCode["ID"] = "ID";
        CountryCode["IE"] = "IE";
        CountryCode["IL"] = "IL";
        CountryCode["IM"] = "IM";
        CountryCode["IN"] = "IN";
        CountryCode["IO"] = "IO";
        CountryCode["IQ"] = "IQ";
        CountryCode["IR"] = "IR";
        CountryCode["IS"] = "IS";
        CountryCode["IT"] = "IT";
        CountryCode["JE"] = "JE";
        CountryCode["JM"] = "JM";
        CountryCode["JO"] = "JO";
        CountryCode["JP"] = "JP";
        CountryCode["KE"] = "KE";
        CountryCode["KG"] = "KG";
        CountryCode["KH"] = "KH";
        CountryCode["KI"] = "KI";
        CountryCode["KM"] = "KM";
        CountryCode["KN"] = "KN";
        CountryCode["KP"] = "KP";
        CountryCode["KR"] = "KR";
        CountryCode["KW"] = "KW";
        CountryCode["KY"] = "KY";
        CountryCode["KZ"] = "KZ";
        CountryCode["LA"] = "LA";
        CountryCode["LB"] = "LB";
        CountryCode["LC"] = "LC";
        CountryCode["LI"] = "LI";
        CountryCode["LK"] = "LK";
        CountryCode["LR"] = "LR";
        CountryCode["LS"] = "LS";
        CountryCode["LT"] = "LT";
        CountryCode["LU"] = "LU";
        CountryCode["LV"] = "LV";
        CountryCode["LY"] = "LY";
        CountryCode["MA"] = "MA";
        CountryCode["MC"] = "MC";
        CountryCode["MD"] = "MD";
        CountryCode["ME"] = "ME";
        CountryCode["MF"] = "MF";
        CountryCode["MG"] = "MG";
        CountryCode["MH"] = "MH";
        CountryCode["MK"] = "MK";
        CountryCode["ML"] = "ML";
        CountryCode["MM"] = "MM";
        CountryCode["MN"] = "MN";
        CountryCode["MO"] = "MO";
        CountryCode["MP"] = "MP";
        CountryCode["MQ"] = "MQ";
        CountryCode["MR"] = "MR";
        CountryCode["MS"] = "MS";
        CountryCode["MT"] = "MT";
        CountryCode["MU"] = "MU";
        CountryCode["MV"] = "MV";
        CountryCode["MW"] = "MW";
        CountryCode["MX"] = "MX";
        CountryCode["MY"] = "MY";
        CountryCode["MZ"] = "MZ";
        CountryCode["NA"] = "NA";
        CountryCode["NC"] = "NC";
        CountryCode["NE"] = "NE";
        CountryCode["NF"] = "NF";
        CountryCode["NG"] = "NG";
        CountryCode["NI"] = "NI";
        CountryCode["NL"] = "NL";
        CountryCode["NO"] = "NO";
        CountryCode["NP"] = "NP";
        CountryCode["NR"] = "NR";
        CountryCode["NU"] = "NU";
        CountryCode["NZ"] = "NZ";
        CountryCode["OM"] = "OM";
        CountryCode["PA"] = "PA";
        CountryCode["PE"] = "PE";
        CountryCode["PF"] = "PF";
        CountryCode["PG"] = "PG";
        CountryCode["PH"] = "PH";
        CountryCode["PK"] = "PK";
        CountryCode["PL"] = "PL";
        CountryCode["PM"] = "PM";
        CountryCode["PN"] = "PN";
        CountryCode["PR"] = "PR";
        CountryCode["PS"] = "PS";
        CountryCode["PT"] = "PT";
        CountryCode["PW"] = "PW";
        CountryCode["PY"] = "PY";
        CountryCode["QA"] = "QA";
        CountryCode["RE"] = "RE";
        CountryCode["RO"] = "RO";
        CountryCode["RS"] = "RS";
        CountryCode["RU"] = "RU";
        CountryCode["RW"] = "RW";
        CountryCode["SA"] = "SA";
        CountryCode["SB"] = "SB";
        CountryCode["SC"] = "SC";
        CountryCode["SD"] = "SD";
        CountryCode["SE"] = "SE";
        CountryCode["SG"] = "SG";
        CountryCode["SH"] = "SH";
        CountryCode["SI"] = "SI";
        CountryCode["SJ"] = "SJ";
        CountryCode["SK"] = "SK";
        CountryCode["SL"] = "SL";
        CountryCode["SM"] = "SM";
        CountryCode["SN"] = "SN";
        CountryCode["SO"] = "SO";
        CountryCode["SR"] = "SR";
        CountryCode["SS"] = "SS";
        CountryCode["ST"] = "ST";
        CountryCode["SV"] = "SV";
        CountryCode["SX"] = "SX";
        CountryCode["SY"] = "SY";
        CountryCode["SZ"] = "SZ";
        CountryCode["TC"] = "TC";
        CountryCode["TD"] = "TD";
        CountryCode["TF"] = "TF";
        CountryCode["TG"] = "TG";
        CountryCode["TH"] = "TH";
        CountryCode["TJ"] = "TJ";
        CountryCode["TK"] = "TK";
        CountryCode["TL"] = "TL";
        CountryCode["TM"] = "TM";
        CountryCode["TN"] = "TN";
        CountryCode["TO"] = "TO";
        CountryCode["TR"] = "TR";
        CountryCode["TT"] = "TT";
        CountryCode["TV"] = "TV";
        CountryCode["TW"] = "TW";
        CountryCode["TZ"] = "TZ";
        CountryCode["UA"] = "UA";
        CountryCode["UG"] = "UG";
        CountryCode["UM"] = "UM";
        CountryCode["US"] = "US";
        CountryCode["UY"] = "UY";
        CountryCode["UZ"] = "UZ";
        CountryCode["VA"] = "VA";
        CountryCode["VC"] = "VC";
        CountryCode["VE"] = "VE";
        CountryCode["VG"] = "VG";
        CountryCode["VI"] = "VI";
        CountryCode["VN"] = "VN";
        CountryCode["VU"] = "VU";
        CountryCode["WF"] = "WF";
        CountryCode["WS"] = "WS";
        CountryCode["YE"] = "YE";
        CountryCode["YT"] = "YT";
        CountryCode["ZA"] = "ZA";
        CountryCode["ZM"] = "ZM";
        CountryCode["ZW"] = "ZW";
    })(CountryCode = AddressType.CountryCode || (AddressType.CountryCode = {}));
})(AddressType || (AddressType = {}));
