import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTable } from '@f2w/data-table';
import React from 'react';
import { MortgageStatus } from '../types';
import * as MortgageServices from '../services/mortgageServices';
import { translate } from 'Services/Translator';
import { formatDateShort } from 'Services/DateHelper';
import { formatMoney, formatPercentage } from 'Services/NumberHelper';
import { Badge, Button, Icon } from 'Components/Atoms';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { DeleteIcon } from 'Components/App/Icons';
const getStatusBadgeColor = (status) => {
    const statusBadgeColorMap = {
        [MortgageStatus.ACTIVE]: 'success',
        [MortgageStatus.UPCOMING]: 'primaryOutline',
        [MortgageStatus.EXPIRED]: 'danger',
    };
    return statusBadgeColorMap[status] || 'darkOutline';
};
export const MortgageDataTable = DataTable.factory()(() => ({
    tableId: 'Mortgage',
    RowActions: ({ onEditMortgage, onDeleteMortgage, row: { original: { itemId: id } }, }) => {
        return (_jsx(ActionsDropdown, { id: id, quickActions: [
                {
                    icon: "edit",
                    title: translate('mortgage.edit'),
                    onClick: () => {
                        onEditMortgage(id);
                    }
                },
            ], children: _jsxs(ActionsDropdown.Link, { onClick: () => onDeleteMortgage(id), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }) }));
    },
    Actions: ({ onCreateMortgage }) => (_jsx("div", { children: _jsxs(Button, { "$size": 'lg', color: 'primary', title: translate('mortgage.new'), onClick: () => onCreateMortgage(), children: [_jsx(Icon, { icon: 'plus' }), " ", _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('new') })] }) })),
    usePagination: {},
    initialState: {
        pageSize: 25,
        sortBy: [
            { id: 'start', desc: false, },
            { id: 'end', desc: false, },
        ],
    },
    getId: (row) => row.itemId,
    columns: {
        $actions: {
            sticky: 'left',
        },
        property: {
            Header: translate('mortgage.property'),
            Cell: ({ value, onPropertyClick }) => (_jsx("a", { href: "#", onClick: (event => {
                    event.preventDefault();
                    onPropertyClick(value.id);
                }), children: value.name })),
        },
        label: {
            Header: _jsx("div", { children: translate('mortgage.label') }),
            accessor: d => d.mortgage.label
        },
        value: {
            Header: _jsx("div", { className: "text-right w-100", children: translate('mortgage.value') }),
            accessor: d => formatMoney(d.mortgage.value),
            useSortBy: {},
            className: "text-right",
            style: {
                whiteSpace: 'nowrap'
            }
        },
        interest: {
            Header: translate('mortgage.interestRate'),
            Cell: ({ row: { original: d } }) => (!d.mortgage ? null : _jsx("div", { children: d.mortgage.type == 'floating' ? translate('mortgage.type.floating') : formatPercentage(d.mortgage.effectiveInterest / 100, '0.0[00]%') })),
        },
        type: {
            Header: translate('mortgage.type'),
            accessor: d => translate('mortgage.type.' + d.mortgage.type),
        },
        start: {
            Header: translate('mortgage.start'),
            accessor: d => d.mortgage.start ? formatDateShort(d.mortgage.start) : '–',
            useSortBy: {},
        },
        end: {
            Header: translate('mortgage.end'),
            accessor: d => d.mortgage.end ? formatDateShort(d.mortgage.end) : '–',
            useSortBy: {},
        },
        status: {
            Header: translate('mortgage.status'),
            accessor: d => {
                const color = getStatusBadgeColor(d.mortgage.status);
                return (_jsx(Badge, { "$color": color, children: translate('mortgage.status.' + d.mortgage.status) }));
            },
        },
        amortization: {
            Header: _jsx("div", { className: "text-right w-100", children: translate('mortgage.amortization') }),
            accessor: d => d.mortgage.amortisationAmount ? formatMoney(d.mortgage.amortisationAmount) : '–',
            useSortBy: {},
            headerClassName: "text-right",
            className: "text-right",
            style: {
                whiteSpace: 'nowrap'
            }
        },
        amortizationDue: {
            Header: translate('mortgage.amortizationDue'),
            accessor: d => d.mortgage.amortisationDeadline ? formatDateShort(d.mortgage.amortisationDeadline) : '–',
            useSortBy: {},
        },
    },
}));
export function useMortgageTable({ services = MortgageServices, onCreateMortgage, onEditMortgage, onDeleteConfirm, onPropertyClick }) {
    const tableModel = React.useMemo(() => MortgageDataTable.create({
        manualControl: true,
        onCreateMortgage: onCreateMortgage,
        onEditMortgage: onEditMortgage,
        onDeleteMortgage: (id) => onDeleteConfirm(id),
        onPropertyClick: onPropertyClick,
        getData: ({ request }) => services.getMortgages(request),
    }), []);
    return tableModel.useTable({
        services,
        onCreateMortgage,
        onEditMortgage,
        onDeleteConfirm,
        onPropertyClick
    });
}
