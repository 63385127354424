import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import { formatDateShort } from 'Services/DateHelper';
import moment from 'moment';
import { formatMoney } from 'Services/NumberHelper';
import { useOpeningBalanceContext } from '../../OpeningBalance/OpeningBalanceContext';
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
export const FinancialAccountGroupTableModel = Table.factory()(() => ({
    tableId: 'FinancialAccountGroup',
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        hiddenColumns: ['isEmpty']
    },
    RowActions: ({ row: { id }, openFinancialAccountStatement }) => {
        return _jsx(ActionsDropdown, { id: id, quickActions: [
                {
                    icon: 'eye',
                    title: translate('dunning.accountStatement'),
                    onClick: () => openFinancialAccountStatement(Number(id))
                },
            ] });
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        position: {
            Header: translate('accounting.financial.account.position'),
            width: 50,
        },
        label: {
            Header: ({ accountGroup }) => accountGroup.label
        },
        openingBalance: {
            Header: ({ startDate }) => _jsx("div", { className: "text-right w-100", children: translate('accounting.openingBalance') + ' ' + formatDateShort(moment(startDate)) }),
            Cell: ({ value, fiscalYear, startDate, row: { original: { id: financialAccountId } } }) => {
                const { openForm, disableOpeningBalanceModification } = useOpeningBalanceContext();
                return _jsxs(_Fragment, { children: [formatMoney(value), _jsx("a", { className: startDate != fiscalYear.starts || disableOpeningBalanceModification ? "btn btn-sm btn-icon disabled" : "btn btn-sm btn-icon", onClick: () => {
                                openForm({
                                    financialAccountId,
                                    fiscalYearId: fiscalYear.id
                                });
                            }, children: _jsx("i", { className: "la la-edit" }) })] });
            },
            className: "text-right",
            width: 200,
        },
        closingBalance: {
            Header: _jsx("div", { className: "text-right w-100", children: translate('accounting.valueAndSaldo') }),
            Cell: ({ value }) => formatMoney(value),
            headerClassName: "text-right",
            className: "text-right",
            width: 200,
        },
        isEmpty: {
            Cell: ({ row: { original: { openingBalance, closingBalance } } }) => {
                return (!openingBalance && !closingBalance) ? 'Y' : 'N';
            },
            useFilters: {},
        }
    }
}));
