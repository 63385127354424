import { rentalObjectTypeOptions } from "Enums/RentalObjectType";
import { getFloorLabelFromNumber } from "Components/Molecules/Floors/services";
export const getPropertyNameFromGroupedData = (groupedStreets) => {
    return Object
        .keys(groupedStreets)
        .reduce((propertyName, street) => {
        const minHouseNumber = Math.min(...groupedStreets[street]);
        const maxHouseNumber = Math.max(...groupedStreets[street]);
        let groupedNumbers = "";
        if (!isNaN(minHouseNumber) && !isNaN(maxHouseNumber)) {
            groupedNumbers = minHouseNumber === maxHouseNumber
                ? minHouseNumber.toString()
                : `${minHouseNumber ?? ""} - ${maxHouseNumber ?? ""}`;
        }
        if (propertyName !== undefined) {
            return `${propertyName} / ${street} ${groupedNumbers}`;
        }
        return `${street} ${groupedNumbers}`;
    }, undefined);
};
export const getDefaultRentalObjectNameFromData = (rentalObject) => {
    if (!rentalObject) {
        return undefined;
    }
    const floorLabel = getFloorLabelFromNumber(rentalObject.floor);
    const typeName = rentalObjectTypeOptions[0].options[0].label;
    return `${rentalObject.roomsCount}-Zi-${typeName} ${floorLabel}`.trim();
};
