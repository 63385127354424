import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { sizeToken, toPx } from '@f2w/theme';
import styled, { css } from 'styled-components';
import { InputTheme, useInputOnChange } from './base';
export const TextareaRoot = styled.textarea ``;
export const TextArea = forwardRef(({ $variant, $size, ...props }, ref) => {
    const onChange = useInputOnChange(props.onChange);
    return (_jsx(TextAreaWrapper, { "$variant": $variant, "$size": $size, children: _jsx(TextareaRoot, { ...{
                ...props,
                onChange,
                ref,
            } }) }));
});
export const textAreaSizeToken = sizeToken.extend({
    xs: {
        styles: (prev) => ({
            ...prev,
            paddingX: 6,
            paddingY: 6,
        }),
    },
    sm: {
        styles: (prev) => ({
            ...prev,
            paddingX: 10,
            paddingY: 10,
        }),
    },
    md: {
        styles: (prev) => ({
            ...prev,
            paddingX: 12,
            paddingY: 12,
        }),
    },
    lg: {
        styles: (prev) => ({
            ...prev,
            paddingX: 16,
            paddingY: 16,
        }),
    },
});
export function useTextAreaTheme(props) {
    const vars = InputTheme.useVars(props, textAreaSizeToken);
    return {
        '--fw-input__font-size': toPx(vars.fontSize),
        '--fw-input__line-height': toPx(vars.lineHeight),
        '--fw-input__border-radius': toPx(vars.borderRadius),
        '--fw-input__padding-x': toPx(vars.paddingX),
        '--fw-input__padding-y': toPx(vars.paddingY),
        '--fw-input__placeholder-color': vars.placeholderColor,
        '--fw-input__icon-color': vars.iconColor,
        '--fw-input__border-color': vars.borderColor,
        '--fw-input__state-color': vars.stateIconColor?.$500,
        '--fw-input__state-icon-color': vars.stateIconColor?.$500,
        '--fw-input__active-color': vars.stateColor?.$500 ?? vars.activeColor.$500,
        '--fw-input__active-shadow-color': `${vars.stateColor?.$500 ?? vars.activeColor.$500}40`,
    };
}
const TextAreaWrapper = styled.div(({ theme: { rem, palette }, $variant, $size }) => {
    const styles = useTextAreaTheme({ $size, $variant });
    return css `
        ${styles};
        display: flex;
        align-items: center;
        overflow: hidden;

        transition-timing-function: ease-in-out;
        transition-duration: 100ms;
        transition-property: box-shadow, border;

        padding: var(--fw-input__padding-Y, 10px) var(--fw-input__padding-x, 10px);
        color: var(--fw-input__color, inherit);
        background: var(--fw-input__background-color, white);
        border: var(--fw-input__border-size, 1px) solid var(--fw-input__border-color, #aaa);
        border-radius: var(--fw-input__border-radius, 0);

        font-size: var(--fw-input__font-size, 14px);
        line-height: var(--fw-input__line-height, 1.25em);

        textarea {
            width: 100%;
            outline: 0;
            padding: 0;

            font-size: var(--fw-input__font-size, 14px);
            line-height: var(--fw-input__line-height, 1.25em);
            letter-spacing: inherit;
            font-weight: inherit;
            font-family: inherit;
            background: inherit;
            color: inherit;
            outline: none;
            border: none;
        }

        &:not([aria-disabled="true"]) {
            &:hover {
                border-color: var(--fw-input__state-color, var(--fw-color-neutral-800));
            }

            &:focus-within {
                border-color: var(--fw-input__active-color);

                &:hover {
                    box-shadow: 0 0 0 4px var(--fw-input__active-shadow-color);
                }
            }
        }

        &[aria-busy="true"] {
            animation-name: loading;
            animation-duration: 1.2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    `;
});
