import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ContentHeader, Header, Section } from 'Atoms/Layout/components';
import { translate } from 'Services/Translator';
import React from 'react';
import { Div, FlexRow } from 'Atoms/Layout';
import { Button, Icon, Text, Title } from 'Components/Atoms';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
import { ActiveView, useObjectViewModal } from 'Components/ObjectViewModal';
import { DateType, MoneyType } from '@f2w/form';
import RentStateBadge from 'Pages/Rent/components/RentStateBadge';
import { hasFeature } from "Services/FeatureFlagManager";
export const RightSide = ({ groups, group, renderNav, view: { data: contract } }) => {
    const { setCurrentActiveView } = useObjectViewModal();
    const goToRentOverview = React.useCallback((rentId) => {
        setCurrentActiveView(ActiveView.RENT, rentId, contract.rentalObject.id);
    }, []);
    return _jsxs(_Fragment, { children: [_jsxs(Header.Sticky, { children: [_jsx(Title, { h6: true, as: 'h3', children: translate('baseData.contract.rents') }), hasFeature('NEW_CONTRACT_CHANGE') && (_jsx(Button, { variant: 'outline', href: `/app/wizard/contract-modification/${contract.id}`, children: translate('processes.modifyContract') }))] }), _jsx(Section, { pt: 0, children: contract.rents.map(rent => (_jsxs(ViewModal.ObjectItem, { style: { gap: 20 }, onClick: () => goToRentOverview(rent.id), children: [_jsxs(FlexRow, { textLeft: true, alignCenter: true, columnGap: 20, grow: 1, flexWrap: true, children: [_jsxs(Text, { nowrap: true, body: true, neutral: 700, children: [DateType.format(rent.startDate), " - ", rent.endDate ? DateType.format(rent.endDate) : translate('contract.ends.notLimited')] }), _jsxs(Text, { flexGrow: 1, small: true, neutral: 500, children: [MoneyType.format(rent.netAmount), " / ", MoneyType.format(rent.grossAmount)] })] }), _jsxs(FlexRow, { justifyRight: true, alignCenter: true, gap: 16, children: [_jsx(RentStateBadge, { state: rent.state, isVacancy: rent.isVacancy }), _jsx(Icon, { icon: "chevron-right", size: 21 })] })] }, 'contract-' + rent.id))) }), groups.length > 1 && _jsx(Section.Nav, { sticky: 0, children: renderNav() }), group.props.showHeader && (_jsxs(ContentHeader, { sticky: true, children: [_jsx(Title, { h6: true, as: 'h3', children: group.title }), _jsxs(Div, { children: [group.render('ContentHeader'), group.renderActions()] })] })), group.render('Content')] });
};
