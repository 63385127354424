import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import Actions from './DamageTableActions';
import { Button } from 'Components/Atoms/Button';
import { Icon } from 'Components/Atoms/Icon';
import { formatDateShort } from 'Services/DateHelper';
import moment from 'moment';
import InlineDamageStatusEdit from 'Pages/Damage/Overview/components/InlineDamageStatusEdit/InlineDamageStatusEdit';
import TruncatedText from 'Components/Atoms/TruncatedText/TruncatedText';
import { RentalObjectCard } from 'Components/App/card';
const model = Table.factory()(() => ({
    tableId: 'Damage',
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        sortBy: [
            {
                id: 'id',
                desc: true
            }
        ]
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        property: {
            Header: translate('property'),
            accessor: ({ property }) => property.name,
            useSortBy: {},
            Cell: ({ row: { original: damage } }) => {
                const { rentalObject, building } = damage;
                return (_jsx(RentalObjectCard, { rentalObjectName: rentalObject.name, rentalObjectId: rentalObject.id, buildingName: building.name }));
            }
        },
        status: {
            Header: translate('damage.status'),
            accessor: 'status',
            useSortBy: {},
            Cell: ({ row, value, onChangeDamageStatus, refresh }) => _jsx(InlineDamageStatusEdit, { status: value, onChange: ({ value }) => {
                    onChangeDamageStatus(row.original.id, value);
                    refresh();
                } }),
            useFilters: {},
        },
        room: {
            Header: translate('damage.room'),
            accessor: ({ room }) => room?.name,
            useSortBy: {},
        },
        asset: {
            Header: translate('damage.asset'),
            accessor: ({ asset }) => asset?.name,
            useSortBy: {},
        },
        dateOfDamage: {
            Header: translate('damage.dateOfDamage'),
            accessor: 'dateOfDamage',
            useSortBy: {},
            Cell: ({ value: dateOfDamage }) => (_jsx(_Fragment, { children: dateOfDamage
                    ? formatDateShort(moment(dateOfDamage))
                    : translate('notAvailable') })),
        },
        shortDescription: {
            Header: translate('damage.shortDescription'),
            accessor: 'shortDescription',
            Cell: ({ value: shortDescription }) => (_jsx(TruncatedText, { text: shortDescription, maxWidth: 100, maxLines: 2 })),
            useSortBy: {},
        },
    },
    RowActions: Actions,
    Actions: ({ onCreateDamage }) => (_jsx("div", { children: _jsxs(Button, { "$size": 'lg', title: translate('damage.new'), onClick: () => onCreateDamage(), children: [_jsx(Icon, { icon: 'plus' }), _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('new') })] }) })),
}));
export default model;
