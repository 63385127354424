import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import { RentalObjectCard } from 'Components/App/card';
import DebitorInvoiceState from 'Pages/Debitor/components/DebitorInvoiceState';
import { Section } from 'Atoms/Layout/components';
import { Button, Tbody, Td, TdActions, Th, Thead, Tr } from 'Components/Atoms';
import { formatMoney } from 'Services/NumberHelper';
import moment from 'moment';
import { PersonAccountStatementModal } from "Pages/Dunning/components/PersonAccountStatementModal";
import { PersonType } from "Enums/PersonType";
export const InvoicesTab = ({ group: { data: { invoices = [] }, data, }, view, onStreetClick, }) => {
    const getInvoiceDetailsUrl = React.useCallback((invoiceId) => {
        return generateRoute('list_debitor_invoices', { invoiceId });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PersonAccountStatementModal, { person: view.state.personAccountStatementOpen ? {
                    id: data.id,
                    name: data.personType === PersonType.CORPORATE_PERSON
                        ? data.corporatePerson.companyName
                        : `${data.naturalPerson.firstName} ${data.naturalPerson.lastName}`,
                    address: data.address,
                    dateOfBirth: data.dateOfBirth
                } : undefined, onClose: () => {
                    view.setState({ ...view.state, personAccountStatementOpen: false });
                } }), invoices.length > 0 && (_jsxs(_Fragment, { children: [_jsxs(Section.Table, { stickyHead: 0, colSpan: 5, children: [_jsxs(Thead, { children: [_jsx(Th, { children: translate('person.details.invoices.invoice') }), _jsx(Th, { children: translate('person.details.invoices.amount') }), _jsx(Th, { align: 'center', children: translate('person.details.invoices.status') }), _jsx(Th, { children: translate('debitor.details.dueDate') }), _jsx(Th, { children: " " })] }), _jsx(Tbody, { children: invoices.map(invoice => (_jsxs(Tr, { children: [_jsx(Td, { children: _jsx(RentalObjectCard, { rentalObjectName: invoice.rentalObject.name, buildingName: invoice.building.name, onClick: onStreetClick(invoice.rentalObject.id) }) }), _jsx(Td, { children: formatMoney(invoice.amountGross) }), _jsx(Td, { align: 'center', children: _jsx(DebitorInvoiceState, { size: 'sm', state: invoice.status }) }), _jsx(Td, { children: invoice.dueDate
                                                ? moment(invoice.dueDate).format('DD.MM.YYYY')
                                                : translate('notAvailable') }), _jsx(TdActions, { id: invoice.id, sticky: 'right', quickActions: [
                                                {
                                                    icon: 'eye',
                                                    title: translate('btn.view'),
                                                    href: getInvoiceDetailsUrl(invoice.id),
                                                    target: "_blank"
                                                },
                                            ] })] }, `table-row-${invoice.id}`))) })] }), _jsx(Section, { sticky: { bottom: 0 }, style: { textAlign: 'right' }, children: _jsx(Button, { label: translate('person.details.seeAll'), href: generateRoute('list_debitor_invoices', { personId: data.id }), target: '_blank' }) })] }))] }));
};
