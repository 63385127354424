import { generate } from "Services/Router";
import client from "Utils/client";
export var FinancialAccountServices;
(function (FinancialAccountServices) {
    FinancialAccountServices.getFinancialAccountStatement = async (financialAccountId, fiscalYearId, dateRange) => {
        const route = generate('api.financialAccounts.statement', {
            financialAccount: financialAccountId,
            fiscalYear: fiscalYearId,
            dateRange: dateRange ? {
                start: dateRange.from ?? null,
                end: dateRange.to ?? null
            } : null
        });
        return client
            .get(route)
            .then(response => response.data);
    };
})(FinancialAccountServices || (FinancialAccountServices = {}));
