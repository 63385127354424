import { forEachObject } from '@f2w/utils';
import { KeyOrChild } from './IndexDescriptor';
export class ChildDescriptor {
    name;
    data = Object.create(null);
    config;
    constructor(name, config) {
        this.name = name;
        this.config = { name, ...config };
    }
    static create(key, config) {
        return new ChildDescriptor(key, config);
    }
    static fromConfig(config) {
        const map = Object.create(null);
        forEachObject(config, (key, config) => {
            map[key] = new ChildDescriptor(key, {
                ...config,
            });
        });
        return map;
    }
    add(child) {
        const config = this.config;
        let set;
        if (config.set)
            set = (value) => {
                config.set(child, value);
            };
        Object.defineProperty(this.data, child.key, {
            configurable: true,
            enumerable: true,
            get: () => config.getValue(child),
            set,
        });
    }
    remove(keyOrChild) {
        const key = KeyOrChild.key(keyOrChild);
        delete this.data[key];
    }
}
