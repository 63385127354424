import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractFormType, SimpleType } from '../core';
import { translate } from 'Services/Translator';
import { Col, Row } from 'Atoms/Grid';
import { CountryType } from './CountryType';
import { StringType } from './StringType';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
export class AddressType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: translate('person.address'),
            zipKeyName: 'zip'
        };
    }
    buildFields() {
        const fields = this._fields = {
            street: new StringType({
                label: translate('address.street'),
                ...this.options.street,
                constraints: {
                    max: [70, translate('form.validation.noLongerThan', { length: 50 })],
                },
                autocomplete: {
                    getItems: async (value) => AutocompleteService.street(value),
                    getItemValue: (item) => item,
                }
            }),
            houseNumber: new StringType({
                label: translate('address.housenumber'),
                ...this.options.houseNumber,
                constraints: {
                    max: [10, translate('form.validation.noLongerThan', { length: 10 })],
                },
            }),
            [this.options.zipKeyName]: new StringType({
                label: translate('address.zip'),
                ...this.options.zip,
                constraints: {
                    max: [10, translate('form.validation.noLongerThan', { length: 10 })],
                },
                autocomplete: {
                    getItems: async (value) => AutocompleteService.zip(value),
                    getItemValue: (item) => item.postalCode,
                    getItemLabel: (item) => `${item.postalCode} - ${item.city}`,
                    onSelect: (item) => {
                        const field = fields.city.field;
                        if (field) {
                            field.setValue(item.city);
                        }
                    },
                }
            }),
            city: new StringType({
                label: translate('address.city'),
                ...this.options.city,
                constraints: {
                    max: [50, translate('form.validation.noLongerThan', { length: 50 })],
                },
                autocomplete: {
                    getItems: async (value) => AutocompleteService.city(value),
                    getItemValue: (item) => item.city,
                    getItemLabel: (item) => `${item.postalCode} - ${item.city}`,
                    onSelect: (item) => {
                        const field = fields[this.options.zipKeyName]?.field;
                        if (field) {
                            field.setValue(item.postalCode);
                        }
                    },
                }
            }),
            country: new CountryType({
                ...this.options.country,
                getProps: () => ({ alignMenu: 'right' })
            }),
        };
        if (this.options.hideStreet) {
            delete fields.street;
            delete fields.houseNumber;
        }
        if (this.options.hideZip) {
            delete fields.zip;
        }
        if (this.options.hideCity) {
            delete fields.city;
        }
        if (this.options.hideCountry) {
            delete fields.country;
        }
        return fields;
    }
    _renderWidget() {
        const { houseNumber, street, city, country, } = this._fields;
        const zip = this._fields[this.options.zipKeyName];
        return _jsxs(_Fragment, { children: [street?.field && houseNumber?.field && _jsxs(Row, { gap: 10, children: [_jsx(Col, { children: street.render() }), _jsx(Col, { xs: 2, minw: 90, children: houseNumber.render() })] }), _jsxs(Row, { gap: 10, children: [zip?.field && _jsx(Col, { xs: 2, minw: 110, children: zip.render() }), city?.field && _jsx(Col, { children: city.render() }), country?.field && _jsx(Col, { xs: 'auto', children: country.render() })] })] });
    }
}
var AutocompleteService;
(function (AutocompleteService) {
    AutocompleteService.zip = async (term, limit = 50) => {
        const route = generateRoute('api.postalCode.autocomplete');
        const { data } = await client.get(route, {
            params: { limit, term }
        });
        return data.results;
    };
    AutocompleteService.city = async (term, limit = 50) => {
        const route = generateRoute('api.city.autocomplete');
        const { data } = await client.get(route, {
            params: { limit, term }
        });
        return data.results;
    };
    AutocompleteService.street = async (term, limit = 50) => {
        const route = generateRoute('api.street.autocomplete');
        const { data } = await client.get(route, {
            params: { limit, term }
        });
        return data.results;
    };
})(AutocompleteService || (AutocompleteService = {}));
export class ZipType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super({
            label: translate('form.type.zip.label'),
            ...options,
            constraints: {
                ...options?.constraints,
                max: 10,
                matches: [/^\d{4}$/, translate('form.type.zip.error')],
            },
        }, parent);
    }
}
