import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import Actions from './UserTableActions';
import { Button } from 'Components/Atoms/Button';
import { Icon } from 'Components/Atoms/Icon';
import { Badge } from 'Atoms/Badge';
import { FlexRow } from 'Atoms/Layout';
const model = Table.factory()(() => ({
    tableId: 'User',
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        pageSize: 25,
        sortBy: [
            { id: 'id', desc: true }
        ]
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        fullName: {
            Header: translate('user.name'),
            useSortBy: {},
            Cell: ({ value, row: { original }, row, onEditUser }) => original.availableActions.includes('edit') ?
                _jsx("a", { href: "#", onClick: (e) => { e.preventDefault(); onEditUser(row); }, children: value }) : value,
        },
        jobTitle: {
            Header: translate('user.jobTitle'),
            useSortBy: {},
        },
        email: {
            Header: translate('person.email'),
            useSortBy: {},
        },
        cellPhoneNumber: {
            Header: translate('person.cellPhoneNumber'),
            useSortBy: {},
        },
        roles: {
            Header: translate('person.roles'),
            accessor: ({ roles }) => {
                return (_jsx(FlexRow, { gap: 5, children: roles.map((role, index) => _jsx(Badge, { "$size": "sm", "$color": "primary", children: role.label }, 'user-role-' + index)) }));
            }
        },
        isDisabled: {
            Header: translate('user.status'),
            accessor: ({ isDisabled }) => (isDisabled
                ? _jsx(Badge, { "$size": "sm", "$color": "danger", children: translate('user.blocked') })
                : _jsx(Badge, { "$size": "sm", "$color": "success", children: translate('user.active') })),
            useSortBy: {},
        },
    },
    RowActions: Actions,
    Actions: ({ onCreateUser, hitLimit }) => (_jsx("div", { children: _jsxs(Button, { "$size": 'lg', title: translate('user.new'), onClick: () => onCreateUser(), disabled: hitLimit, children: [_jsx(Icon, { icon: 'plus' }), _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('new') })] }) })),
}));
export default model;
