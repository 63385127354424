import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatcherApi } from 'Components/Dispatcher';
import { uniqueId } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { translate } from 'Services/Translator';
import useStableMemo from '@restart/hooks/useStableMemo';
import { FormControl } from 'Atoms/Input';
import { getDefaultRentalObjectNameFromData } from '../../utils/namingHelpers';
import { CreatePropertyApi, LocationApi } from '../../services';
import { CreatePropertyType } from '../../types/CreatePropertyType';
import { rentalObjectTypeOptions } from "Enums/RentalObjectType";
import moment from "moment/moment";
export function CreatePropertySearch(props) {
    const { onChange: _onChange, onSelect: _onSelect, } = props;
    const dispatcher = useDispatcherApi();
    const [current, setCurrent] = useState({ value: '' });
    const autocompleteProps = useStableMemo(() => ({
        getItems: (query) => LocationApi.search(query).catch(dispatcher.handleError),
        getItemValue: (item) => item.value,
        getItemId: (item) => item.id,
        getItemLabel: (item) => _jsx("span", { dangerouslySetInnerHTML: { __html: item.label } }),
        onChange: (value) => setCurrent({ value }),
        onSelect: (item) => {
            setCurrent(item);
            _onChange?.(item);
        },
    }), [_onChange]);
    const prefix = useMemo(() => LocationApi.getType(current.value), [current.value]);
    useEffect(() => {
        current.featureId && _onSelect?.(current.featureId);
    }, [current.featureId]);
    return _jsx(_Fragment, { children: _jsx(FormControl.Autocomplete, { placeholder: translate('createProperty.searchLabel'), ...props, ...autocompleteProps, style: { flexGrow: 1 }, value: current.value, prefix: prefix }) });
}
export function useCreatePropertySearch({ locationId: _initialLocationId, getSettings: _getSettings = CreatePropertyApi.getSettings, getLocationData: _getLocationData = CreatePropertyApi.getLocationData, }) {
    const [loading, setLoading] = useState(true);
    const [hidden, _setHidden] = useState(false);
    const [settings, setSettings] = useState(null);
    const [formData, _setFormData] = useState(null);
    const dispatcher = useDispatcherApi();
    const _confirmReset = useCallback(async () => {
        if (!formData || (await dispatcher.modal.confirmAsync({
            message: translate('createProperty.closeMessage'),
        }))) {
            _setFormData(null);
            return true;
        }
        return false;
    }, [formData]);
    const getSettings = useCallback(async () => {
        if (settings) {
            return settings;
        }
        return _getSettings()
            .then(s => {
            setSettings(s);
            return s;
        })
            .catch(e => dispatcher.handleError(e, 'error loading settings'));
    }, [settings]);
    const setData = useCallback(async (data) => {
        if (!data)
            return;
        const settings = await getSettings();
        _setFormData({
            id: String(uniqueId()),
            ...data,
            settings,
            property: {
                managerId: settings?.managers?.[0]?.id,
                mandateId: settings?.mandates?.[0]?.id,
                ...data?.property,
            },
        });
    }, [getSettings]);
    const setLocationData = useCallback(async (data) => {
        if (!data) {
            return;
        }
        const settings = await getSettings();
        const { buildings, address, pin, id, egid, egrid, featureId, attrs, label, ...rest } = data;
        await setData({
            ...rest,
            pin,
            id: String(id || featureId || uniqueId()),
            property: {
                egrid,
                name: `${address.street} ${address.houseNumber}`,
                zip: address.zip,
                city: address.city,
            },
            buildings: buildings
                .map((_building) => {
                return {
                    uuid: uuidv4(),
                    name: `${_building.address.street} ${_building.address.entranceNumber}`,
                    houseNumber: _building.address.entranceNumber,
                    bunker: _building.bunker,
                    buildYear: attrs.gbauj,
                    startOfRenting: attrs.gbauj ? moment(attrs.gbauj, 'YYYY').month(0).date(1).toDate() : null,
                    numberOfFloors: _building.numberOfFloors,
                    egid: _building.egid,
                    street: _building.address.street,
                    taxValue: 0,
                    rentalObjects: _building.objects
                        .map((_object, index) => {
                        return {
                            uuid: uuidv4(),
                            type: rentalObjectTypeOptions[0].options[0].value,
                            name: getDefaultRentalObjectNameFromData(_object),
                            ewid: _object.ewid,
                            area: _object.area,
                            numberOfRooms: _object.roomsCount,
                            floor: _object.floor,
                        };
                    })
                        .sort((a, b) => a.ewid < b.ewid),
                };
            }),
        });
    }, [getSettings]);
    const loadLocationById = useCallback(async (id) => {
        if (id && (await _confirmReset())) {
            await setLoading(true);
            await _getLocationData(id)
                .then(setLocationData)
                .catch(e => dispatcher.handleError(e, translate('createProperty.error.noDataFound')))
                .finally(() => setLoading(false));
        }
    }, [_getLocationData, setLocationData, settings, _confirmReset]);
    const loadData = useCallback(async (data) => {
        if (data && (await _confirmReset())) {
            await setData({ ...data });
        }
    }, [setData, settings, _confirmReset]);
    useEffect(() => {
        getSettings()
            .finally(() => {
            !_initialLocationId && setLoading(false);
        });
    }, []);
    useEffect(() => {
        if (settings) {
            loadLocationById(_initialLocationId);
        }
    }, [settings]);
    const locationValue = CreatePropertyType.useValue(() => {
        if (!formData)
            return { skipEmpty: true };
        return {
            initialValue: formData,
        };
    }, [formData]);
    const api = {
        loading,
        settings,
        formData,
        locationValue: locationValue,
        loadById: loadLocationById,
        load: loadData,
    };
    return api;
}
