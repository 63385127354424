import { mapObjectEntries, omit, omitKeys, pick, pickKeys, } from '@f2w/utils';
export class BaseObjectMap {
    get fields() {
        return this._shape;
    }
    get size() {
        return this._keys.length;
    }
    constructor(shape) {
        this.reset(shape);
    }
    keys() {
        return this._keys;
    }
    has(key) {
        return !!this._shape[key];
    }
    values() {
        return Object.values(this._shape);
    }
    get(key) {
        return this._shape[key];
    }
    forEach(cb) {
        this.keys().forEach(key => cb(this.get(key), key));
    }
    map(cb) {
        return this.keys().map(key => cb(this.get(key), key));
    }
    mapEntries(cb) {
        return mapObjectEntries(this._shape, (key, value) => {
            return cb(value, key);
        });
    }
    pickKeys(keys) {
        return pickKeys(this._shape, keys);
    }
    omitKeys(keys) {
        return omitKeys(this._shape, keys);
    }
}
export class ObjectMap extends BaseObjectMap {
    options;
    constructor(shape, options) {
        super(shape);
        this.options = options;
    }
    static create(options, shape) {
        return new ObjectMap(shape ?? {}, options);
    }
    clear() {
        this._shape = Object.create(null);
        this._keys = [];
        return this;
    }
    reset(shape) {
        this.clear();
        shape && this.update(shape);
        return this;
    }
    add(key, value) {
        const newValue = this.options?.onAdd(key, value);
        if (newValue) {
            this.has(key) || this._keys.push(key);
            Object.assign(this._shape, { [key]: newValue });
        }
    }
    update(shape) {
        shape && Object.keys(shape).forEach(key => {
            if (shape[key] !== undefined) {
                this.add(key, shape[key]);
            }
        });
        return this;
    }
    pick(keys) {
        return ObjectMap.create(pick(this._shape, keys));
    }
    omit(keys) {
        return ObjectMap.create(omit(this._shape, keys));
    }
}
