import { jsx as _jsx } from "react/jsx-runtime";
import { StringValueType } from '../../core';
import { InputField } from '../../theme';
import { SimpleType } from './BaseSimpleType';
import { FormControl } from 'Atoms/Input';
import { TextEditor } from 'Atoms/Input/controls';
export class TextType extends SimpleType {
    specs = StringValueType.define({});
    constructor(options) {
        super(options);
        this.__construct();
    }
    _renderForm(valueType, props) {
        return _jsx(InputField, { ...props, Widget: ({ field, ...innerProps }) => (_jsx(FormControl.TextArea, { ...innerProps, style: {
                    height: props.height,
                    ...innerProps.style,
                } })) });
    }
}
export class EditorType extends SimpleType {
    specs = this._getSpecs();
    constructor(options) {
        super(options);
        this.__construct();
    }
    _getSpecs() {
        return StringValueType.define({
            defineOptions: () => ({
                type: 'block',
            }),
        });
    }
    _renderForm(valueType, props) {
        const { type: editorType = 'BLOCK', placeholder, } = valueType.options;
        return _jsx(InputField, { ...props, Widget: ({ field, $size, $variant, disabled, id, value, onChange, onBlur, }) => (_jsx(TextEditor, { id: id, disabled: disabled, placeholder: placeholder, initialValue: field.initialValue, onChange: onChange, onBlur: onBlur, editorType: editorType, "$size": $size, "$variant": $variant })) });
    }
}
