import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { getCurrentUser } from "Services/UserServices";
const Actions = ({ cell, refresh, onEditUser, onBlockUser, onUnblockUser, onDeleteUser }) => {
    const row = cell.value;
    const canBeBlocked = row.availableActions.includes('block') && !row.isDisabled && getCurrentUser().id !== row.id;
    const canBeUnblocked = row.availableActions.includes('block') && row.isDisabled;
    const canBeDeleted = row.id !== getCurrentUser().id && !row.roles.find(role => role.name === "ROLE_OWNER");
    return (_jsxs(ActionsDropdown, { id: row.id, quickActions: [row.availableActions.includes('edit') && {
                icon: 'edit',
                title: translate('btn.edit'),
                onClick: () => onEditUser(row)
            }], children: [canBeBlocked &&
                _jsx(ActionsDropdown.Link, { icon: "lock", onClick: (e) => onBlockUser(row, refresh), children: translate('user.block') }), canBeUnblocked &&
                _jsx(ActionsDropdown.Link, { icon: "unlock", onClick: (e) => onUnblockUser(row, refresh), children: translate('user.unblock') }), canBeDeleted && (_jsx(ActionsDropdown.Link, { icon: "trash", onClick: () => onDeleteUser(row.id), children: translate('btn.delete') }))] }));
};
export default Actions;
