import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useViewType } from '@f2w/view';
import { translate } from 'Services/Translator';
import { PersonDetailsServices } from 'Pages/Person/Details/services/personDetailsServices';
import { ProfileTab } from '../components/ProfileTab';
import { ContractsTab } from '../components/ContractsTab';
import { InvoicesTab } from '../components/InvoicesTab';
import { PersonType } from 'Pages/Person/types/PersonType';
import { Button, ButtonToolbar } from 'Components/Atoms';
import generateRoute from 'Services/Router';
import { PersonTemplate } from '../components/PersonTemplate';
import { FinancialsTab } from 'Pages/Person/Details/components/PersonView/components/FinancialsTab';
import { AddressesTab } from 'Pages/Person/Details/components/PersonView/components/AddressesTab';
import { AddressState } from 'Pages/Person/enums';
import { WaitingListServices } from "Services/waitingList";
import { WaitingListTab } from "Pages/Person/Details/components/PersonView/components/WaitingListTab";
import { getGlobalContext } from "Services/GlobalContext";
import { Tier } from "Pages/Administration/Customers/enums";
export const usePersonView = ({ getPersonById: loadData = PersonDetailsServices.getPersonById, updatePerson: editPerson = PersonDetailsServices.updatePerson, onStreetClick: onStreetClick = () => () => { }, createPersonAddress = PersonDetailsServices.createPersonAddress, updatePersonAddress = PersonDetailsServices.updatePersonAddress, deletePersonAddress = PersonDetailsServices.deletePersonAddress, updatePersonWaitingListNotes = PersonDetailsServices.updatePersonWaitingListNotes, waitingListServices = WaitingListServices, }) => useViewType(() => new PersonType(), {
    id: 'person-view',
    defaultState: {
        leftActiveTab: 'profile',
        rightActiveTab: 'invoices',
        editWaitingListNotesEnabled: false,
        personAccountStatementOpen: false
    },
    DefaultTemplate: props => _jsx(PersonTemplate, { ...props }),
    load: async (id) => {
        const data = await loadData(id);
        return {
            ...data,
            bankAccountId: data.bankAccountObject?.id,
            pastAddress: {}
        };
    },
}, () => ({
    profile: {
        name: translate('person.details.profile'),
        Content: (props) => _jsx(ProfileTab, { ...props }),
        Form: ({ type }) => type.renderChild('profile'),
        save: async (values, view) => {
            const data = view.type.getChild('profile').getResolvedValue();
            const updateData = {
                ...data,
                corporatePerson: data.personType === PersonType.General.CORPORATE_PERSON
                    ? { ...data.corporatePerson }
                    : undefined,
                naturalPerson: data.personType === PersonType.General.NATURAL_PERSON
                    ? { ...data.naturalPerson }
                    : undefined,
            };
            return editPerson(values.id, updateData)
                .then(response => {
                view.updateData({
                    ...response
                });
            });
        }
    },
    financial: {
        name: translate('person.details.financial'),
        Content: (props) => _jsx(FinancialsTab, { ...props }),
    },
    contracts: {
        name: translate('person.details.contracts'),
        Content: (props) => _jsx(ContractsTab, { ...props, onStreetClick: onStreetClick }),
        ContentHeader: ({ view }) => (_jsx(Button.New, { href: generateRoute('create_contract'), target: '_blank' })),
    },
    pastAddress: {
        name: translate('person.details.addresses'),
        Content: (props) => _jsx(AddressesTab, { deletePersonAddress: deletePersonAddress, ...props }),
        Form: ({ type }) => type.renderChild('pastAddress'),
        forceValidation: true,
        Actions: ({ view }) => {
            return (_jsx(Button.New, { title: translate('person.btn.newAddress'), label: translate('person.btn.newAddress'), onClick: async () => {
                    await view.updateData({
                        pastAddress: view.type.children.pastAddress.cast()
                    });
                    view.openForm('pastAddress');
                } }));
        },
        save: async (values, view) => {
            const data = view.type.getChild('pastAddress').getResolvedValue();
            if (data.id) {
                return updatePersonAddress(data, values.id, data.id)
                    .then(addresses => {
                    const activeAddress = addresses.find(({ state }) => state === AddressState.ACTIVE);
                    view.updateData({
                        address: { ...activeAddress },
                        pastAddresses: addresses,
                        pastAddress: {}
                    });
                });
            }
            return createPersonAddress(data, values.id)
                .then(data => {
                view.updateData({
                    pastAddresses: data,
                    pastAddress: {}
                });
            });
        }
    },
    invoices: {
        name: translate('person.details.invoices'),
        Content: (props) => _jsx(InvoicesTab, { ...props, onStreetClick: onStreetClick }),
        ContentHeader: ({ view }) => (_jsxs(ButtonToolbar, { variant: 'outline', children: [_jsx(Button, { onClick: () => view.setState({
                        ...view.state,
                        personAccountStatementOpen: true
                    }), children: translate('dunning.accountStatement') }), _jsx(Button.New, { href: generateRoute('list_debitor_invoices', { openCreateForm: 1 }), target: '_blank' })] })),
    },
    waitingList: {
        name: translate('baseData.rentalObject.waitingList'),
        Content: (props) => _jsx(WaitingListTab, { ...props, waitingListServices: waitingListServices, updatePersonWaitingListNotes: updatePersonWaitingListNotes }),
        ContentHeader: ({ view }) => (_jsx(Button.Edit, { disabled: view.state.editWaitingListNotesEnabled, onClick: () => view.setState({
                ...view.state,
                editWaitingListNotesEnabled: true
            }) })),
        locked: getGlobalContext().customer.currentSubscriptionTier === Tier.PRIVATE,
        lockedMessage: translate('featureLocked.waitingList.text')
    }
}));
