import humanizeString from 'Utils/humanizeString';
import { lowerFirst, toString } from 'lodash';
import { translate } from 'Services/Translator';
import { isBool, isString, orFunction, OrFunction, } from 'Utils/types';
import { ValidationError, } from './specs';
import { renderContent } from '../../renderContent';
export * from "../general";
export var TypeSpecs;
(function (TypeSpecs) {
    function getValueType(type) {
        if (type['specs'] instanceof BaseValueType) {
            return type['specs'];
        }
        if (type instanceof BaseValueType)
            return type;
        return null;
    }
    TypeSpecs.getValueType = getValueType;
})(TypeSpecs || (TypeSpecs = {}));
export class BaseValueType {
    _type;
    _props = Object.create(null);
    get options() {
        return this._specs.options;
    }
    get schema() {
        return this._specs.schema;
    }
    constructor(props) {
        Object.assign(this._props, props);
    }
    render(props) {
        const { field: valueType, options, as: Component = valueType.isVisible ? this.Renderer : null, ...rest } = props;
        options && valueType.specs.update(options);
        return !Component ? null : renderContent(Component, {
            key: `${valueType.id}--valueType-template`,
            ...rest,
            field: valueType,
        });
    }
    _render(valueType, props) {
        return this._type['_renderForm'](valueType, {
            key: `${valueType.id}--form`,
            ...props,
            field: valueType,
        });
    }
    Renderer = ({ field: valueType, render, props }) => {
        valueType.use({});
        if (render || (render = valueType.options.render)) {
            return render(valueType);
        }
        return this._render(valueType, props);
    };
    async validate(value, options) {
        try {
            await this._specs.schema.validate(value, { abortEarly: true, ...options });
            ValidationError.throwError(this._props.validate?.(value, ValidationError));
            ValidationError.throwError(await this._props.validateAsync?.(value, ValidationError));
        }
        catch (e) {
            if (!ValidationError.isError(e))
                throw e;
            throw e;
        }
    }
    validateSync(value, options) {
        try {
            this._specs.schema.validateSync(value, { abortEarly: true, ...options });
            ValidationError.throwError(this._props.validate?.(value, ValidationError));
        }
        catch (e) {
            if (!ValidationError.isError(e))
                throw e;
            throw e;
        }
    }
    init(type, initialOptions) {
        this._type = type;
        this._specs.init({ ...initialOptions });
        return this;
    }
    $$bindParent(parent, key) {
        Object.assign(this._props, (parent && (key = toString(key))) ? { key, parent } : { key: null, parent: null });
        return this;
    }
    getOptionsModifiers(props) {
        const { self: _this, own: _own, mutate: _mutate, } = props;
        return {
            key: {
                readonly: true,
                get: () => (this._props.key ?? ''),
            },
            parent: {
                readonly: true,
                get: () => this._props.parent,
            },
            name: {
                getDefault: () => lowerFirst(this.constructor.name?.replace?.(/(Form|Type)$/, '')),
                set: value => value && isString(value) ? value : undefined,
            },
            hideLabel: {
                get: (value) => value ?? (this.isRoot || (!!value || !isString(_this.label))),
            },
            label: {
                set: (value) => {
                    if (isBool(value))
                        _this.hideLabel = !value;
                    else
                        return value;
                },
                get: (value) => OrFunction(value) ?? humanizeString(_this.key),
                update: () => _mutate(s => s.label(_this.errorLabel)),
            },
            errorLabel: {
                get: (value) => (value ?? _this.label ?? 'This'),
                update: () => _mutate(s => s.label(_this.errorLabel)),
            },
            nullable: {
                get: () => !!_own.nullable,
                update: (value) => _mutate(s => s.nullable(!!value)),
            },
            required: {
                get: () => !!_own.required,
                update: (value) => _mutate(s => value
                    ? s.required(isString(value) ? value : translate('form.validation.fieldIsRequired'))
                    : s.notRequired()),
            },
            defaultValue: {
                get: () => _own.defaultValue,
                update: (value) => _mutate(s => s.default(value)),
            },
        };
    }
    update(options) {
        this._specs.update(options);
    }
    set name(value) {
        this.options.name = value;
    }
    get key() {
        return (this._props.key ?? '');
    }
    get parent() {
        return this._props.parent;
    }
    get isRoot() {
        return (!this.parent);
    }
    get isRequired() {
        return !!this.options.required;
    }
    get isNullable() {
        return this.options.nullable ?? (!this.isRequired && (orFunction(this.options.defaultValue) == null));
    }
    _dump() {
        return this._specs._dump();
    }
    test(v) {
        if (this.isNullable && v === null)
            return true;
        return this._typeCheck(v);
    }
    parse(value) {
        if (this._props.parse)
            value = this._props.parse(value);
        return this.test(value) ? value : this._parse(value);
    }
    cast(value, options) {
        if (typeof value === 'undefined' && !options?.ignoreDefaults)
            value = this.getDefault();
        value = this.parse(value);
        if (!this.test(value) && !options?.ignoreDefaults)
            value = this.getDefault();
        if (this._props.cast)
            value = this._props.cast(value);
        return value;
    }
    getDefault() {
        return this.parse(orFunction(this.options.defaultValue));
    }
}
BaseValueType.prototype.__is_fw_value_type = true;
