import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { navSlot } from './Nav';
export const NavItemRoot = styled.div ``;
export const NavItem = forwardRef(({ className, children, as: Component = NavItemRoot, ...rest }, ref) => {
    return (_jsx(Component, { ...navSlot.attr('item'), ...rest, ref: ref, className: classNames(className, 'fw-nav-item'), children: children }));
});
NavItem.displayName = 'NavItem';
