import { BaseValue } from './BaseValue';
export class CompoundValue extends BaseValue {
    toString() {
        return JSON.stringify(this.value);
    }
    has(key) {
        return this._state.has(key);
    }
    get(key) {
        return this._state.get(key);
    }
    forEach(cb) {
        return this._state.forEach(cb);
    }
    map(cb) {
        return this._state.map(cb);
    }
    renderChildren(names, props) {
        return names
            ? names.map(key => this.get(key)?.render(props))
            : this.map(child => child.render({ ...props }));
    }
    renderChild(name, props) {
        return this.get(name)?.render(props);
    }
    get isEmpty() {
        return this._state.getChildrenSize() === 0;
    }
    get hasChildren() {
        return this._state.getChildrenSize() > 0;
    }
    get touched() {
        return super.touched;
    }
    get error() {
        return (this.meta.valid ? false : super.error);
    }
    clear(skipRender) {
        this._state.clearIndexes();
        this.forEach(child => child.clear(true));
        this._handleUpdate(skipRender);
    }
    async validate(force, skipRender) {
        const promises = [];
        let errors = false;
        if (this.isEmpty) {
            this._state.updateParent();
        }
        else {
            this.forEach(child => {
                if (child['_shouldValidate']())
                    promises.push(child.validate(force, true));
            });
            await Promise.allSettled(promises);
        }
        this._handleUpdate(skipRender);
        return errors;
    }
}
