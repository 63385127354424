import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FinancialAccountGroupTable from "Pages/Accounting/AccountingRecord/FinancialAccountGroupTable";
import { translate } from "Services/Translator";
import { formatMoney } from "Services/NumberHelper";
const FinancialAccountGroups = ({ openFinancialAccountStatement, title, accountGroups, fiscalYear, dateFilter, filterEmpty = false }) => {
    return (_jsxs(_Fragment, { children: [_jsx("h2", { style: { color: "#434954" }, children: title }), accountGroups.map((accountGroup, key) => {
                if (filterEmpty && accountGroup.emptyGroup) {
                    return null;
                }
                return (_jsxs("div", { style: { marginBottom: 40 }, children: [_jsx(FinancialAccountGroupTable, { openFinancialAccountStatement: openFinancialAccountStatement, accountGroup: accountGroup, fiscalYear: fiscalYear, dateFilter: dateFilter, filterEmpty: filterEmpty }, accountGroup.id), _jsx(AccountGroupTotals, { accountGroup: accountGroup })] }, key));
            })] }));
};
const AccountGroupTotals = ({ accountGroup }) => (_jsxs("div", { className: "text-right", style: { color: '#434954', fontWeight: 500, marginTop: 10, marginBottom: 30, paddingRight: 15, fontSize: 16 }, children: [translate('total'), " ", accountGroup.label, " \u00A0\u00A0 ", formatMoney(accountGroup.closingBalance)] }));
export default FinancialAccountGroups;
