import { translate } from "Services/App";
export const getFloorLabelFromNumber = (floor) => {
    if (floor === 0) {
        return translate('baseData.rentalObject.floor.ground');
    }
    if (floor < 0) {
        return translate('baseData.rentalObject.floor.basement', { floor: Math.abs(floor) });
    }
    else {
        return translate('baseData.rentalObject.floor.upper', { floor: floor });
    }
};
