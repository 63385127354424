import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import generateRoute from 'Services/Router';
import { translate } from 'Services/Translator';
import { DeleteIcon, EditIcon } from 'Components/App/Icons';
import { formatMoney } from 'Services/NumberHelper';
import { AmountRangeFilter } from 'Pages/Accounting/AccountingRecord/components/AmountRangeFilter';
import { FinancialAccountFilter } from 'Pages/Accounting/AccountingRecord/components/FinancialAccountFilter';
import { useAccountingPageContext } from '../../AccountingPageContext';
import { Td, Tr } from 'Atoms/Table';
import { Text } from 'Atoms/Text';
import { DateType, MoneyType } from '@f2w/form';
import { FlexRow } from 'Atoms/Layout';
const model = Table.factory()(() => ({
    tableId: 'AccountingRecordSheet',
    RowActions: ({ row: { original: { id, invoiceData, availableActions } }, refresh, onDeleteClicked, onEditClicked, onEditOpeningBalance }) => (_jsxs(ActionsDropdown, { id: id, quickActions: [
            {
                icon: 'invoice',
                href: invoiceData?.link,
                target: '_blank',
                hidden: invoiceData === null,
            },
            availableActions.includes('edit') && {
                icon: 'edit',
                title: translate('btn.edit'),
                onClick: () => onEditClicked(id)
            }
        ], children: [availableActions.includes('delete') && _jsxs(ActionsDropdown.Link, { onClick: () => onDeleteClicked(id, refresh), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }), availableActions.includes('editOpeningBalance') && _jsxs(ActionsDropdown.Link, { onClick: () => {
                    onEditOpeningBalance();
                }, children: [" ", _jsx(EditIcon, {}), " ", _jsx("span", { children: translate('accountingRecord.editOpeningBalance') })] })] })),
    BulkActions: ({ accountBalance }) => (_jsx(_Fragment, { children: accountBalance !== null && _jsxs(Text, { children: ["Saldo per ", DateType.format(accountBalance.date), ": ", _jsx("strong", { children: MoneyType.format(accountBalance.amount) })] }) })),
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $actions: {
            sticky: 'left',
        },
        financialAccount: {
            Header: translate('accountingRecord.financialAccount'),
            hide: true,
            useFilters: {
                Filter: FinancialAccountFilter,
            }
        },
        date: {
            Header: translate('accountingRecord.date'),
            useSortBy: {},
            Cell: ({ value }) => DateType.useFormat(value),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => {
                    const { fiscalYear } = useAccountingPageContext();
                    return _jsx(Table.filters.DateRangeFilter, { filterValue: filterValue, onChange: setFilter, min: fiscalYear?.starts, max: fiscalYear?.ends });
                },
            }
        },
        customizableId: {
            Header: translate('accountingRecord.customizableId'),
            Cell: ({ value, row: { original: { invoiceData } } }) => invoiceData?.reference
                ? _jsx("a", { href: invoiceData.link, target: '_blank', children: invoiceData.reference })
                : value
        },
        invoiceRecipient: {
            Header: translate('accountingRecord.invoiceRecipient'),
            accessor: originalRow => originalRow.invoiceData,
            Cell: ({ value }) => (_jsx("div", { children: value && value.personId
                    ? _jsx("a", { href: generateRoute('person.list', { personId: value.personId }), target: '_blank', children: value.personName })
                    : translate('notAvailable') }))
        },
        label: {
            Header: translate('accountingRecord.label'),
        },
        debitAccount: {
            Header: translate('accountingRecord.debitAccount'),
            Cell: ({ value }) => (_jsx("div", { children: value
                    ? `${value.position} - ${value.label}`
                    : translate('notAvailable') }))
        },
        creditAccount: {
            Header: translate('accountingRecord.creditAccount'),
            Cell: ({ value }) => _jsx("div", { children: value
                    ? `${value.position} - ${value.label}`
                    : translate('notAvailable') })
        },
        amount: {
            Header: translate('accountingRecord.amount'),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatMoney(value) }),
            useFilters: {
                Filter: AmountRangeFilter,
            }
        },
        cumulativeBalance: {
            Header: translate('accountingRecord.cumulativeBalance'),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: _jsx(Text, { nowrap: true, children: MoneyType.useFormat(value) }) }),
        }
    },
    Footer: ({ data }) => {
        const amount = data.reduce((total, row) => total + row.amount, 0);
        return (_jsxs(Tr, { children: [_jsx(Td, { colSpan: 7, align: "right", children: _jsxs("strong", { children: [translate('accounting.sheet.table.totalAmount'), ":"] }) }), _jsx(Td, { align: "right", noWrap: true, children: _jsx("strong", { children: MoneyType.format(amount) }) }), _jsx(Td, {})] }));
    },
}));
export default model;
