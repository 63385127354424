import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import { Button } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { CopyIcon, DeleteIcon, ResetIcon } from "Components/App/Icons";
import { Div, FlexRow } from "Atoms/Layout";
import moment from "moment/moment";
import { hasFeature } from "Services/FeatureFlagManager";
import { Dropdown } from "Atoms/Dropdown";
import { TypeFilter } from "Pages/DocumentTemplates/List/components/TypeFilter";
import { AreaFilter } from "Pages/DocumentTemplates/List/components/AreaFilter";
import { PortfolioPropertyFilter } from "Pages/DocumentTemplates/List/components/PortfolioPropertyFilter";
import { HasChangesFilter } from "Pages/DocumentTemplates/List/components/HasChangesFilter";
const types = ['document', 'letter'];
const BaseTable = Table.factory()(() => ({
    tableId: 'DocumentTemplates',
    Actions: ({ openSettingsForm, createNewDocument }) => (_jsxs(FlexRow, { gap: 12, children: [hasFeature('TEMPLATES_ADD') && _jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { children: translate('btn.addDocument') }), _jsx(Dropdown.Menu, { children: types.map((type, i) => (_jsx(Dropdown.Item, { onClick: () => createNewDocument(type), children: translate('document.v2.type.' + type, {}, 'documents') }, `document-link-${i}`))) })] }), _jsxs(Button, { variant: "outline", color: "dark", onClick: openSettingsForm, title: translate('documentTemplates.settings'), children: [_jsx(Icon, { icon: 'settings' }), " ", translate('documentTemplates.settings')] })] })),
    manualControl: true,
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    useRowState: {},
    initialState: {
        sortBy: [{ id: 'label', desc: false }],
        pageSize: 25,
    },
    FiltersLayout: ({ renderFilter }) => (_jsxs(_Fragment, { children: [renderFilter('type'), renderFilter('areas'), renderFilter('properties'), renderFilter('lastUpdateAt', undefined, { label: translate('documentTemplate.list.hasChanges') })] })),
    columns: {
        $actions: {
            sticky: 'left',
        },
        label: {
            Header: translate('documentTemplate.list.document'),
            useSortBy: {},
        },
        type: {
            Header: translate('documentTemplate.list.type'),
            Cell: ({ value: type }) => translate('document.v2.type.' + type, {}, 'documents'),
            useSortBy: {},
            useFilters: {
                Filter: TypeFilter
            }
        },
        areas: {
            Header: translate('documentTemplate.list.areas'),
            Cell: ({ value: areas }) => areas.map((area) => translate('document_area.title.' + area)).join(', '),
            useFilters: {
                Filter: AreaFilter
            }
        },
        properties: {
            Header: translate('documentTemplate.list.properties'),
            Cell: ({ value: properties }) => properties.map(({ name }) => name).join(', '),
            useSortBy: {},
            useFilters: {
                Filter: PortfolioPropertyFilter
            }
        },
        lastUpdateAt: {
            Header: translate('documentTemplate.list.lastUpdate'),
            Cell: ({ value }) => {
                return _jsx(Div, { style: { whiteSpace: 'nowrap' }, children: value ? moment(value).format('DD.MM.YYYY HH:mm') : '—' });
            },
            useSortBy: {},
            useFilters: {
                Filter: HasChangesFilter
            }
        },
    },
    RowActions: ({ row: { original: { id, label, instanceKey, isDeletable, isRevertable } }, openDocumentView, openCloneModal, onDelete, onResetToDefault }) => {
        return (_jsx(ActionsDropdown, { id: id, scrollable: true, quickActions: [
                {
                    icon: 'edit',
                    title: translate('documentTemplate.edit.action'),
                    onClick: () => openDocumentView(instanceKey, id)
                },
            ], dropdownActions: ({ Link }) => (_jsxs(_Fragment, { children: [_jsxs(Link, { onClick: () => openCloneModal(instanceKey, id, label + ' ' + translate('documentTemplate.copySuffix')), variant: 'outline', title: translate('btn.copy'), children: [_jsx(CopyIcon, {}), " ", translate('btn.copy')] }), isDeletable && _jsxs(Link, { onClick: () => onDelete(id), variant: 'outline', title: translate('btn.delete'), children: [_jsx(DeleteIcon, { size: 16 }), " ", translate('btn.delete')] }), isRevertable && _jsxs(Link, { onClick: () => onResetToDefault(id), variant: 'outline', title: translate('documentTemplate.resetToDefault.action'), children: [_jsx(ResetIcon, { size: 16 }), " ", translate('documentTemplate.resetToDefault.action')] })] })) }));
    },
}));
export const DocumentTemplateDataTable = Object.assign(BaseTable, {
    use({ documentTemplateServices, openSettingsForm, createNewDocument, openDocumentView, openCloneModal, onDelete, onResetToDefault }) {
        return DocumentTemplateDataTable.useTable({ openSettingsForm, createNewDocument, openDocumentView, openCloneModal, onDelete, onResetToDefault }, () => ({
            getData: ({ request }) => documentTemplateServices.listDocumentTemplates({ ...request }),
        }));
    }
});
