import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useEventCallback } from '@restart/hooks';
import { toPx } from '@f2w/theme';
import { getLocale } from "Services/Translator";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { EditorVariants } from "Atoms/Input/controls/TextEditorConfig";
import './TextEditor.styles.scss';
import { useTextAreaTheme } from './TextArea';
export function TextEditor({ editorType = 'full', initialValue, placeholder, placeholderOptions, hasError, onChange: _onChange, onBlur: _onBlur, loading, $size, $variant, ...props }) {
    const locale = getLocale();
    const typeProps = React.useMemo(() => {
        const variantProps = (EditorVariants?.[editorType?.toLowerCase()] ?? EditorVariants.full);
        return {
            ...variantProps,
            config: {
                ...variantProps.config,
                placeholderConfig: {
                    types: placeholderOptions
                },
            }
        };
    }, [editorType, placeholderOptions, locale]);
    const onChange = useEventCallback((event, editor) => _onChange(editor.getData()));
    const onBlur = useEventCallback((event, editor) => _onBlur(editor.getData()));
    const onFocus = useEventCallback((event, editor) => {
        const element = document.querySelector(".ck-powered-by-balloon");
        if (element !== null)
            element.remove();
    });
    const vars = useTextAreaTheme({ $size, $variant });
    return (_jsx("div", { style: {
            '--fw-text-editor__border-size': toPx(1),
            '--fw-text-editor__border-radius': vars['--fw-input__border-radius'],
            '--fw-text-editor__border-color': vars['--fw-input__border-color'],
            '--fw-text-editor__active-color': vars['--fw-input__active-color'],
        }, className: `ck-editor-${editorType?.toLowerCase()}`, children: _jsx(CKEditor, { ...props, ...typeProps, data: initialValue, onChange: onChange, onBlur: onBlur, onFocus: onFocus }) }));
}
