import { useAssetView } from "Pages/Asset/components/DetailView/hooks/useAssetView";
import React from "react";
import { useObjectViewModal } from "Components/ObjectViewModal";
export const AssetDetailView = ({ assetId }) => {
    const { activeView } = useObjectViewModal();
    const assetView = useAssetView();
    React.useEffect(() => {
        assetView.load(assetId);
        if (activeView.asEdit) {
            assetView.openForm('general');
        }
    }, []);
    return assetView.render();
};
