import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import AccountingRecordServices from 'Pages/Accounting/AccountingRecord/services/accountingRecordServices';
import { translate } from 'Services/Translator';
import FinancialAccountGroups from 'Pages/Accounting/AccountingRecord/FinancialAccountGroups';
import EmptyRecordsFilter from 'Pages/Accounting/AccountingRecord/EmptyRecordsFilter';
import BaseBalanceSection from 'Pages/Accounting/AccountingRecord/components/BalanceSection';
import { Div, FlexBox, FlexCol, FlexRow } from 'Components/Atoms';
import OpeningBalanceProvider from 'Pages/Accounting/OpeningBalance/OpeningBalanceContext';
import { useOpeningBalanceForm } from 'Pages/Accounting/OpeningBalance/OpeningBalanceForm';
import { DatePicker } from 'Atoms/DatePicker';
import moment from 'moment';
import { useAccountingPageContext } from '../AccountingPageContext';
import { FormModal } from '@f2w/form';
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from "Services/EventManager";
import { FinancialAccountStatement } from "Pages/Accounting/components/FinancialAccountStatement";
const BalanceStatementPage = ({ propertyId, fiscalYearId, isReportingFiscalYear, reportingDate, accountingRecordServices }) => {
    const { fiscalYear } = useAccountingPageContext();
    const [dateFilter, setDateFilter] = useState(reportingDate);
    const [balanceStatement, setBalanceStatement] = useState();
    const [filterEmpty, setFilterEmpty] = useState(true);
    const [statementData, setStatementData] = useState();
    const loadData = async () => {
        const BalanceStatement = await accountingRecordServices.getBalanceStatement(propertyId, fiscalYearId, dateFilter);
        setBalanceStatement(BalanceStatement);
    };
    const incomeForm = useOpeningBalanceForm({
        accountingRecordServices: AccountingRecordServices,
        onSaved: loadData
    });
    const updateOnOpeningBalanceUpdated = (e) => {
        loadData();
    };
    useEffect(() => {
        subscribeToCustomEvent('fw.accounting.openingBalanceUpdated', updateOnOpeningBalanceUpdated);
        return () => {
            unsubscribeFromCustomEvent('fw.accounting.openingBalanceUpdated', updateOnOpeningBalanceUpdated);
        };
    }, []);
    useEffect(() => {
        loadData();
    }, [dateFilter]);
    if (!balanceStatement) {
        return null;
    }
    const hasNotEmptyAssets = balanceStatement.assets.filter(accountGroup => !accountGroup.emptyGroup).length > 0;
    const hasNotEmptyLiabilities = balanceStatement.liabilities.filter(accountGroup => !accountGroup.emptyGroup).length > 0;
    return (_jsxs(OpeningBalanceProvider, { disableOpeningBalanceModification: isReportingFiscalYear, accountingRecordServices: AccountingRecordServices, openForm: incomeForm.openForm, children: [_jsxs(FlexBox, { gap: 50, children: [_jsxs(FlexCol, { flex: 1, children: [_jsx(Div, { mb: 25, style: { position: "relative", zIndex: 30 }, children: _jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx("span", { children: translate('accounting.reportingDate') }), _jsx(DatePicker, { value: dateFilter, style: { flexGrow: 1 }, onChange: (date) => {
                                                const momentDate = moment(date);
                                                setDateFilter(momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : undefined);
                                            }, minDate: fiscalYear?.starts, maxDate: fiscalYear?.ends })] }) }), _jsx(EmptyRecordsFilter, { name: "balance_select_account", onChange: setFilterEmpty })] }), _jsx(FlexCol, { flex: 1, children: _jsx(BalanceSection, { balance: balanceStatement.balance }) })] }), _jsxs("div", { children: [(filterEmpty && !hasNotEmptyAssets && !hasNotEmptyLiabilities)
                        && _jsx("div", { style: { marginTop: 50, textAlign: 'center' }, children: translate('accounting.noDataAvailable') }), (hasNotEmptyAssets || !filterEmpty) && (_jsx(FinancialAccountGroups, { openFinancialAccountStatement: (financialAccountId) => setStatementData({
                            financialAccountId,
                            fiscalYearId
                        }), title: translate('accounting.group.aktiva'), accountGroups: balanceStatement.assets, fiscalYear: balanceStatement.fiscalYear, dateFilter: null, filterEmpty: filterEmpty })), (hasNotEmptyLiabilities || !filterEmpty) && (_jsx(FinancialAccountGroups, { openFinancialAccountStatement: (financialAccountId) => setStatementData({
                            financialAccountId,
                            fiscalYearId
                        }), title: translate('accounting.group.passiva'), accountGroups: balanceStatement.liabilities, fiscalYear: balanceStatement.fiscalYear, dateFilter: null, filterEmpty: filterEmpty }))] }), _jsx(FormModal, { modal: incomeForm, size: { maxWidth: '455px' } }), statementData && (_jsx(FinancialAccountStatement, { statementData: statementData, onClose: () => setStatementData(undefined), initialDateRange: {
                    to: dateFilter
                }, initialConfig: {
                    type: 'date',
                    min: fiscalYear?.starts,
                    max: fiscalYear?.ends
                } }))] }));
};
const BalanceSection = ({ balance }) => (_jsx(BaseBalanceSection, { titledAmounts: [
        { title: translate('accounting.totalAssets'), amount: balance.assets },
        { title: translate('accounting.totalLiabilities'), amount: balance.liabilities },
        { title: translate('accounting.difference') + ' +/-', amount: balance.difference, important: true },
    ] }));
export default BalanceStatementPage;
