import { useFormModal } from '@f2w/form';
import { translate } from 'Services/Translator';
import personServices from 'Pages/Person/Details/services/personServices';
import { PersonType } from 'Pages/Person/types';
export const usePersonForm = ({ onSaved, createPerson, updatePerson }) => {
    return useFormModal({
        name: 'person-form',
        type: () => new PersonType.General(),
        title: ({ values }) => values.id ? translate('person.edit') : translate('person.new'),
        onLoad: async (id) => {
            const data = await personServices.getPersonById(id);
            if (data?.corporatePerson === null) {
                data.corporatePerson = undefined;
            }
            return data;
        },
        onSave: (values) => {
            const formattedValues = {
                ...values,
                naturalPerson: { ...values?.naturalPerson },
                corporatePerson: { ...values?.corporatePerson },
                personType: parseInt(values.personType),
            };
            if (formattedValues.personType === 1) {
                delete formattedValues.corporatePerson;
            }
            else {
                delete formattedValues.naturalPerson;
            }
            if (values.id) {
                return updatePerson(values.id, formattedValues)
                    .then(() => {
                    onSaved();
                });
            }
            else {
                return createPerson(formattedValues)
                    .then(() => {
                    onSaved();
                });
            }
        },
    });
};
