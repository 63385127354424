import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { useCallback, useMemo } from 'react';
import { Table, Tbody, Td, TdActions, TdRoot, Th, Thead, Tr, TrRoot, } from 'Atoms/Table';
import { translate } from 'Services/Translator';
import { orFunction } from '@f2w/utils';
import { cssVars } from '@f2w/theme';
export function CollectionTable({ field: arrayValue, columns: columnsDef, ...props }) {
    const hasActions = arrayValue.specs.hasActions || null;
    const _columns = useMemo(() => {
        return columnsDef ?? arrayValue.protoType.shape.map((child, key) => {
            return ({
                id: String(key),
                label: child?.options?.label ?? key,
            });
        });
    }, [columnsDef]);
    const getRowActionProps = useTableActions(arrayValue);
    const renderRow = useCallback((row) => {
        return (_jsx(_Fragment, { children: row.map(child => (_jsx(Td, { children: _jsx("div", { style: { display: 'inline-flex' }, children: child.render({}) }) }, `${child.id}--table-col`))) }));
    }, [_columns, hasActions]);
    arrayValue.setTheme({ size: 'sm' });
    return _jsx(_Fragment, { children: _jsxs(CollectionTable.TableRoot, { borderRadius: false, variant: 'default', hover: false, size: 'sm', className: "fw-collection--body", colSpan: hasActions ? _columns.length + 1 : _columns.length, ...props, children: [_jsxs(Thead, { children: [_columns.map((col) => (_jsx(Th, { children: orFunction(col.label) }, `${col.id}--column`))), hasActions && (_jsx(Th, { sticky: 'right', children: "##" }, `${arrayValue.id}-table-actions`))] }), _jsx(Tbody, { children: arrayValue.map((row) => {
                        const status = row.isValidated ? (row.isValid ? 'success' : 'error') : undefined;
                        return _jsxs(Tr, { status: status, style: cssVars({ 'cell-valign': 'top' }), children: [renderRow(row), hasActions && (_jsx(TdActions, { id: `${row.id}--table-actions`, sticky: 'right', ...getRowActionProps(row) }))] }, `${row.id}--table-row`);
                    }) })] }) });
}
const useTableActions = (arrayValue) => {
    return useCallback((row) => {
        return {
            quickActions: [
                arrayValue.specs.showDelete && {
                    color: 'error',
                    icon: 'trash-2',
                    onClick: () => arrayValue.remove(row.key),
                    confirm: translate('table.collection.deleteConfirm'),
                },
            ]
        };
    }, [arrayValue.specs.showDelete]);
};
(function (CollectionTable) {
    CollectionTable.TableRoot = styled(Table)(({}) => css `
        margin-bottom: 40px;
        margin-top: -10px;

        ${TrRoot} {
            --row-padding-x: 4px;
            --form-row-spacing: 0;

            > ${TdRoot} > ${CollectionTable.TableRoot} {
                margin-bottom: 0;
            }
        }
    `);
})(CollectionTable || (CollectionTable = {}));
