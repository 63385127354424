import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { UseStyled, useTheme } from '@f2w/theme';
import { Icon } from 'Atoms/Icon';
import { Dropdown } from 'Atoms/Dropdown';
import { OrFunction } from '@f2w/utils';
import defaultTheme from './defaultTheme';
import { selectComponents } from '../hooks/selectTheme';
import { UseSelect } from '../hooks/useSelect';
import { mergeStyles } from 'react-select';
const Toggle = styled(Dropdown.Toggle) `
    && {
        padding: 0;
        font-size: 18px;
    }
`;
const DropdownMenu = UseStyled.styled('div', ({ theme: { palette } }) => ({
    '&&&': {
        zIndex: 699,
        padding: 0,
    }
}));
export function SearchSelect({ select, ...rest }) {
    const s = OrFunction(select);
    const { options, selectedOptions, setValue, props } = s;
    const selectProps = {
        ...rest,
        ...props,
    };
    return _jsx(SelectDropdown, { ...selectProps, options: options, value: selectedOptions, onChange: (value, meta) => {
            setValue(value);
        } });
}
const SelectDropdown = ({ label, fixed = false, ...props }) => {
    return (_jsxs(Dropdown, { container: true, variant: 'custom', focusFirstItemOnShow: false, children: [_jsx(Toggle, { caret: false, "$size": 'md', variant: 'link', icon: 'plus-circle', label: label }), _jsx(Dropdown.Menu, { variant: 'custom', as: DropdownMenu, fixed: fixed, children: _jsx(UseSelect, { isSearchable: true, isClearable: false, ...defaultProps, ...props }) })] }));
};
const { styles: { ...styles }, components } = defaultTheme;
const defaultProps = {
    menuIsOpen: true,
    autoFocus: true,
    tabIndex: 1,
    closeMenuOnSelect: false,
    closeMenuOnScroll: false,
    openMenuOnClick: false,
    openMenuOnFocus: false,
    hideSelectedOptions: false,
    controlShouldRenderValue: false,
    components: {
        ...selectComponents,
        ...components,
        DropdownIndicator: (props) => {
            return _jsx(Icon, { icon: 'search' });
        },
        Option: ({ children, ...props }) => {
            return (_jsxs(selectComponents.Option, { ...props, children: [_jsx("span", { children: children }), props.isSelected && _jsx(Icon, { size: 20, icon: 'x' })] }));
        }
    },
    styles: mergeStyles(styles, {
        container: (base) => {
            const { palette } = useTheme();
            return {
                ...base,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                padding: '16px',
                gap: 8,
                width: '300px',
                background: palette.white,
                border: `2px solid ${palette.primary.$50}`,
                boxShadow: 'none',
                overflow: 'hidden',
                borderRadius: 8,
            };
        },
        menu: (base, state) => ({
            margin: '0',
            padding: '0',
            overflow: 'hidden',
        }),
        option: (base, state) => ({
            ...base,
            justifyContent: 'space-between',
        }),
        menuList: (base, state) => ({
            ...base,
            marginRight: -20,
            paddingRight: 8,
            overflowY: 'scroll',
        }),
        input: (base, state) => ({
            ...base,
            marginLeft: 0,
        }),
        indicatorsContainer: (base, state) => {
            const { paddingX } = state.theme.vars;
            return {
                ...base,
                order: 1,
                paddingLeft: paddingX,
            };
        },
        valueContainer: base => ({
            ...base,
            order: 2,
            padding: 0,
        }),
    }),
};
