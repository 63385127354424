import { modalTemplate } from './template';
const template = modalTemplate;
export function createWizardType(config, layout) {
    const { id, path, title, ...rest } = config;
    const type = {
        _: config,
        ...rest,
        id,
        path: path ?? (path === undefined ? id : undefined),
        title: (title ?? (() => id)),
        layout: {
            ...template,
            ...layout,
        },
    };
    return (props) => {
        Object.assign(type.layout, props?.layout);
        if (props.initialValues) {
            const load = type.load;
            type.load = async (...args) => {
                const d = load ? await load(...args) : undefined;
                return {
                    ...d,
                    stepData: {
                        ...d?.['stepData'],
                        ...props?.initialValues
                    }
                };
            };
        }
        return type;
    };
}
