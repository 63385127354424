import { pick } from '@f2w/utils';
import { BaseState } from './BaseState';
export class SimpleState extends BaseState {
    _config = SimpleState.createManager(this._configureDescriptors());
    constructor(config) {
        super(config);
        Object.defineProperties(this, {
            meta: {
                value: this._config.meta,
                writable: false,
                configurable: false
            },
            data: {
                value: this._config.data,
                writable: false,
                configurable: false
            },
        });
    }
    get value() {
        return this.hasValue() ? this.data.value : this.data.initialValue;
    }
    get initialValue() {
        return this.data.initialValue;
    }
    hasInitialValue() {
        return this._hasOwn('initialValue');
    }
    hasValue() {
        return this._hasOwn('value');
    }
    clear(skipUpdate, ignoreValue) {
        this._own = pick(this._own, ['initialValue', ignoreValue ? ['value', 'humanValue', 'formattedValue', 'prevValue'] : []]);
        if (!skipUpdate) {
            this.updateParent() && this._dispatchUpdate();
        }
    }
    reset(options) {
        let initialValue = this._own.initialValue;
        this.clear(true);
        if (options?.newValue !== undefined) {
            initialValue = this._cast(options?.newValue, this.hasInitialValue());
        }
        else if (!this.hasInitialValue()) {
            initialValue = this._type().getDefault();
        }
        const updated = this.set('initialValue', initialValue);
        if (!options?.skipRender && (this.updateParent() || updated)) {
            this._dispatchUpdate();
        }
    }
    _updateData(key, value, force) {
        switch (key) {
            case 'initialValue':
                if (super._updateData(key, value)) {
                    return this.meta.touched ? ['dirty'] : [];
                }
                break;
            case 'value':
                if (super._updateData(key, value, !this.hasValue())) {
                    return ['dirty', 'touched'];
                }
                break;
            case 'error':
                if (super._updateData(key, value)) {
                    return ['validated', 'valid', 'hasError'];
                }
                break;
            default:
                if (super._updateData(key, value)) {
                    return [key];
                }
                break;
        }
    }
    _configureDescriptors() {
        const config = {
            touched: {
                set: (value) => this._own.touched = value,
                getValue: () => (this._own.touched ?? false),
                getMetaValue: () => (this._own.touched ?? false),
            },
            validated: {
                set: (value) => this._own.validated = value,
                getValue: () => (this._own.validated ?? (this._own.error != null)),
                getMetaValue: () => (this._own.validated ?? (this._own.error != null)),
            },
            validating: {
                set: (value) => this._own.validating = value,
                getValue: () => (this._own.validating ?? false),
                getMetaValue: () => (this._own.validating ?? false),
            },
            disabled: {
                set: (value) => this._own.disabled = value,
                getValue: () => this._own.disabled ?? false,
                getMetaValue: () => {
                    return this._own.disabled || this._parent?.meta.disabled || false;
                },
            },
            active: {
                set: (value) => this._own.active = value,
                getValue: () => this._own.active ?? false,
                getMetaValue: () => {
                    return this._own.active || this._parent?.meta.active || false;
                },
            },
            visible: {
                set: (value) => this._own.visible = value,
                getValue: () => this._own.visible ?? false,
                getMetaValue: () => {
                    return this._own.visible || this._parent?.meta.visible || false;
                },
            },
            dirty: {
                getValue: () => {
                    return this.hasValue() && this._own.value !== this._own.initialValue;
                },
                getMetaValue: () => {
                    return this.hasValue() && this._own.value !== this._own.initialValue;
                },
            },
            valid: {
                getValue: () => (this._own.error === ''),
                getMetaValue: () => (this._own.error === ''),
            },
            hasError: {
                getValue: () => (this._own.error !== ''),
                getMetaValue: () => (this._own.error !== ''),
            },
            initialValue: {
                getValue: () => this._own.initialValue,
                set: (value) => {
                    this._own.initialValue = value;
                    _clearFormatted();
                },
            },
            value: {
                getValue: () => this._own.value,
                set: (value) => {
                    this._own.prevValue = this._own.value;
                    this._own.value = value;
                    _clearFormatted();
                },
            },
            error: {
                getValue: () => this._own.error,
                set: (value) => this._own.error = value,
            },
            prevValue: {
                getValue: () => this._own.prevValue,
            },
            formattedValue: {
                getValue: () => {
                    if (!this._hasOwn('formattedValue'))
                        this._own.formattedValue = this._type().format(this.value, 'upsert');
                    return this._own.formattedValue;
                },
            },
            humanValue: {
                getValue: () => {
                    if (!this._hasOwn('humanValue'))
                        this._own.humanValue = this._type().format(this.value, 'view');
                    return this._own.humanValue;
                },
            },
        };
        const _clearFormatted = () => {
            const { formattedValue, humanValue, ...own } = this._own;
            this._own = own;
        };
        return config;
    }
}
