export class UpdateQueue {
    _items = [];
    _props;
    constructor(cb) {
        this._props = {
            callback: cb,
        };
    }
    static create(cb) {
        return new UpdateQueue(cb);
    }
    get length() {
        return this._items.length;
    }
    all() {
        return this._items.slice(0);
    }
    keys() {
        return this._items.map(v => v.propName);
    }
    push(...args) {
        this._items.push(args.shift());
        if (args.length)
            return this.push(...args);
        return this;
    }
    flush() {
        if (this._flush())
            this.flush();
        return this;
    }
    _flush() {
        if (this._items.length && false !== this._props.callback({ ...this._items[0] })) {
            this._items.shift();
            return true;
        }
    }
}
