import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Select } from 'Atoms/Select';
import { convertOptionsToValues } from 'Utils/select';
import { getFloorLabelFromNumber } from "Components/Molecules/Floors/services";
export const FloorsSelect = ({ value, onChange, ...rest }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const result = [];
        for (let floor = -10; floor <= 10; floor++) {
            result.push({ value: floor, label: getFloorLabelFromNumber(floor) });
        }
        setOptions(result);
    }, []);
    return (_jsx(Select, { value: value !== undefined ? value === 0 ? 'ground' : value : '', options: options, onChange: (newValue) => onChange(convertOptionsToValues(newValue)), ...rest }));
};
