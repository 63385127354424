import { isBool } from '@f2w/utils';
export class PropDesc {
    target;
    defaults;
    _props = Object.create(null);
    constructor(target, defaults) {
        this.target = target;
        this.defaults = defaults;
    }
    static create(target, defaults) {
        return new PropDesc(target, defaults);
    }
    value(key, value, ...args) {
        return this._prop(key, {
            ...this.defaults,
            writable: isBool(args[0]) ? args.shift() : this.defaults?.writable ?? true,
            ...args.shift(),
            value,
        });
    }
    values(...args) {
        const props = args.pop();
        const defaults = args.pop();
        Object.keys(props).forEach((key) => this.value(key, props[key], defaults));
        return this;
    }
    getter(key, getter, ...args) {
        const { writable, ...defaults } = this.defaults;
        return this._prop(key, {
            ...defaults,
            visible: isBool(args[0]) ? args.shift() : true,
            ...args.shift(),
            get: getter,
        });
    }
    getters(...args) {
        const props = args.pop();
        const defaults = args.pop();
        Object.keys(props).forEach((key) => this.getter(key, props[key], defaults));
        return this;
    }
    _prop(key, { visible = true, ...rest }) {
        const desc = { enumerable: visible, ...rest };
        if (this.target)
            Object.defineProperty(this.target, key, desc);
        else
            this._props[key] = desc;
        return this;
    }
}
