import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import AccountingRecordSheetTableModel from '../models/AccountingRecordSheetTableModel';
import { Col, Row } from 'Components/Atoms';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { useFinancialAccountsHook } from 'Hooks/useFinancialAccountsHook';
import { dispatchCustomEvent } from "Services/EventManager";
export const useAccountingRecordSheetTable = (propertyId, fiscalYearId, initialFinancialAccountId, initialDateRange, accountingRecordServices, onEditClicked, incomeForm) => {
    const [accountBalance, setAccountBalance] = React.useState(null);
    const [selectedFinancialAccount, setSelectedFinancialAccount] = React.useState(null);
    const { groupedFinancialAccountOptions } = useFinancialAccountsHook({
        propertyId: propertyId
    });
    const tableModel = React.useMemo(() => AccountingRecordSheetTableModel.create({
        manualControl: true,
        FiltersLayout: ({ renderFilter }) => (_jsxs(Row, { children: [_jsx(Col, { xl: 2, children: renderFilter('financialAccount') }), _jsx(Col, { lg: 6, xl: 5, children: renderFilter('date') }), _jsx(Col, { lg: 6, xl: 5, children: renderFilter('amount') })] })),
        initialState: {
            filters: [
                {
                    id: 'financialAccount',
                    value: initialFinancialAccountId
                },
                {
                    id: 'fiscalYear',
                    value: fiscalYearId
                },
                {
                    id: 'date',
                    value: initialDateRange
                }
            ],
            sortBy: [
                {
                    id: 'date',
                    desc: true,
                }
            ],
        }
    }), []);
    const getData = React.useCallback(({ request }) => {
        const mappedRequest = { ...request };
        if (request.filters.date) {
            mappedRequest.filters.dateRange = request.filters.date;
            request.filters.date = undefined;
        }
        if (request.filters.amount) {
            mappedRequest.filters.amountRange = request.filters.amount;
            request.filters.amount = undefined;
        }
        mappedRequest.filters.addOpeningBalance = 1;
        return accountingRecordServices.listAccountingRecords(mappedRequest);
    }, []);
    const { success, error } = useToastDispatcherApi();
    const onDeleteFiscalYear = React.useCallback((accountingRecordId) => {
        accountingRecordServices.deleteAccountingRecord(accountingRecordId)
            .then(() => {
            dispatchCustomEvent('fw.accounting.recordDeleted', { id: accountingRecordId });
            success({
                timeout: 2,
                message: translate('flash.accounting.journal.recordDeletionSuccess')
            });
        })
            .catch(({ response }) => {
            const errorMessage = (response.status === 400)
                ? response.data.message
                : translate('flash.accounting.journal.recordDeletionFailure');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: onDeleteFiscalYear,
        message: translate('accountingRecord.delete.areYouSure'),
        help: translate('accountingRecord.delete.confirmation'),
        btnConfirm: translate('btn.confirm')
    });
    const onEditOpeningBalance = React.useCallback(() => {
        incomeForm.openForm({
            financialAccountId: selectedFinancialAccount,
            fiscalYearId: fiscalYearId
        });
    }, [fiscalYearId, selectedFinancialAccount, incomeForm]);
    const instance = tableModel.useTable({
        getData,
        onDeleteClicked: onDeleteConfirmation,
        onEditClicked: onEditClicked,
        onEditOpeningBalance: onEditOpeningBalance,
        groupedFinancialAccountOptions,
        accountBalance: accountBalance,
    });
    let financialAccountFilterValue = instance.columns.find(c => c.id === 'financialAccount')?.filterValue;
    React.useEffect(() => {
        accountingRecordServices.getAccountBalance(propertyId, financialAccountFilterValue, fiscalYearId)
            .then((data) => setAccountBalance(data));
        setSelectedFinancialAccount(financialAccountFilterValue);
    }, [financialAccountFilterValue]);
    return {
        instance
    };
};
