import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { StyledButton } from 'Atoms/Button/StyledButton';
import { isObject, OrFunction, orFunction } from 'Utils/types';
import { renderContent } from '../index';
import { Table, TdRoot, Tr } from 'Components/Atoms/Table';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { FlexRow } from 'Atoms/Layout';
import { Icon } from 'Atoms/Icon';
const LabelContent = ({ help: _help, field, children }) => {
    const help = orFunction(_help, field);
    const { message, ...props } = (help?.message ? help : help ? { message: help } : {});
    const messageContent = message && renderContent(message, { field }) || message || null;
    if (!messageContent) {
        return children;
    }
    return (_jsxs(FlexRow, { gap: 10, children: [children, _jsx(OverlayTrigger, { placement: 'top', flip: true, ...props, overlay: (_jsx(Tooltip, { id: `${field.id}--help`, children: messageContent })), children: _jsx(Icon, { style: { pointerEvents: 'all', cursor: 'pointer' }, "data-form-help": true, size: 20, icon: 'help-circle' }) })] }));
};
const Required = styled.span `
  font-size: 22px;
  color: ${p => p.theme.palette.error.$600};
`;
export const Label = Object.assign(({ field, children, text, help = field._type.options.help, ...props }) => {
    if (!field.showLabel && !children) {
        return null;
    }
    return (_jsxs(Label.Root, { htmlFor: `${field.id}--widget`, ...props, children: [field.showLabel && (_jsxs(LabelContent, { help: help, field: field, children: [_jsxs(Label.Text, { children: [_jsx(Label.Title, { children: field.label }), !field.isCompound && _jsx(Label.Required, { children: field.isRequired && '*' })] }), OrFunction(text)] })), children] }));
}, {
    Root: styled.label `
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 0 12px;
      ${p => p.theme.typography.body.fontStyles};
      color: ${p => p.theme.palette.neutral.$600};

      *[data-form-help] {
        color: ${p => p.theme.palette.secondary.$600};
        font-size: 20px;
      }
      ${Required} {
        font-size: 150%;
      }
    `,
    Required,
    Title: styled.span `
        font-size: inherit;
      font-weight: inherit;
      color: inherit;
      line-height: inherit;
      letter-spacing: inherit;
    `,
    Text: styled.span `
        display: flex;
        gap: 3px;
    `,
});
export const Error = Object.assign(({ field, ...props }) => {
    let error = field.error;
    if (isObject(error)) {
        Object.values(error).filter(e => typeof e !== 'object');
        return null;
    }
    return field.showError && !field.isCompound ? _jsx(Error.Root, { ...props, dangerouslySetInnerHTML: { __html: error } }) : null;
}, {
    Root: styled.div `
      color: var(--state-color, ${p => p.theme.palette.error.$500});
      margin: 6px 0 0;
    `,
});
export const Row = Object.assign(({ field, children, ...props }) => {
    return _jsx(Row.Root, { ...props, compound: field.isCompound, children: children });
}, {
    Root: styled.div `
      display: block;
      //position: relative;
      //flex-direction: column;
      margin-bottom: ${p => p.compound ? '0' : 'var(--form-row-spacing, 1.5rem)'};
    `,
});
export const Group = Object.assign(({ field, ...props }) => {
    return _jsx(Group.Row, { ...props });
}, {
    Row: styled(Row.Root) `
      ${p => {
        if (p.variant === 'box') {
            return {
                border: `1px ${p.theme.palette.neutral.$100} solid`,
                borderRadius: 8,
                padding: '30px 35px',
                marginBottom: 'var(--form-row-spacing, 1.5rem)',
                [`${Label.Title}${Label.Title}`]: {
                    marginTop: 0
                },
            };
        }
        return {};
    }};
    `,
    Label: styled(Label.Root) `
      ${Label.Title} {
        ${p => p.theme.typography.body.cssSize({ fontWeight: 'bold' })};
        margin: 1em 0 .75em;
      }
    `,
    Error: styled(Error.Root) ``,
});
const Actions = styled.div.attrs(props => ({})) `
  display: flex;
`;
export const Collection = Object.assign(({ field, ...props }) => {
    return _jsx(Collection.Row, { ...props });
}, {
    Row: styled(Row.Root) ``,
    Error: styled(Error.Root) ``,
    TableRow: styled(Tr) `
      & > ${TdRoot} > ${Row.Root} {
        margin-bottom: 0;
      }
    `,
    Table: styled(Table) `
      margin-bottom: 40px;
      margin-top: -10px;
    `,
    Label: styled.label `
      position: relative;
      //display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: flex-start;
      text-align: left;
      margin: 20px 0;
      padding: 5px 0;
      border-bottom: 1px solid ${p => p.theme.palette.dark.$100};

      ${Actions} {
        position: absolute;
        z-index: 10;
        margin: 0 0 auto auto;
        top: 0;
        right: 0;
        justify-content: flex-end;

        ${StyledButton} {
          padding: 0 3px;
        }
      }
    `,
    Body: styled.div `
      //&& > * {
      //  transition: opacity ease-in 300ms;
      //}
      &&:hover > * {
        opacity: .4;

        &:not(:hover) {
          --trans-duration: 250ms;
          --trans-delay: 100ms;
          --trans-timing-function: ease-out;
          opacity: .45;
        }

        &:hover {
          opacity: 1;
        }
      }
    `,
    Section: styled.div `
      --trans-duration: 300ms;
      //--trans-delay: 250ms;
      --trans-timing-function: ease-in;
      --opacity: 0;

      &:hover {
        --trans-delay: 0;
        --trans-duration: 300ms, 300ms, 300ms, 180ms, 180ms, 180ms, 180ms;
        --trans-timing-function: ease-out, ease-out, ease-out, ease-in, ease-in, ease-in, ease-in;
        --position-offset: -10px;
        --opacity: .9;

        ${Actions} {
          visibility: visible;
          opacity: 1;
        }
      }

      ${p => p.status ? css `
        --trans-duration: 200ms;
        --trans-delay: 50ms;
        --trans-timing-function: ease-out;
        --position-offset: -8px;
        --background-color: ${p.theme.palette[p.status || 'primary'].$50}22;
        --border-color: ${p.theme.palette[p.status || 'primary'].$400};

      ` : css `

        --trans-delay: 300ms, 300ms, 300ms, 300ms, 300ms, 300ms, 300ms;
        --position-offset: -2px;
        --background-color: transparent;
        --border-color: transparent;

        &:hover {
          --background-color: ${p.theme.palette.primary.$50}22;
          --border-color: ${p.theme.palette.primary.$100};
        }
      `};

      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      position: relative;
      margin: 20px 0;

      //--trans-property: background-color, opacity, left, right, bottom, top;
      transition-property: background-color, opacity, border-color, left, right, bottom, top, box-shadow;
      transition-duration: var(--trans-duration);
      transition-delay: var(--trans-delay);
      transition-timing-function: var(--trans-timing-function);

      ${Actions} {
        transition: inherit;
        position: absolute;
        z-index: 10;
        //top: calc(100% - 20px);
        top: -30px;
        align-self: center;
        align-items: flex-start;
        margin: 0 auto;
        padding: 6px;

        opacity: var(--opacity);
        //visibility: hidden;
        background: #ffffff;
        border: 0px solid var(--border-color);
        border-radius: 8px;
        gap: 8px;
        //box-shadow: 0 0 0 1px var(--border-color);
        ${StyledButton} {
          padding: 4px;
          ${p => p.theme.typography.small.fontStyles};
        }
      }

      &:before {
        content: "";
        position: absolute;
        pointer-events: none;
        left: var(--position-offset);
        top: var(--position-offset);
        right: var(--position-offset);
        bottom: var(--position-offset);
        width: auto;
        height: auto;

        transition: inherit;

        opacity: var(--opacity);
        background-color: var(--background-color);
        border: 0px var(--border-color) solid;
        border-radius: 8px;
        box-shadow: 0 0 0 3px var(--border-color);
      }
    `,
    Actions,
});
