import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { renderContent, setDebugVariable } from '@f2w/utils';
import { PropDesc } from './utils';
import useStableMemo from '@restart/hooks/useStableMemo';
import { useNavigation, useOutletContext } from 'react-router-dom';
import { StepApi } from './StepApi';
class BaseWizardType {
    constructor(config, data) {
        PropDesc.create(this, { visible: true, writable: false, configurable: false })
            .values({
            config,
        })
            .values({
            props: {
                id: `${config.id}_${data?.id ?? 'loading'}`,
                data: { ...data },
                loaded: !!data?.id,
            },
        });
    }
    get templates() {
        return this.config.layout;
    }
}
export class WizardType extends BaseWizardType {
    stepApi = new StepApi(this.config, this);
    constructor(config, data) {
        super(config, data);
        PropDesc.create(this, { visible: true, writable: false, configurable: false })
            .getters({
            steps: () => this.stepApi.steps,
            stepList: () => this.stepApi.stepList,
        });
        console.debug('-- CREATE - WizardType -- %O', this);
        setDebugVariable({ fw_wizard: this });
    }
    get id() {
        return this.props.id;
    }
    get data() {
        return this.props.data;
    }
    get steps() {
        return this.stepApi.steps;
    }
    get valueType() {
        return this.stepApi.valueType;
    }
    get stepList() {
        return this.stepApi.stepList;
    }
    get step() {
        return this.stepApi.currentStep;
    }
    get loading() {
        return this.props.nav?.state === 'loading';
    }
    get title() {
        return this.config.title(this);
    }
    getStep = (stepId) => {
        return this.stepApi.getStep(stepId);
    };
    close = () => {
        return this.stepApi.handleClose();
    };
    setStep = (stepId) => {
        if (stepId !== this.stepApi.currentStep.id && !this.stepApi.setCurrent(stepId)) {
            return this.stepApi.currentStep;
        }
    };
    render = (props) => {
        return renderContent(this.RootTemplate, {
            key: `wizard--${this.id}`,
            ...props,
        });
    };
    RootTemplate = ({ children }) => {
        const templates = this.config.layout;
        const props = this.stepApi.useType();
        return _jsx(_Fragment, { children: _jsxs(templates.Root, { ...props, children: [children, props.step.render()] }) });
    };
    static use(props) {
        const wizard = useStableMemo(() => {
            return new WizardType(props.config, props.data);
        }, [props.config?.id, props.data?.id]);
        wizard.props.nav = useNavigation();
        props.stepId && wizard.setStep(props.stepId);
        return wizard;
    }
}
(function (WizardType) {
    function create(props) {
        return new WizardType(props.config, props.data);
    }
    WizardType.create = create;
})(WizardType || (WizardType = {}));
export function useWizardContext() {
    const nav = useOutletContext();
    const stepApi = nav?.wizard?.stepApi;
    return {
        ...nav,
        step: stepApi?.currentStep,
        valueType: stepApi?.currentStep?.valueType,
        stepApi
    };
}
