import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Table, Tbody, Td, Tr } from 'Atoms/Table';
import { cssVars } from '@f2w/theme';
import { FormControl } from 'Atoms/Input';
import { Text } from 'Atoms/Text';
import { FlexRow } from 'Atoms/Layout';
import { Icon } from 'Atoms/Icon';
import { Button } from 'Atoms/Button';
const MyTable = styled(Table) `
    && {
      --row-padding-y: 16px;
    }
`;
export const AccordionTable = ({ arrayValue, Header, Footer, Row }) => {
    return _jsx(MyTable, { variant: 'accordion', size: 'sm', className: "fw-collection--body", colSpan: 3, children: _jsx(Tbody, { children: arrayValue.map((row) => {
                const isSelected = arrayValue.isSelected(row);
                const isPartialSelected = arrayValue.isPartialSelected(row);
                return _jsxs(Tr, { expanded: isSelected, onClick: (e) => {
                        if (e.target?.['tagName'] === 'TD') {
                            arrayValue.updateSelected(row);
                        }
                    }, expandableRows: () => {
                        return _jsxs(Tr, { style: cssVars({ 'cell-valign': 'top' }), children: [Row(row), Footer && Footer(row)] });
                    }, children: [_jsx(Td, { children: _jsx(FormControl.Checkbox, { checked: isSelected, indeterminate: isPartialSelected, onChange: (v, e) => {
                                    e.stopPropagation();
                                    arrayValue.updateSelected(row);
                                } }) }), _jsx(Td, { auto: true, children: _jsx(Text, { body: 500, primary: 500, children: Header(row) }) }), _jsx(Td, { noWrap: true, children: _jsxs(FlexRow, { gap: 20, justifyRight: true, alignCenter: true, children: [(isSelected && row.meta.hasError) && _jsx(Text, { error: true, size: 'large', children: _jsx(Icon, { icon: 'alert-circle' }) }), arrayValue.specs.showDelete && _jsx(Button.Delete, { "$size": 'sm', onClick: () => arrayValue.remove(row) })] }) })] }, `${row.id}--table-row`);
            }) }) });
};
