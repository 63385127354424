import { isObject } from '@f2w/utils';
import { PropDesc } from './utils';
import { ObjectType } from '@f2w/form-new';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import { StepType } from './StepType';
import { translate } from 'Services/App';
import { useDispatcherApi } from 'Components/Dispatcher';
import { useNavigate, useNavigation } from 'react-router-dom';
export class StepApi {
    config;
    constructor(config, parent) {
        this.config = config;
        const desc = PropDesc.create(this, { visible: true, writable: false, configurable: false });
        desc
            .values({ visible: false }, {
            _props: {
                currentIndex: 0,
                lastIndex: 0,
                type: ObjectType.create({})
            }
        })
            .values({
            steps: Object.create(null),
            stepIds: [],
            stepList: [],
        });
        desc
            .getters({
            type: () => this._props.type,
            valueType: () => {
                if (!this._props.valueType) {
                    const rootType = this._props.valueType = this.type?.init({
                        initialValue: { ...parent.data?.['stepData'] },
                        context: {
                            dispatchUpdate: () => this._props.dispatchUpdate?.(),
                        },
                    });
                }
                return this._props.valueType;
            }
        });
        Object.keys(config.steps)
            .forEach(id => this._createStep(config.steps, id, parent));
    }
    _createStep(configs, id, parent) {
        const shape = this._props.type.specs.shape;
        if (!isObject(configs[id]))
            return;
        const stepType = this.steps[id] = new StepType({
            id,
            config: configs[id],
            index: this.stepList.length,
            parent,
        });
        this.stepList.push(stepType);
        this.stepIds.push(id);
        if (stepType.type) {
            shape.add(stepType.id, stepType.type);
        }
    }
    updateStep = (step) => {
        this._props.navigate(step.id);
    };
    _updateNext = () => {
        const nextStep = this.currentStep.nextStep();
        if (nextStep) {
            this._props.lastIndex = Math.max(nextStep.index, this._props.lastIndex);
            this.updateStep(nextStep);
        }
    };
    handlePrev = () => {
        const nextStep = this.currentStep.prevStep();
        if (nextStep) {
            this.updateStep(nextStep);
        }
    };
    goToStep = (idOrIndex) => {
        this.updateStep(this.getStep(idOrIndex));
    };
    async _handleSave() {
        const valueType = this.valueType;
        const data = valueType.formattedValue;
        console.log('-- _handleSave -- ', { data });
        this.config.save?.(data, this);
    }
    ;
    handleNext = () => {
        const { dispatcherApi } = this._props;
        const step = this.currentStep;
        if (!step.hasType()) {
            return this._updateNext();
        }
        const valueType = step.valueType;
        return valueType.submit()
            .then(async () => {
            if (!step.isLast) {
                return this._updateNext();
            }
            else {
                return this._handleSave();
            }
        })
            .catch(e => {
            if (step.isLast) {
                dispatcherApi?.toast.error({
                    message: translate('wizard.save.error'),
                });
            }
        });
    };
    handleClose = () => {
        const { navigation, navigate } = this._props;
        const closeProps = this.config.onClose?.({ navigate, navigation });
        return closeProps;
    };
    handleSaveDraft = () => {
        console.log('-- handleSaveDraft -- ');
    };
    useType() {
        this._props.dispatchUpdate = useForceUpdate();
        this._props.dispatcherApi = useDispatcherApi();
        this._props.navigate = useNavigate();
        this._props.navigation = useNavigation();
        const step = this.currentStep;
        const wizard = step?.parent;
        return {
            step,
            wizard,
            config: wizard.config,
            stepApi: wizard.stepApi,
            loading: step.loading,
            valueType: step.valueType,
        };
    }
    ;
    get currentIndex() {
        return this._props.currentIndex;
    }
    get isFirst() {
        return this.currentIndex === 0;
    }
    get isLast() {
        return !this.currentStep.nextStep();
    }
    get currentStep() {
        return this.stepList[this.currentIndex];
    }
    get lastIndex() {
        return this._props.lastIndex;
    }
    get lastStep() {
        return this.stepList[this.lastIndex];
    }
    setCurrent = (idOrIndex) => {
        const step = this.getValid(idOrIndex);
        if (step && step.index !== this.currentIndex) {
            this._props.currentIndex = step.index;
            return step;
        }
    };
    getStep = (idOrIndex) => {
        return (this.steps[idOrIndex] ?? this.stepList[idOrIndex]);
    };
    getValid = (idOrIndex) => {
        const step = this.getStep(idOrIndex);
        return step?.isEnabled && step;
    };
    hasStep = (idOrIndex) => {
        return !!this.getStep(idOrIndex);
    };
    isValid = (idOrIndex) => {
        return !!this.getValid(idOrIndex);
    };
}
