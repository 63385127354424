import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Div, FlexRow, Text } from 'Components/Atoms';
import { Form, FormProvider, ServerError, useForm } from '@f2w/form';
import parse from 'html-react-parser';
import { translate } from 'Services/Translator';
import { Content } from 'Atoms/Layout/components';
import { DebitorType } from '../forms/DebitorType';
import moment from 'moment';
export const ManualPaymentFormContentBlock = ({ closeForm, debitorLabel, debitorId, payerBankAccountId, debitorAmountRemaining, financialAccountId: debitAccountId, createManualPayment }) => {
    const manualPaymentForm = useForm({
        name: 'manual-payment-form',
        initialValues: {
            label: translate("payment.create.initialLabel", {
                invoiceLabel: debitorLabel
            }),
            amount: debitorAmountRemaining,
            paidAt: moment().format('YYYY-MM-DD'),
            debitAccountId: debitAccountId,
            payerBankAccountId: payerBankAccountId
        },
        type: () => new DebitorType.PaymentType(),
        title: translate('debitor.actions.pay'),
        onSave: async (values) => {
            return createManualPayment(debitorId, values).then(closeForm);
        }
    });
    return (_jsxs(Content, { children: [_jsx(FlexRow, { mb: 15, children: _jsx(Text, { h6: 500, neutral: 500, children: translate('debitorInvoice.payments.create') }) }), _jsx(Div, { style: { overflowY: 'auto' }, children: _jsx(FormProvider, { form: manualPaymentForm, children: _jsx(Form, { noValidate: true, style: { padding: '0 15px' }, children: manualPaymentForm.render() }) }) }), _jsxs(FlexRow, { alignCenter: true, mt: 10, children: [_jsx(Box, { flex: '1 1 auto', children: _jsx(ServerError, { children: manualPaymentForm.status?.error
                                ? parse(manualPaymentForm.status?.error)
                                : undefined }) }), _jsxs(FlexRow, { justifyContent: 'flex-end', gap: 5, children: [_jsx(Button, { variant: 'link', color: 'neutral', type: 'button', onClick: closeForm, children: translate('btn.close') }), _jsx(Button, { type: 'submit', onClick: () => manualPaymentForm.submitForm(), children: translate('btn.save') })] })] })] }));
};
