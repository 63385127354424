import { jsx as _jsx } from "react/jsx-runtime";
import { transChoice, translate } from 'Services/Translator';
import { Div, FlexRow, Input, Text } from 'Components/Atoms';
import { formatAmount } from 'Services/NumberHelper';
import { CalculationServices } from '../services';
import { toNum } from 'Utils';
import { DataTable } from '@f2w/data-table';
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const GroupedTable = DataTable.factory()(() => ({
    tableId: 'CalculationAccount',
    manualControl: false,
    showEmpty: true,
    usePagination: {},
    useGroupBy: {
        initialState: { groupBy: ['name'] }
    },
    useRowState: {
        initialRowStateAccessor: (...args) => {
            return {};
        },
    },
    RowActions: ({ row: { id }, onViewFinancialAccount }) => {
        if (isNaN(parseInt(id))) {
            return _jsx(Div, { w: 50 });
        }
        return _jsx(ActionsDropdown, { id: id, quickActions: [
                {
                    icon: 'eye',
                    title: translate('dunning.accountStatement'),
                    onClick: () => onViewFinancialAccount(Number(id))
                },
            ] });
    },
    columns: {
        name: {
            accessor: d => d.account.name,
            Header: translate('ancillaryExpenseCalculation.expenses.type'),
            useSortBy: {},
            Cell: ({ value }) => {
                return _jsx(Text, { value: value });
            }
        },
        financialAccount: {
            accessor: d => d.displayName,
            Header: translate('ancillaryExpenseAccount.financialAccount'),
            useSortBy: {},
            aggregate: 'uniqueCount',
            Aggregated: ({ value }) => {
                return _jsx(Text, { children: transChoice('ancillaryExpenseAccount.financialAccount.count', toNum(value)) });
            },
        },
        amount: {
            Header: translate('amount'),
            useSortBy: {},
            aggregate: 'sum',
            Aggregated: ({ value }) => {
                return _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) });
            },
            Cell: ({ value, editMode, updateApi, row }) => {
                const { amount = value, } = updateApi.getData(row.id);
                if (!editMode) {
                    return (_jsx(FlexRow, { justifyFlexEnd: true, children: amount !== undefined && amount !== null
                            ? formatAmount(amount)
                            : translate('notAvailable') }));
                }
                return (_jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', prefix: 'CHF', value: amount ?? 0, onChange: (value) => {
                        updateApi.update(row.id, {
                            amount: value
                        });
                    } }));
            },
        },
        $rowExpand: {},
    },
}));
export const CalculationAccountTable = Object.assign(GroupedTable, {
    use: ({ calculationId, calculationServices = CalculationServices, reloadDistributions, onViewFinancialAccount, editMode, setEditMode, }) => {
        return GroupedTable.useTable({
            calculationServices,
            calculationId,
            reloadDistributions,
            onViewFinancialAccount,
            editMode,
            setEditMode,
        }, {
            getData: async () => {
                const results = await calculationServices.listCalculationAccounts(calculationId) ?? [];
                return {
                    results: convertData(results)
                };
            }
        });
    }
});
const convertData = (data) => {
    const newData = [];
    data.forEach(({ id, account, financialAccounts, keyVariation, }) => {
        financialAccounts.forEach(({ id: financialAccountId, displayName, amount, }) => {
            newData.push({
                id: `${id}-${financialAccountId}`,
                expenseId: id,
                displayName,
                account,
                financialAccountId,
                keyVariation,
                amount,
            });
        });
    });
    return newData;
};
