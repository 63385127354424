import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { translate } from 'Services/Translator';
import { DeleteIcon, LockIcon, UnlockIcon } from 'Components/App/Icons';
import { Button, Select } from 'Components/Atoms';
import { DateType } from '@f2w/form';
import { FiscalYearType } from "Pages/Accounting/FiscalYear/enums";
export const FiscalYearTableModel = Table.factory()(() => ({
    tableId: 'FiscalYear',
    RowActions: ({ onRefreshBalancesClicked, row: { original: { id, availableActions } }, refresh, onDeleteClicked, onToggleLockClicked, onEditClicked }) => (_jsxs(ActionsDropdown, { id: id, quickActions: [
            availableActions.includes('refresh') ? {
                icon: 'refresh-cw',
                title: translate('fiscalYear.refreshOpeningBalances'),
                onClick: () => onRefreshBalancesClicked(id)
            } : undefined,
            availableActions.includes('edit') && {
                icon: 'edit',
                title: translate('btn.edit'),
                onClick: () => onEditClicked(id)
            }
        ], children: [_jsxs(ActionsDropdown.Link, { disabled: !availableActions.includes('delete'), onClick: () => onDeleteClicked(id, refresh), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }), availableActions.includes('lock') && _jsxs(ActionsDropdown.Link, { onClick: () => onToggleLockClicked(id, refresh), children: [_jsx(LockIcon, {}), " ", _jsx("span", { children: translate('accounting.fiscalYear.table.actions.lock') })] }), availableActions.includes('unlock') && _jsxs(ActionsDropdown.Link, { onClick: () => onToggleLockClicked(id, refresh), children: [_jsx(UnlockIcon, {}), " ", _jsx("span", { children: translate('accounting.fiscalYear.table.actions.unlock') })] })] })),
    Actions: ({ onCreateClicked }) => (_jsx("div", { children: _jsx(Button, { icon: "plus", label: translate('new'), onClick: onCreateClicked, title: translate('accounting.fiscalYear.table.actions.new') }) })),
    useSortBy: {
        initialState: {
            sortBy: [
                {
                    id: 'label',
                    desc: true
                }
            ]
        }
    },
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $actions: {
            sticky: 'left',
        },
        label: {
            Header: translate('accounting.fiscalYear.table.columns.label'),
            useSortBy: {},
        },
        type: {
            Header: translate('fiscalYear.type'),
            Cell: ({ value }) => translate(`fiscalYear.type.${value}`),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => {
                    return _jsx(Select, { value: filterValue || '', isSearchable: true, isClearable: true, options: Object.values(FiscalYearType).map(fy => ({
                            label: translate(`fiscalYear.type.${fy}`),
                            value: fy
                        })), onChange: newValue => setFilter(newValue?.value) });
                },
            }
        },
        starts: {
            Header: translate('accounting.fiscalYear.table.columns.starts'),
            useSortBy: {},
            Cell: ({ value }) => DateType.format(value),
        },
        ends: {
            Header: translate('accounting.fiscalYear.table.columns.ends'),
            useSortBy: {},
            Cell: ({ value }) => DateType.format(value),
        },
        lockedAt: {
            Header: translate('accounting.fiscalYear.table.columns.lockedAt'),
            useSortBy: {},
            Cell: ({ value }) => value ? DateType.format(value) : translate('notAvailable')
        }
    }
}));
