import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useModalDispatcherConfirm } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import * as ObjectViewModalServices from './services/objectViewModalServices';
import { getBreadcrumbsForObject } from './services/objectViewModalServices';
import { ActiveView } from './enums';
import { useObjectDeleteApi } from './hooks/useObjectDeleteApi';
import { FormModal } from '@f2w/form';
import { useRentalObjectAddRoomForm } from 'Components/ObjectViewModal/hooks/useRentalObjectAddRoomForm';
const ObjectViewModalContext = React.createContext({});
export const ObjectViewModalProvider = ({ children, initialActiveView, onClose, getBreadcrumbsForObject: _getBreadcrumbsForObject = getBreadcrumbsForObject }) => {
    const activeViewRef = React.useRef();
    const [breadcrumbs, setBreadcrumbs] = React.useState([]);
    const [activeView, setActiveView] = React.useState();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [hasErrors, setHasErrors] = React.useState(false);
    const [saveEnabled, setSaveEnabled] = React.useState(false);
    const [editData, setEditData] = React.useState();
    const [contractData, setContractData] = React.useState();
    React.useEffect(() => {
        if (initialActiveView) {
            setModalOpen(true);
            setActiveView(initialActiveView);
        }
    }, [initialActiveView]);
    React.useEffect(() => {
        let ignore = false;
        if (activeView) {
            setBreadcrumbs([]);
            _getBreadcrumbsForObject(activeView.view, activeView.id)
                .then(data => {
                if (!ignore) {
                    setBreadcrumbs(data);
                }
            });
        }
        else {
            setBreadcrumbs([]);
        }
        return () => {
            ignore = true;
        };
    }, [activeView]);
    const closeModalConfirm = React.useCallback(() => {
        if (activeViewRef.current) {
            activeViewRef.current.closeForm(() => {
                setModalOpen(false);
                setSaveEnabled(false);
                onClose();
                setContractData(undefined);
            });
        }
        else {
            setModalOpen(false);
            setSaveEnabled(false);
            onClose();
            setContractData(undefined);
        }
    }, []);
    const onCloseConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: closeModalConfirm,
        message: translate('baseData.areYouSure'),
        help: translate('baseData.switchViewConfirmation.areYouSure.help'),
        btnConfirm: translate('btn.confirm')
    });
    const closeModal = saveEnabled ? onCloseConfirmation : closeModalConfirm;
    const setCurrentActiveViewConfirm = React.useCallback((activeView, id, parentRentalObjectId) => {
        setActiveView({
            id,
            view: activeView,
            parentRentalObjectId
        });
        setSaveEnabled(false);
    }, []);
    const onChangeViewConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: setCurrentActiveViewConfirm,
        message: translate('baseData.areYouSure'),
        help: translate('baseData.switchViewConfirmation.areYouSure.help'),
        btnConfirm: translate('btn.confirm')
    });
    const setCurrentActiveView = (activeView, id, parentRentalObjectId) => {
        if (saveEnabled || activeViewRef?.current?.form?.dirty) {
            onChangeViewConfirmation(activeView, id, parentRentalObjectId);
        }
        else {
            setCurrentActiveViewConfirm(activeView, id, parentRentalObjectId);
        }
    };
    const deleteApi = useObjectDeleteApi({
        onSuccess: closeModalConfirm
    });
    const onDelete = React.useCallback(() => {
        switch (activeView.view) {
            case ActiveView.PROPERTY: {
                deleteApi.deleteProperty(activeView.id);
                break;
            }
            case ActiveView.BUILDING: {
                deleteApi.deleteBuilding(activeView.id);
                break;
            }
            case ActiveView.RENTAL_OBJECT: {
                deleteApi.deleteRentalObject(activeView.id);
                break;
            }
            case ActiveView.RENTAL_OBJECT_ROOM: {
                deleteApi.deleteRentalObjectRoom(activeView.id);
                break;
            }
            case ActiveView.RENT: {
                deleteApi.deleteRent(activeView.id);
                break;
            }
        }
    }, [activeView]);
    const addRoomForm = useRentalObjectAddRoomForm({
        addRentalObjectRoom: ObjectViewModalServices.addRentalObjectRoom,
    });
    const updateBreadcrumbName = useCallback((view) => (id, name) => {
        setBreadcrumbs(prev => {
            const breadcrumbs = [...prev];
            breadcrumbs[breadcrumbs.findIndex(b => b.id === id && b.view === view)].name = name;
            return breadcrumbs;
        });
    }, []);
    const state = {
        activeView, setCurrentActiveView,
        breadcrumbs,
        modalOpen, closeModal,
        editData, setEditData,
        hasErrors, setHasErrors,
        saveEnabled, setSaveEnabled,
        updatePropertyNameForBreadcrumb: updateBreadcrumbName(ActiveView.PROPERTY),
        updateBuildingNameForBreadcrumb: updateBreadcrumbName(ActiveView.BUILDING),
        updateRentalObjectNameForBreadcrumb: updateBreadcrumbName(ActiveView.RENTAL_OBJECT),
        onDelete,
        setActiveView: (view) => activeViewRef.current = view,
        activeViewType: activeViewRef.current,
        contractData, setContractData,
        openAddRoomForm: (id) => {
            closeModal();
            addRoomForm.openForm(id);
        }
    };
    return (_jsxs(ObjectViewModalContext.Provider, { value: { ...state }, children: [children, _jsx(FormModal, { modal: addRoomForm })] }));
};
export const useObjectViewModal = () => React.useContext(ObjectViewModalContext);
