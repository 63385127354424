import { forEachObject } from '@f2w/utils';
import { ChildDescriptor, IndexManager, KeyOrChild } from './descriptors';
import { BaseState } from './BaseState';
export class CompoundState extends BaseState {
    _config = this.defaultConfig();
    constructor(userConfig) {
        super(userConfig);
        Object.defineProperties(this, {
            meta: {
                value: this._config.meta,
                writable: false,
                configurable: false
            },
            data: {
                value: this._config.data,
                writable: false,
                configurable: false
            },
        });
    }
    get _indexManager() {
        return this._config.indexMap;
    }
    defaultConfig() {
        return this._createConfig(this._configureDescriptors());
    }
    _createConfig(config) {
        const metaProps = Object.create(null);
        const dataProps = Object.create(null);
        const indexMap = IndexManager.create({}, {
            onChange: (event) => {
                this._userConfig?.onIndexChange?.(event);
            },
        });
        const gettersMap = Object.create(null);
        forEachObject(config, (key, configProp) => {
            const defaults = { configurable: false, enumerable: true };
            let _data = { ...defaults };
            let _meta = { ...defaults };
            if (configProp.index) {
                const index = indexMap.create(key, configProp.index);
                _meta.get = () => index.get();
            }
            if (configProp.getMetaValue)
                _meta.get = () => configProp.getMetaValue();
            if (configProp.getValue)
                _data.get = () => configProp.getValue();
            if (configProp.child) {
                const getter = gettersMap[key] = ChildDescriptor.create(key, configProp.child);
                _data.get = () => getter.data;
            }
            if (_data.get)
                dataProps[key] = { ..._data };
            if (_meta.get)
                metaProps[key] = { ..._meta };
        });
        const data = Object.defineProperties({}, { ...metaProps, ...dataProps });
        const meta = Object.defineProperties({}, { ...metaProps });
        return {
            props: config,
            gettersMap,
            indexMap,
            data: data,
            meta: meta,
        };
    }
    childrenMap = new Map();
    get indexes() {
        return this._indexManager.data;
    }
    get index() {
        return this._indexManager;
    }
    isActive(keyOrChild) {
        return true;
    }
    getChildrenSize() {
        return this.childrenMap.size;
    }
    getActiveChildrenSize() {
        return this.childrenMap.size;
    }
    onAddChild(child) {
    }
    onRemoveChild(child) {
    }
    keys() {
        return [...this.childrenMap.keys()];
    }
    all() {
        return [...this.childrenMap.values()];
    }
    has(keyOrChild) {
        return this.childrenMap.has(KeyOrChild.key(keyOrChild));
    }
    get(keyOrChild) {
        return this.childrenMap.get(KeyOrChild.key(keyOrChild));
    }
    forEach(cb) {
        this.childrenMap.forEach(cb);
    }
    map(cb) {
        const items = [];
        this.childrenMap.forEach((child, key) => {
            const resolvedChild = cb(child, key);
            resolvedChild && items.push(resolvedChild);
        });
        return items;
    }
    register(key, child) {
        if (key && !this.has(key)) {
            const state = child['_state'];
            if (state['_parent'] && (state['_parent'] !== this || state.key !== key)) {
                state['_parent'].remove(child);
            }
            this.childrenMap.set(key, child);
            state.props.key = key;
            state.props.parent = this._self();
            forEachObject(this._config.gettersMap, (key, getter) => {
                getter.add(child);
            });
            this.onAddChild(child);
        }
    }
    remove(keyOrChild) {
        const child = this.get(keyOrChild);
        if (child) {
            this.clearIndexes([keyOrChild]);
            forEachObject(this._config.gettersMap, (k, getter) => {
                getter.remove(child.key);
            });
            this.onRemoveChild(child);
            this.childrenMap.delete(child.key);
            if (child['_state']['_parent'] === this) {
                child['_state'].props.parent = null;
            }
            this.updateParent();
            return true;
        }
    }
    _updateData(key, value, force) {
        return super._updateData(key, value);
    }
    clearIndexes(childrenOrKeys) {
        if (childrenOrKeys) {
            this.index.update((index) => index.removeAll(childrenOrKeys));
        }
        else {
            this.index.update((index) => index.clear());
        }
    }
    updateIndexes(props) {
        const {} = props;
        const updatedKeys = props.childrenOrKeys.filter(keyOrChild => {
            const child = this.get(keyOrChild);
            if (!child) {
                console.log('no child', keyOrChild, props);
                return false;
            }
            return this.index.update((index, k) => {
                if (props.ignoredKeys && props.ignoredKeys.includes(k))
                    return false;
                return child && index._update(child);
            }, props.keys);
        });
        if (updatedKeys) {
            this.updateParent();
            return true;
        }
    }
    _dumpMeta() {
        const keys = {};
        Object.entries(this.index.data).forEach(([key, index]) => {
            keys[key] = [...index.values()];
        });
        return keys;
    }
    _configureDescriptors() {
        return {
            disabled: {
                set: (value) => this._own.disabled = value,
                getValue: () => this._own.disabled ?? false,
                getMetaValue: () => {
                    return this._own.disabled || this._parent?.meta.disabled || false;
                },
                index: {
                    getMetaValue: (set) => set.size > 0,
                },
            },
            active: {
                getMetaValue: () => false,
                set: (val) => {
                    return false;
                },
            },
            visible: {
                getMetaValue: () => {
                    return true;
                },
                set: (val) => {
                    return false;
                },
            },
            dirty: {
                index: {
                    getMetaValue: (set) => set.size > 0,
                },
            },
            touched: {
                index: {
                    getMetaValue: (set) => set.size > 0,
                },
                child: {
                    getValue: (child) => child.touched,
                    set: (child, value) => child.setTouched(value, false),
                },
            },
            validated: {
                index: {
                    getMetaValue: (set) => set.size === this.getActiveChildrenSize(),
                }
            },
            validating: {
                index: {
                    getMetaValue: (set) => set.size > 0,
                },
            },
            hasError: {
                index: {
                    getMetaValue: (set) => set.size > 0,
                },
            },
            valid: {
                getMetaValue: () => (this._config.meta.validated && !this._config.meta.hasError),
            },
            fields: {
                child: {
                    getValue: (child) => child,
                },
            },
            initialValue: {
                child: {
                    getValue: (child) => child.initialValue,
                    set: (child, value) => child.setValue(value),
                },
            },
            value: {
                child: {
                    getValue: (child) => child.value,
                    set: (child, value) => child.updateValue(value, true),
                },
            },
            error: {
                child: {
                    getValue: (child) => child.error || false,
                    set: (child, value) => child.setError(value),
                },
            },
            prevValue: {
                child: {
                    getValue: (child) => child.data.prevValue,
                },
            },
            formattedValue: {
                child: {
                    getValue: (child) => child.data.formattedValue,
                },
            },
            humanValue: {
                child: {
                    getValue: (child) => child.data.humanValue,
                },
            },
        };
    }
}
