import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { GeoAdminMap } from 'Atoms/Map/GeoAdminMap';
import { Section } from 'Atoms/Layout/components';
import { Text, Title } from 'Atoms/Text';
import { Button } from 'Atoms/Button';
import styled from 'styled-components';
import { Col, Row } from 'Atoms/Grid';
import { useDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { generate } from 'Services/Router';
import { getGlobalContext } from 'Services/GlobalContext';
import { Overlay, Tooltip } from 'Atoms/Overlay';
import { CreatePropertyApi } from '../../services/createPropertyServices';
const MemoizedGeoAdminMap = React.memo(GeoAdminMap);
export function CreatePropertyTemplate({ valueType }) {
    const { property, pin } = valueType.value;
    return _jsxs(_Fragment, { children: [_jsx(Section.Divider, { withPadding: true, size: 1, spacing: '25px' }), _jsxs(Section, { children: [_jsx(Title, { as: 'h2', h5: true, children: property.name }), _jsxs(Title, { as: 'h3', large: true, children: [property.zip, " ", property.city] })] }), _jsx(Section, { children: _jsxs(Row, { gap: 13, style: { rowGap: 26 }, children: [_jsx(Col, { xl: { span: 6, order: 2 }, children: pin && _jsx(MemoizedGeoAdminMap, { mapPin: pin }) }), _jsx(Col, { xl: 6, children: _jsx(Box, { title: valueType.children.property.label, children: valueType.children.property.render() }) })] }) }), _jsx(Section, { children: _jsx(Box, { title: valueType.children.buildings.label, children: valueType.children.buildings.render() }) }), _jsx(Section, { sticky: { bottom: 0 }, children: _jsx(CreatePropertyTemplateFooter, { valueType: valueType }) })] });
}
const CreatePropertyTemplateFooter = ({ valueType }) => {
    const { toast } = useDispatcherApi();
    const limits = useLimits(valueType.children.buildings);
    const target = React.useRef(null);
    const isExceeded = limits.used > limits.total;
    return _jsxs(_Fragment, { children: [_jsx(Button, { block: true, ref: target, disabled: isExceeded, label: translate('createProperty.save.action'), onClick: () => valueType.submit()
                    .then(async () => {
                    const data = valueType.getResolvedValue();
                    const resp = await CreatePropertyApi.create(data);
                    toast.success({
                        message: translate('createProperty.save.success'),
                    });
                    setTimeout(() => {
                        window.location.href = generate('baseData.overview');
                    });
                    return resp;
                })
                    .catch(e => {
                    toast.error({
                        message: translate('createProperty.save.error'),
                    });
                }) }), _jsx(Overlay, { target: target.current, show: isExceeded, placement: "top", children: (props) => (_jsx(Tooltip, { ...props, children: translate('createProperty.exceededLimitMessage', limits) })) })] });
};
const BoxRoot = styled.div `
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--fw-color-neutral-100, #D7D8DB);
  background: #FFF;

  & + & {
    margin-top: 40px;
  }
`;
const Box = ({ children, title = null }) => {
    return _jsxs(BoxRoot, { children: [title && _jsx(Text, { h6: true, as: 'h2', children: title }), _jsx("div", { children: children })] });
};
function useLimits(buildingsType) {
    const { limits, usages } = getGlobalContext();
    const total = limits?.nbRentalObjects ?? 300;
    const used = buildingsType.children.reduce((prev, v) => {
        const r = v.children.rentalObjects;
        return prev + r.manager.getActiveChildrenSize();
    }, usages?.nbRentalObjects ?? 0);
    return {
        used,
        total,
    };
}
