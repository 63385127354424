import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import DunningServices from 'Pages/Dunning/services/dunningServices';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/Translator';
import { FormModal } from '@f2w/form';
import { useCreateRemindersForm } from 'Pages/Dunning/hooks/useCreateRemindersForm';
import { DunningGroupedDataTable } from './table/DunningGroupedDataTable';
import { DebitorView } from "Pages/Debitor/components/DebitorView";
import * as DebitorServices from "Pages/Debitor/services/debitorServices";
import { PersonAccountStatementModal } from "Pages/Dunning/components/PersonAccountStatementModal";
import { usePersonAccountStatementReportsExportForm } from "./components/PersonAccountStatementReportsExportForm";
import { PersonModal } from "Pages/Person/components/PersonModal";
export const DunningPage = ({ dunningServices = DunningServices, debitorServices = DebitorServices, }) => {
    const personModalApi = useRef();
    const [person, setPerson] = useState();
    const [debitorInvoiceId, setDebitorInvoiceId] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const form = useCreateRemindersForm({
        onClose: () => instance.refresh(),
        selectedRows: selectedRows,
        createReminders: dunningServices.createReminders
    });
    const instance = DunningGroupedDataTable.use({
        listDunning: dunningServices.listDebitor,
        openPersonAccountStatementReportsExportModal: () => exportForm.openForm(),
        openPersonInfo: (row) => {
            personModalApi.current?.openModalWithData({
                id: row.personId,
                initials: row.initials,
                name: row.name,
                address: row.street ? {
                    street: row.street,
                    houseNumber: row.houseNumber,
                    city: row.city,
                    postalCode: row.zip
                } : undefined,
                dateOfBirth: row.dateOfBirth,
                emailAddress: row.emailAddress,
                phoneNumber: row.phoneNumber,
                secondEmailAddress: row.secondEmailAddress,
                secondPhoneNumber: row.secondPhoneNumber
            });
        },
        openPersonAccountStatement: (row) => {
            setPerson({
                id: row.personId,
                address: row.street ? {
                    street: row.street,
                    city: row.city,
                    postalCode: row.zip,
                    houseNumber: row.houseNumber
                } : undefined,
                dateOfBirth: row.dateOfBirth,
                name: row.name
            });
        },
        openDebitorView: setDebitorInvoiceId,
        openCreateRemindersForm: async (selectedRows) => {
            await setSelectedRows(selectedRows);
            await form.openForm();
        },
    });
    const getSelectedPersonIds = React.useCallback(() => {
        return [
            ...new Set(Object
                .values(instance.state.rowState)
                .map(selectedState => selectedState.personId)
                .filter(personId => personId !== null && personId !== undefined))
        ];
    }, [instance.state.rowState]);
    const exportForm = usePersonAccountStatementReportsExportForm({
        getPersonIds: getSelectedPersonIds,
        getPersonAccountStatementReports: dunningServices.getPersonAccountStatementReports
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { title: translate('dunning'), variant: 'content', instance: instance, showFilters: true }), _jsx(PersonModal, { api: personModalApi, onClose: () => instance.refresh() }), debitorInvoiceId && _jsx(DebitorView, { debitorInvoiceId: debitorInvoiceId, onClose: () => {
                    setDebitorInvoiceId(undefined);
                    instance.refresh();
                }, getDebitorInvoiceDetails: debitorServices.getDebitorInvoiceDetails, updateDebitorInvoiceGeneralData: debitorServices.updateDebitorInvoiceGeneralData }), _jsx(PersonAccountStatementModal, { person: person, onClose: () => setPerson(undefined), getPersonAccountStatement: dunningServices.getPersonAccountStatement, getPersonAccountStatementReport: dunningServices.getPersonAccountStatementReport }), _jsx(FormModal, { modal: form }), _jsx(FormModal, { modal: exportForm })] }));
};
