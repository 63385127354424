import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import DebitorTableModel from './DebitorTableModel';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { updateReminderState } from 'Services/DebitorInvoiceManager';
import { generateExportUrl } from 'Utils/generateExportUrl';
import { Badge, FlexRow, Icon } from 'Components/Atoms';
import { useModalDispatcherConfirm, useModalDispatcherConfirmWithChangingProps, useToastDispatcherApi, } from 'Components/Dispatcher';
import { deleteDebitorInvoice, remindDebitorInvoice } from 'Pages/Debitor/services/debitorServices';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { DateType, FormModal, useFormModal } from '@f2w/form';
import { DebitorView } from 'Pages/Debitor/components/DebitorView';
import { useManualPaymentForm } from 'Pages/Debitor/hooks/useManualPaymentForm';
import { useSplitPaymentForm } from 'Pages/Debitor/hooks/useSplitPaymentForm';
import { useInvoiceManualDebitorForm } from 'Pages/Debitor/hooks/useInvoiceManualDebitorForm';
import { DebitorType } from './components/DebitorView/forms/DebitorType';
import { PersonModal } from "Pages/Person/components/PersonModal";
function useDebitorsTable({ onNewDebitorClicked, onSendDebitorClicked, onEditDebitorClicked, openManualPaymentForm, openSplitPaymentForm, filters, debitorServices: { getNumberOfInvoicesWithPayments, bulkDeleteDebitorInvoices }, onBuildingNameClicked, onViewDebitorClicked, openPersonInfoModal }) {
    const tableModel = React.useMemo(() => DebitorTableModel.create({
        manualControl: true,
        initialState: {
            filters: filters ? Object.keys(filters).map(key => ({ id: key, value: filters[key] })) : []
        }
    }), []);
    const { success, error } = useToastDispatcherApi();
    const bulkDelete = React.useCallback((ids, refreshTable, toggleAllRowsSelected) => {
        bulkDeleteDebitorInvoices(ids)
            .then(() => {
            success({
                timeout: 2,
                message: translate('debitorInvoices.bulkDeletion.success', {
                    numberOfInvoices: ids.length
                })
            });
            toggleAllRowsSelected(false);
            refreshTable();
        })
            .catch(() => {
            error({
                timeout: 5,
                message: translate('debitorInvoices.bulkDeletion.error')
            });
        });
    }, []);
    const onExcludePaidConfirmation = useModalDispatcherConfirmWithChangingProps({
        icon: 'alert-triangle',
        onConfirm: bulkDelete,
        message: translate('debitorInvoices.message.areYouSure'),
        help: translate('debitorInvoices.message.bulkDeleteExcludePaid'),
        btnConfirm: translate('btn.confirm')
    });
    const validatePaidInvoices = React.useCallback((ids, refreshTable, toggleAllRowsSelected) => {
        getNumberOfInvoicesWithPayments(ids)
            .then(invoices => {
            if (invoices.length > 0) {
                const unpaidInvoiceIds = ids.filter(id => !invoices.includes(id));
                onExcludePaidConfirmation([unpaidInvoiceIds, refreshTable, toggleAllRowsSelected], {
                    help: translate('debitorInvoices.message.bulkDeleteExcludeWithPayments', {
                        numberOfPaidInvoices: invoices.length,
                        numberOfUnpaidInvoices: ids.filter(id => !invoices.includes(id)).length
                    })
                });
            }
            else {
                bulkDelete(ids, refreshTable, toggleAllRowsSelected);
            }
        });
    }, []);
    const onBulkDeleteConfirmation = useModalDispatcherConfirmWithChangingProps({
        icon: 'alert-triangle',
        onConfirm: validatePaidInvoices,
        message: translate('debitorInvoices.message.areYouSure'),
        help: translate('debitorInvoices.message.bulkDeleteConfirmation'),
        btnConfirm: translate('btn.confirm')
    });
    const getData = React.useCallback(async ({ request, }) => {
        const url = generateRoute('api.debitorInvoices.list');
        const response = await client.get(url, { params: request });
        return response.data;
    }, []);
    const onDeleteInvoice = React.useCallback((invoiceId, refreshTable) => {
        deleteDebitorInvoice(invoiceId)
            .then(() => {
            success({
                timeout: 2,
                message: translate('flash.debitorDeleted')
            });
            refreshTable();
        })
            .catch(({ response }) => {
            const errorMessage = (response.status === 400)
                ? response.data.message
                : translate('flash.unableToDeleteDebitorInvoice');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: onDeleteInvoice,
        message: translate('debitorInvoice.removePromptMessage'),
        btnConfirm: translate('btn.confirm')
    });
    const onRemindDebitorClicked = (invoiceId, refreshTable) => {
        remindDebitorInvoice(invoiceId)
            .then(() => {
            success({
                timeout: 2,
                message: translate('flash.debitorInvoice.reminder.sent.success')
            });
            refreshTable();
        })
            .catch(({ response }) => {
            const errorMessage = (response.status === 400)
                ? response.data.message
                : translate('flash.debitorInvoice.reminder.sent.failure');
            error({
                timeout: 5,
                message: errorMessage
            });
        });
    };
    return tableModel.useTable({
        getData,
        openPersonInfoModal,
        onNewDebitorClicked,
        onEditDebitorClicked,
        onSendDebitorClicked,
        openManualPaymentForm,
        openSplitPaymentForm,
        onDeleteDebitorClicked: onDeleteConfirmation,
        onRemindDebitorClicked: onRemindDebitorClicked,
        bulkDelete: onBulkDeleteConfirmation,
        updateReminderState,
        onBuildingNameClicked,
        onViewDebitorClicked
    });
}
const Content = ({ rentPeriod, onResetRentPeriod, personData, onResetPersonData, rent, onResetRent }) => _jsxs(FlexRow, { gap: 5, alignCenter: true, children: [_jsxs("label", { style: { marginBottom: 0 }, children: [translate('automaticInvoices.filters'), ":"] }), rentPeriod && _jsxs(Badge, { children: [moment(rentPeriod + "01").format('MMMM YYYY'), " ", _jsx(Icon, { style: { cursor: "pointer" }, onClick: () => onResetRentPeriod(), icon: 'x' })] }), personData && _jsxs(Badge, { children: [personData.name, " ", _jsx(Icon, { style: { cursor: "pointer" }, onClick: () => onResetPersonData(), icon: 'x' })] }), rent && _jsxs(Badge, { children: ["Mietzins ", DateType.format(rent.starts), " - ", rent.ends ? DateType.format(rent.ends) : translate('contract.ends.notLimited'), " ", _jsx(Icon, { style: { cursor: "pointer" }, onClick: () => onResetRent(), icon: 'x' })] })] });
const DebitorPage = ({ filters, debitorServices, openCreateForm = false, openDetailsForInvoice }) => {
    const [debitorInvoiceId, setDebitorInvoiceId] = useState(openDetailsForInvoice);
    const [rentPeriod, setRentPeriod] = useState(filters?.rentPeriod);
    const [personData, setPersonData] = useState(filters?.person);
    const [rent, setRent] = useState(filters.rent);
    const personModalApi = useRef();
    const debitorForm = useFormModal({
        name: 'debitor-form',
        title: ({ values }) => {
            return values?.id ? translate('debitor.edit') : translate('debitor.create');
        },
        type: () => new DebitorType.GeneralForm(),
        onSave: async (values) => {
            const createDto = {
                rentalObjectId: values.rentalObjectId,
                label: values.label,
                amount: Number(values.amount),
                vat: values.vat ? Number(values.vat) : undefined,
                positions: values.positions
                    .filter(position => !!position)
                    .map(position => ({
                    ...position,
                    amount: Number(position.amount)
                })),
                contractId: values.contractId,
                conditions: {
                    dueDate: values.dueDate,
                    receiptDate: values.receiptDate,
                    accountingDate: values.accountingDate,
                    recipientBankAccountId: values.recipientBankAccountId
                }
            };
            if (values.id) {
                return debitorServices
                    .updateDebitorInvoice(values.id, createDto)
                    .then(() => instance.refresh());
            }
            return debitorServices
                .createDebitorInvoice(createDto)
                .then(() => instance.refresh());
        },
        onLoad: async (id) => {
            if (!id) {
                return null;
            }
            const data = await debitorServices.getDebitorInvoiceById(id);
            return {
                ...data,
                ...data.conditions,
                id: id
            };
        },
    });
    const sendDebitorForm = useInvoiceManualDebitorForm({
        sendDebitor: debitorServices.sendDebitor,
        getSendDebitorData: debitorServices.getSendDebitorData,
        onSave: () => instance.refresh()
    });
    const manualPaymentForm = useManualPaymentForm(debitorServices.createManualPayment, debitorServices.loadPaymentData, () => instance.refresh());
    const splitPaymentForm = useSplitPaymentForm(debitorServices.createSplitPayment, debitorServices.loadPaymentData, () => instance.refresh());
    React.useEffect(() => {
        if (openCreateForm) {
            debitorForm.openForm();
        }
    }, [openCreateForm]);
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    React.useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            instance.refresh();
        }
    }, [activeView]);
    const tableFilters = {};
    if (rentPeriod) {
        tableFilters['rentPeriod'] = rentPeriod;
    }
    if (personData) {
        tableFilters['personId'] = personData.id;
    }
    if (rent) {
        tableFilters['rentId'] = rent.id;
    }
    const { openViewModal } = useObjectViewModalContext();
    const instance = useDebitorsTable({
        onNewDebitorClicked: () => debitorForm.openForm(),
        onSendDebitorClicked: (id) => sendDebitorForm.openForm(id),
        onEditDebitorClicked: (id) => debitorForm.openForm(id),
        openManualPaymentForm: (id) => manualPaymentForm.openForm(id),
        openSplitPaymentForm: (id) => splitPaymentForm.openForm(id),
        filters: tableFilters,
        debitorServices,
        onBuildingNameClicked: openViewModal(ActiveView.RENTAL_OBJECT),
        onViewDebitorClicked: setDebitorInvoiceId,
        openPersonInfoModal: (personId) => personModalApi.current?.openModal(personId),
    });
    const actionDropdown = useCallback(({ Link, instance, }) => {
        return (_jsx(_Fragment, { children: _jsx(Link, { icon: 'download', href: generateExportUrl('api.debitorInvoice.export', instance.state), children: translate('debitor.export.linkTitle') }) }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { inlineFilters: true, actionDropdown: actionDropdown, instance: instance, title: translate('debitors.title'), content: (rentPeriod || personData || rent) ? _jsx(Content, { rentPeriod: rentPeriod, personData: personData, rent: rent, onResetPersonData: () => {
                        instance.setFilter('personId', null);
                        setPersonData(null);
                    }, onResetRentPeriod: () => {
                        instance.setFilter('rentPeriod', null);
                        setRentPeriod(null);
                    }, onResetRent: () => {
                        instance.setFilter('rentId', null);
                        setRent(null);
                    } }) : null }), _jsx(FormModal, { modal: debitorForm }), _jsx(FormModal, { modal: sendDebitorForm }), _jsx(FormModal, { modal: manualPaymentForm }), _jsx(FormModal, { modal: splitPaymentForm }), _jsx(PersonModal, { api: personModalApi, onClose: () => instance.refresh() }), debitorInvoiceId && _jsx(DebitorView, { debitorInvoiceId: debitorInvoiceId, onClose: () => {
                    setDebitorInvoiceId(undefined);
                    instance.refresh();
                }, getDebitorInvoiceDetails: debitorServices.getDebitorInvoiceDetails, updateDebitorInvoiceGeneralData: debitorServices.updateDebitorInvoiceGeneralData })] }));
};
export default DebitorPage;
