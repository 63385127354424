import { forEachObject, inObject, mapObject, objectKeys, toStr } from '@f2w/utils';
export var KeyOrChild;
(function (KeyOrChild) {
    function key(childOrKey) {
        return inObject(childOrKey, 'key') ? childOrKey.key : toStr(childOrKey);
    }
    KeyOrChild.key = key;
})(KeyOrChild || (KeyOrChild = {}));
export class IndexDescriptor {
    name;
    globalConfig;
    config;
    _set = new Set();
    constructor(name, config, globalConfig) {
        this.name = name;
        this.globalConfig = globalConfig;
        this.config = {
            name,
            ...config,
        };
    }
    static create(key, config, globalConfig) {
        return new IndexDescriptor(key, config, globalConfig);
    }
    extend(config) {
        Object.assign(this.config, config(this.config));
        return this;
    }
    get size() {
        return this._set.size;
    }
    has(key) {
        return this._set.has(KeyOrChild.key(key));
    }
    values() {
        return [...this._set.values()];
    }
    get() {
        return this.config.getMetaValue(this._set);
    }
    add(key, ignoreUpdate) {
        key = KeyOrChild.key(key);
        if (this._set.has(key))
            return false;
        this._set.add(key);
        ignoreUpdate || this._handleUpdate([key], true);
        return true;
    }
    remove(key, ignoreUpdate) {
        key = KeyOrChild.key(key);
        if (!this._set.delete(key))
            return false;
        ignoreUpdate || this._handleUpdate([key], false);
        return true;
    }
    _update(child) {
        let add;
        if (this.config.resolveValue) {
            add = !!this.config.resolveValue(child);
        }
        else if (inObject(child['_state'].meta, this.name)) {
            add = !!child['_state'].meta[this.name];
        }
        return add == null ? false : this.update(child.key, add);
    }
    update(key, add, ignoreUpdate) {
        return (add ? this.add(key, ignoreUpdate) : this.remove(key, ignoreUpdate));
    }
    updateAll(keys, add, ignoreUpdate) {
        const updatedKeys = keys
            .map(k => KeyOrChild.key(k))
            .filter(k => this.update(k, add, true));
        if (updatedKeys.length && !ignoreUpdate)
            this._handleUpdate(updatedKeys, add);
        return updatedKeys.length > 0;
    }
    removeAll(keys, ignoreUpdate) {
        return this.updateAll(keys, false, ignoreUpdate);
    }
    toggle(key) {
        return this.update(key, !this.has(key));
    }
    clear(ignoreUpdate) {
        const updatedKeys = this.values();
        this._set.clear();
        if (!ignoreUpdate && updatedKeys.length)
            this._handleUpdate(updatedKeys, false);
        return updatedKeys.length > 0;
    }
    forEach(cb) {
        this._set.forEach(cb);
    }
    map(cb) {
        const items = [];
        this._set.forEach((key, index) => {
            const resolvedChild = cb(key, index);
            resolvedChild && items.push(resolvedChild);
        });
        return items;
    }
    _handleUpdate(keys, add) {
        const event = {
            propName: this.name,
            keys,
            add,
            index: this
        };
        if (add) {
            this.config.onAdd?.(keys, this);
            this.globalConfig?.onAdd?.(event);
        }
        else {
            this.config.onRemove?.(keys, this);
            this.globalConfig?.onRemove?.(event);
        }
        this.config.onChange?.(keys, add, this);
        this.globalConfig?.onChange?.(event);
    }
}
export class IndexManager {
    options;
    data = Object.create(null);
    constructor(options, config) {
        this.options = options;
        this.extend(config);
    }
    static create(config, options) {
        return new IndexManager(options ?? {}, config);
    }
    updateOptions(options) {
        Object.assign(this.options, options);
        return this;
    }
    create(key, config) {
        const data = this.data;
        if (!data[key])
            return data[key] = IndexDescriptor.create(key, config, this.options);
        return data[key].extend((prev) => ({ ...prev, ...config, }));
    }
    add(name, config) {
        this.create(name, config);
        return this;
    }
    remove(name) {
        delete this.data[name];
        return this;
    }
    extend(configMap) {
        forEachObject(configMap, (k, config) => {
            this.add(k, config);
        });
        return this;
    }
    forEach(cb) {
        Object.keys(this.data).forEach(key => {
            cb(this.data[key], key);
        });
    }
    map(cb) {
        return mapObject(this.data, (key, index) => {
            return cb(index, key);
        });
    }
    update(cb, keys) {
        const updatedKeys = (keys ?? objectKeys(this.data))
            .filter((k) => (this.data[k] && cb(this.data[k], k)));
        return updatedKeys.length > 0 ? updatedKeys : null;
    }
}
