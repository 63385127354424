import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { PersonShortInfoModal } from "Pages/Person/components/PersonShortInfoModal";
import { PersonView } from "Pages/Person/Details/components/PersonView";
import { ActiveView, useObjectViewModalContext } from "Components/ObjectViewModal";
import { useCallback, useEffect, useState } from "react";
import { PersonDetailsServices } from "Pages/Person/Details/services/personDetailsServices";
const ShortInfo = PersonShortInfoModal;
const View = PersonView;
export const PersonModal = ({ onClose, personServices = PersonDetailsServices, api }) => {
    const { openViewModalObj } = useObjectViewModalContext();
    const [personModalOpen, setPersonModalOpen] = useState();
    const [shortInfoPersonData, setShortInfoPersonData] = useState();
    const openModal = useCallback((id) => {
        setPersonModalOpen({
            id,
            isShortInfo: true
        });
    }, []);
    const closeModal = useCallback(() => {
        onClose();
        setPersonModalOpen(undefined);
    }, []);
    const openModalWithData = useCallback((data) => {
        setShortInfoPersonData(data);
        setPersonModalOpen({
            id: data.id,
            isShortInfo: true
        });
    }, []);
    useEffect(() => {
        api.current = {
            openModal,
            closeModal,
            openModalWithData
        };
    }, []);
    if (!personModalOpen) {
        return _jsx(_Fragment, {});
    }
    if (personModalOpen?.isShortInfo) {
        return (_jsx(ShortInfo, { id: personModalOpen?.id, data: shortInfoPersonData, openPersonDetails: () => setPersonModalOpen({
                ...personModalOpen,
                isShortInfo: false
            }), onClose: () => setPersonModalOpen(undefined) }));
    }
    return (_jsx(View, { updatePerson: personServices.updatePerson, getPersonById: personServices.getPersonById, onStreetClick: (id) => () => openViewModalObj({
            id,
            view: ActiveView.RENTAL_OBJECT
        }), createPersonAddress: personServices.createPersonAddress, updatePersonAddress: personServices.updatePersonAddress, onClose: () => {
            setPersonModalOpen(undefined);
            onClose();
        }, personId: personModalOpen.id }));
};
