import generateRoute from "Services/Router";
import client from "Utils/client";
export var PersonServices;
(function (PersonServices) {
    PersonServices.listPersons = (request) => {
        const url = generateRoute('api.persons.list');
        return client
            .get(url, { params: request })
            .then(res => res.data);
    };
    PersonServices.getPersonById = (id) => {
        const route = generateRoute('api.person', {
            person: id
        });
        return client
            .get(route)
            .then(response => response.data)
            .then((data) => {
            if (!data.corporatePerson) {
                data.corporatePerson = {
                    companyName: undefined,
                    vatId: undefined
                };
            }
            return data;
        });
    };
    PersonServices.createPerson = (createPersonDto) => {
        const route = generateRoute('api.persons.create');
        return client.post(route, createPersonDto);
    };
    PersonServices.updatePerson = (personId, updatePersonDto) => {
        const route = generateRoute('api.persons.update', {
            id: personId
        });
        return client.put(route, updatePersonDto);
    };
    PersonServices.deletePerson = (personId) => {
        const route = generateRoute('api.persons.delete', {
            person: personId
        });
        return client.delete(route);
    };
})(PersonServices || (PersonServices = {}));
export default PersonServices;
