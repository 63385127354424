import React from "react";
import { useObjectViewModal } from "Components/ObjectViewModal";
import { useBuildingView } from "Components/ObjectViewModal/hooks/useBuildingView";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const BuildingView = ({ buildingId, getBuildingDetails, updateBuilding }) => {
    const toastApi = useToastDispatcherApi();
    const { updateBuildingNameForBreadcrumb, activeView } = useObjectViewModal();
    const view = useBuildingView(getBuildingDetails, updateBuilding, updateBuildingNameForBreadcrumb, toastApi);
    React.useEffect(() => {
        view.load({ id: buildingId });
        if (activeView.asEdit) {
            view.openForm('generalLeft');
        }
    }, [buildingId]);
    return view.render();
};
