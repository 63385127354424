import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ModalNew } from 'Components/Modal';
import { Button, ButtonToolbar, FlexCol, FlexRow, Spinner, Tbody, Td, Text, Tfoot, Th, Thead, Tr, } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import DunningServices from 'Pages/Dunning/services/dunningServices';
import { DateType, MoneyType } from '@f2w/form';
import { Section } from 'Atoms/Layout/components';
import moment from 'moment';
import { DatePicker } from 'Atoms/DatePicker';
export const PersonAccountStatementModal = ({ person, onClose, getPersonAccountStatement = DunningServices.getPersonAccountStatement, getPersonAccountStatementReport = DunningServices.getPersonAccountStatementReport }) => {
    const [loading, setLoading] = React.useState(false);
    const [personAccountStatement, setPersonAccountStatement] = React.useState();
    const [dateRange, setDateRange] = React.useState({
        fromDate: moment().startOf('year').format('YYYY-MM-DD'),
        toDate: moment().endOf('year').format('YYYY-MM-DD'),
    });
    React.useEffect(() => {
        if (person) {
            setLoading(true);
            getPersonAccountStatement(person.id, dateRange.fromDate, dateRange.toDate)
                .then(res => {
                setLoading(false);
                setPersonAccountStatement(res);
            });
        }
        else {
            setPersonAccountStatement(undefined);
            setDateRange({
                fromDate: moment().startOf('year').format('YYYY-MM-DD'),
                toDate: moment().endOf('year').format('YYYY-MM-DD'),
            });
        }
    }, [person?.id, dateRange.fromDate, dateRange.toDate]);
    if (!person) {
        return _jsx(_Fragment, {});
    }
    if (!personAccountStatement) {
        return (_jsxs(ModalNew, { show: true, size: { maxWidth: '80vw', height: '80vh' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsxs(ModalNew.Title, { children: [translate('dunning.accountStatement'), " | ", person.name] }) }), _jsx(ModalNew.Body, { children: _jsx(FlexRow, { style: { height: '100%' }, justifyCenter: true, alignCenter: true, children: _jsx(Spinner, {}) }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { variant: 'outline', color: 'neutral', onClick: onClose, children: translate('btn.close') }), _jsx(Button, { icon: 'pdfFileNoColor', onClick: () => getPersonAccountStatementReport(person.id), children: translate('dunning.btn.export') })] }) })] }));
    }
    const { accountingRecords, contracts, debitTotal, creditTotal, balanceTotal } = personAccountStatement;
    const contractTable = (_jsxs(Section.Table, { stickyHead: 0, colSpan: 4, variant: 'content', size: 'sm', children: [_jsxs(Thead, { children: [_jsx(Th, { style: { fontWeight: 'bold' }, children: translate('property') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('rentalObject') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('contract.starts') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('contract.ends') })] }), _jsx(Tbody, { children: contracts.map(contract => (_jsxs(Tr, { children: [_jsx(Td, { children: contract.propertyName }), _jsx(Td, { children: contract.rentalObjectName }), _jsx(Td, { children: DateType.format(contract.contractStart) }), _jsx(Td, { children: DateType.format(contract.contractEnd) !== ''
                                ? DateType.format(contract.contractEnd)
                                : translate('contract.details.unlimited') })] }, contract.id))) })] }));
    const accountingRecordTable = (_jsxs(Section.Table, { stickyHead: 0, colSpan: 6, variant: 'content', size: 'sm', isLoading: loading, children: [_jsxs(Thead, { children: [_jsx(Th, { style: { fontWeight: 'bold' }, children: translate('date') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('dunning.counterAccountPosition') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('accountingRecord.label') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('dunning.accountingRecord.debit') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('dunning.accountingRecord.credit') }), _jsx(Th, { style: { fontWeight: 'bold' }, children: translate('dunning.accountingRecord.balance') })] }), _jsx(Tbody, { children: accountingRecords.map(accountingRecord => (_jsxs(Tr, { children: [_jsx(Td, { children: DateType.format(accountingRecord.date) }), _jsx(Td, { children: accountingRecord.counterAccountPosition }), _jsx(Td, { children: accountingRecord.label }), _jsx(Td, { children: accountingRecord.debitAmount !== null ? MoneyType.format(accountingRecord.debitAmount) : '' }), _jsx(Td, { children: accountingRecord.creditAmount !== null ? MoneyType.format(accountingRecord.creditAmount) : '' }), _jsx(Td, { children: MoneyType.format(accountingRecord.balance) })] }, accountingRecord.id))) }), _jsx(Tfoot, { children: _jsxs(Tr, { children: [_jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, colSpan: 2 }), _jsxs(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold', textAlign: 'end' }, children: [translate('total'), ":"] }), _jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, children: MoneyType.format(debitTotal) }), _jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, children: MoneyType.format(creditTotal) }), _jsx(Td, { style: { backgroundColor: '#F4F4F4', fontWeight: 'bold' }, children: MoneyType.format(balanceTotal) })] }) })] }));
    return (_jsxs(ModalNew, { show: true, size: { maxWidth: '80vw', maxHeight: '80vh' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsxs(ModalNew.Title, { children: [translate('dunning.accountStatement'), " | ", person.name] }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexCol, { gap: 24, children: [_jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsxs(FlexCol, { gap: 5, children: [_jsxs(FlexRow, { gap: 8, children: [_jsx(Text, { children: _jsxs("strong", { children: [translate('dunning.accountStatement.tenantName'), ":"] }) }), _jsx(Text, { children: person.name })] }), _jsxs(FlexRow, { gap: 8, children: [_jsx(Text, { children: _jsxs("strong", { children: [translate('dunning.accountStatement.currentAddress'), ":"] }) }), _jsxs(Text, { children: [person.address?.street, " ", person.address?.houseNumber, ", ", person.address?.postalCode, " ", person.address?.city] })] }), _jsxs(FlexRow, { gap: 8, children: [_jsx(Text, { children: _jsxs("strong", { children: [translate('dunning.accountStatement.birthDate'), ":"] }) }), _jsx(Text, { children: DateType.useFormat(person.dateOfBirth) ?? translate('notAvailable') })] })] }), _jsxs(FlexRow, { gap: 10, children: [_jsxs(FlexCol, { children: [_jsx(Text, { children: translate('fromDate') }), _jsx(DatePicker, { value: dateRange.fromDate, disabled: loading, isClearable: true, onChange: (date) => {
                                                        setDateRange((prev) => ({
                                                            ...prev,
                                                            fromDate: date ? moment(date).format('YYYY-MM-DD') : undefined
                                                        }));
                                                    }, maxDate: dateRange.toDate })] }), _jsxs(FlexCol, { children: [_jsx(Text, { children: translate('untilDate') }), _jsx(DatePicker, { value: dateRange.toDate, disabled: loading, isClearable: true, onChange: (date) => {
                                                        setDateRange((prev) => ({
                                                            ...prev,
                                                            toDate: date ? moment(date).format('YYYY-MM-DD') : undefined
                                                        }));
                                                    }, minDate: dateRange.fromDate })] })] })] }), _jsxs(FlexCol, { gap: 5, children: [_jsx(Text, { body: 500, children: translate('dunning.contracts') }), contractTable] }), _jsxs(FlexCol, { gap: 5, children: [_jsx(Text, { body: 500, children: translate('dunning.accountingRecords') }), accountingRecordTable] })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { disabled: loading, variant: 'outline', color: 'neutral', onClick: onClose, children: translate('btn.close') }), _jsx(Button, { disabled: loading, icon: 'pdfFileNoColor', onClick: () => getPersonAccountStatementReport(person.id, dateRange.fromDate, dateRange.toDate), children: translate('btn.download') })] }) })] }));
};
