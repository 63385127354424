import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { SendDebitorType } from "Pages/Debitor/forms/SendDebitorType";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
export const useInvoiceManualDebitorForm = ({ getSendDebitorData, sendDebitor, onSave }) => useFormModal({
    name: 'send-debitor-form',
    title: translate('debitorInvoice.invoice'),
    type: () => new SendDebitorType(),
    onLoad: async (id) => ({ ...(await getSendDebitorData(id)), id }),
    onSave: (values) => {
        return sendDebitor(values.id, values)
            .then(onSave);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.save') })] });
    },
});
