import { usePropertyView } from "Components/ObjectViewModal/hooks/usePropertyView";
import { useObjectViewModal } from "Components/ObjectViewModal";
import React from "react";
import { QuickschildIntegrationApi } from "Pages/Quickschild/services/QuickschildIntegrationService";
export const PropertyView = ({ propertyId, getPropertyDetails, updateProperty }) => {
    const { updatePropertyNameForBreadcrumb, activeView } = useObjectViewModal();
    const view = usePropertyView(getPropertyDetails, updateProperty, updatePropertyNameForBreadcrumb, QuickschildIntegrationApi.getIntegration);
    React.useEffect(() => {
        view.load({ id: propertyId });
        if (activeView.asEdit) {
            view.openForm('generalLeft');
        }
    }, [propertyId]);
    return view.render();
};
