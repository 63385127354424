import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, AddressType, DateType, EmailType, GenderType, NumberType, PhoneType, SelectType, StringType, TextType, UrlType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import React from 'react';
import { PersonType as PersonTypeEnum } from '@fw/enums/PersonType';
import { Col, Row } from 'Components/Atoms';
import BankAccountServices from 'Pages/BankAccount/services/bankAccountServices';
export class PersonType extends AbstractFormType {
    buildFields() {
        return {
            profile: new PersonType.General({
                inheritData: true
            }),
            pastAddress: new PersonType.AddressHistory({
                inheritData: false,
                required: true
            })
        };
    }
}
(function (PersonType) {
    class General extends AbstractFormType {
        static CORPORATE_PERSON = PersonTypeEnum.CORPORATE_PERSON;
        static NATURAL_PERSON = PersonTypeEnum.NATURAL_PERSON;
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: false,
            };
        }
        bankAccounts = [];
        _useField(props) {
            super._useField(props);
            const [bankAccounts, setBankAccounts] = React.useState([]);
            this.bankAccounts = bankAccounts;
            React.useEffect(() => {
                BankAccountServices.getTenantBankAccountsNotAssignedToPersonApiAction(this.field.value.id ?? null)
                    .then((bankAccounts) => {
                    setBankAccounts(bankAccounts.map(bankAccount => ({
                        label: bankAccount.name,
                        value: bankAccount.id
                    })));
                });
            }, []);
        }
        buildFields() {
            return {
                personType: new SelectType({
                    label: translate('person.type'),
                    defaultValue: PersonTypeEnum.NATURAL_PERSON,
                    choices: {
                        [PersonTypeEnum.NATURAL_PERSON]: translate('person.type.natural'),
                        [PersonTypeEnum.CORPORATE_PERSON]: translate('person.type.corporate'),
                    },
                }),
                naturalPerson: new Natural({
                    label: false,
                    isVisible: () => {
                        return this.field.value.personType == PersonTypeEnum.NATURAL_PERSON;
                    },
                }),
                corporatePerson: new Corporate({
                    label: false,
                    isVisible: () => {
                        return this.field.value.personType == PersonTypeEnum.CORPORATE_PERSON;
                    },
                }),
                address: new AddressHistory({
                    inheritView: true,
                    label: translate('person.address'),
                    isVisible: () => this.value.id === undefined
                }),
                emailAddress: new EmailType({
                    label: translate('person.emailAddress'),
                    required: false,
                    constraints: {
                        max: 255,
                    },
                }),
                secondEmailAddress: new EmailType({
                    label: translate('person.secondEmailAddress'),
                    required: false,
                    constraints: {
                        max: 255,
                    },
                }),
                phoneNumber: new PhoneType({
                    label: translate('person.phoneNumber'),
                    required: false,
                }),
                secondPhoneNumber: new PhoneType({
                    label: translate('person.secondPhoneNumber'),
                    required: false,
                }),
                website: new UrlType({
                    label: translate('person.website'),
                    required: false
                }),
                notes: new TextType({
                    label: translate('baseData.rentalObject.notes'),
                    required: false,
                    nullable: false,
                }),
                bankAccountId: new SelectType({
                    label: translate('person.details.bankAccount'),
                    required: false,
                    choiceOptions: () => this.bankAccounts,
                })
            };
        }
    }
    PersonType.General = General;
    class Corporate extends AbstractFormType {
        buildFields() {
            return {
                companyName: new StringType({
                    label: translate('person.corporate.companyName'),
                    required: true,
                    constraints: {
                        max: 255,
                    },
                }),
                companyName2: new StringType({
                    label: translate('customer.contact.companyName2'),
                    required: false,
                    defaultValue: '',
                }),
                vatId: new StringType({
                    label: translate('person.corporate.vatId'),
                    required: false,
                    constraints: {
                        max: 255,
                    },
                }),
            };
        }
    }
    PersonType.Corporate = Corporate;
    class Natural extends AbstractFormType {
        buildFields() {
            return {
                gender: new GenderType({
                    required: true,
                }),
                firstName: new StringType({
                    label: translate('person.firstName'),
                    required: true,
                    constraints: {
                        max: 255,
                    },
                }),
                lastName: new StringType({
                    label: translate('person.lastName'),
                    required: true,
                    constraints: {
                        max: 255,
                    },
                }),
                maritalStatus: new SelectType({
                    label: translate('person.maritalStatus'),
                    required: false,
                    choices: {
                        'naturalPerson.maritalStatus.single': translate('SINGLE'),
                        'naturalPerson.maritalStatus.married': translate('MARRIED'),
                        'naturalPerson.maritalStatus.divorced': translate('DIVORCED'),
                        'naturalPerson.maritalStatus.widowed': translate('WIDOWED'),
                    },
                }),
                placeOfOrigin: new StringType({
                    label: translate('person.placeOfOrigin'),
                    required: false,
                    constraints: {
                        max: 255,
                    },
                }),
                birthday: new DateType({
                    label: translate('person.dateOfBirth'),
                    required: false,
                    constraints: {
                        typeError: translate('form.validation.notValidDate')
                    }
                }),
            };
        }
    }
    PersonType.Natural = Natural;
    class AddressHistory extends AddressType {
        __getDefaultOptions() {
            return {
                ...(super.__getDefaultOptions()),
                label: false,
                zipKeyName: 'postalCode'
            };
        }
        buildFields() {
            const fields = super.buildFields();
            return {
                id: new NumberType({
                    isVisible: () => false,
                    required: false
                }),
                ...fields,
                effectiveFrom: new DateType({
                    label: translate('person.address_effective_from'),
                }),
            };
        }
        _renderWidget() {
            return _jsxs(_Fragment, { children: [super._renderWidget(), _jsx(Row, { children: _jsx(Col, { sm: 12, children: this.renderChild('effectiveFrom') }) })] });
        }
    }
    PersonType.AddressHistory = AddressHistory;
})(PersonType || (PersonType = {}));
