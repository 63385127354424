import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { BaseTheme } from '../base';
import { slotCss } from '@f2w/theme';
export function ObjectField({ field, Label: labelProps, Error: errorProps, Widget, children, ...props }) {
    const commonProps = { field };
    const widget = field.options.widget;
    return (_jsxs(ObjectField.Row, { id: `${field.id}--row`, "data-widget": widget, ...commonProps, ...props, children: [_jsx(ObjectField.Label, { ...commonProps, ...labelProps }), children, _jsx(ObjectField.Error, { ...commonProps, ...errorProps })] }));
}
(function (ObjectField) {
    ObjectField.Label = styled(BaseTheme.Label) `
        ${slotCss('label', 'content')} {
            ${p => p.theme.typography.h6.fontSizeStyles};
        }
        margin: 1em 0 .75em;
    `;
    ObjectField.Error = styled(BaseTheme.Error) ``;
    ObjectField.Row = styled(BaseTheme.Row) `
        &[data-widget="box"] {
            border: 1px solid #999;
            padding: 20px;
        }
    `;
})(ObjectField || (ObjectField = {}));
