import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { translate } from 'Services/Translator';
import { Button } from 'Atoms/Button';
import { orFunction } from 'Utils/types';
export function handleError(error, cb) {
    if (error?.isFormError) {
        return cb.onFormError?.(error);
    }
    if (axios.isAxiosError(error) || error?.response?.status) {
        return cb.onResponseError?.(error.response, error);
    }
    window.console?.error(error);
    return cb.onError?.(error);
}
export function handleResponseError(error, { dispatcher, onError, onFormError, statusMessages, ...props }) {
    handleError(error, {
        onResponseError: (res, err) => {
            let message;
            switch (res?.status) {
                case 401:
                    dispatcher?.error?.({
                        id: 'action-account-logout',
                        message: _jsxs(_Fragment, { children: [_jsx("p", { children: orFunction(statusMessages?.[401], res) ?? translate('client.response.error.401') }), _jsx(Button, { fill: true, color: 'dark', label: translate('account.toLogin'), target: '_blank', href: res.data.loginUrl, onClick: (e) => {
                                        dispatcher?.remove?.('action-account-logout');
                                    } })] }),
                        showRemove: true,
                        timeout: 0,
                    });
                    break;
                case 403:
                    message = orFunction(statusMessages?.[403], res) ?? translate('client.response.error.403');
                    break;
                default:
                    message = orFunction(statusMessages?.[res?.status], res) ?? props?.defaultMessage;
                    break;
            }
            message && dispatcher?.error?.({ message });
        },
        onFormError,
        onError,
    });
}
