import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useWizardContext } from './index';
export var WizardTemplate;
(function (WizardTemplate) {
    function render(name, props) {
        const context = {
            ...props,
            ...useWizardContext()
        };
        const Template = context.config?.layout?.[name] ?? BaseTemplate;
        return _jsx(Template, { ...context });
    }
    WizardTemplate.render = render;
    const BaseTemplate = ({ children }) => {
        return _jsx(_Fragment, { children: children });
    };
})(WizardTemplate || (WizardTemplate = {}));
