import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { StyledButton } from 'Atoms/Button/StyledButton';
export function CollectionList({ field, ...props }) {
    return _jsx(CollectionList.Root, { ...props, children: field.map(row => {
            const status = row.meta.touched ? (row.meta.hasError ? 'error' : 'success') : undefined;
            return _jsx(CollectionList.Item, { status: status, children: row.render({}) }, row.id);
        }) });
}
(function (CollectionList) {
    CollectionList.Actions = styled.div `
      display: flex;
    `;
    CollectionList.Root = styled.div `
      //&& > * {
      //  transition: opacity ease-in 300ms;
      //}
      &&:hover > * {
        opacity: .4;

        &:not(:hover) {
          --trans-duration: 250ms;
          --trans-delay: 100ms;
          --trans-timing-function: ease-out;
          opacity: .45;
        }

        &:hover {
          opacity: 1;
        }
      }
    `;
    CollectionList.Item = styled.div `
      --trans-duration: 300ms;
      //--trans-delay: 250ms;
      --trans-timing-function: ease-in;
      --opacity: 0;

      &:hover {
        --trans-delay: 0;
        --trans-duration: 300ms, 300ms, 300ms, 180ms, 180ms, 180ms, 180ms;
        --trans-timing-function: ease-out, ease-out, ease-out, ease-in, ease-in, ease-in, ease-in;
        --position-offset: -10px;
        --opacity: .9;

        ${CollectionList.Actions} {
          visibility: visible;
          opacity: 1;
        }
      }

      ${p => p.status ? css `
        --trans-duration: 200ms;
        --trans-delay: 50ms;
        --trans-timing-function: ease-out;
        --position-offset: -8px;
        --background-color: ${p.theme.palette[p.status || 'primary'].$50}22;
        --border-color: ${p.theme.palette[p.status || 'primary'].$400};

      ` : css `

        --trans-delay: 300ms, 300ms, 300ms, 300ms, 300ms, 300ms, 300ms;
        --position-offset: -2px;
        --background-color: transparent;
        --border-color: transparent;

        &:hover {
          --background-color: ${p.theme.palette.primary.$50}22;
          --border-color: ${p.theme.palette.primary.$100};
        }
      `};

      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      position: relative;
      margin: 20px 0;

      //--trans-property: background-color, opacity, left, right, bottom, top;
      transition-property: background-color, opacity, border-color, left, right, bottom, top, box-shadow;
      transition-duration: var(--trans-duration);
      transition-delay: var(--trans-delay);
      transition-timing-function: var(--trans-timing-function);

      ${CollectionList.Actions} {
        transition: inherit;
        position: absolute;
        z-index: 10;
        //top: calc(100% - 20px);
        top: -30px;
        align-self: center;
        align-items: flex-start;
        margin: 0 auto;
        padding: 6px;

        opacity: var(--opacity);
        //visibility: hidden;
        background: #ffffff;
        border: 0px solid var(--border-color);
        border-radius: 8px;
        gap: 8px;
        //box-shadow: 0 0 0 1px var(--border-color);
        ${StyledButton} {
          padding: 4px;
          ${p => p.theme.typography.small.fontStyles};
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: var(--position-offset);
        top: var(--position-offset);
        right: var(--position-offset);
        bottom: var(--position-offset);
        width: auto;
        height: auto;

        transition: inherit;

        opacity: var(--opacity);
        background-color: var(--background-color);
        border: 0px var(--border-color) solid;
        border-radius: 8px;
        box-shadow: 0 0 0 3px var(--border-color);
      }
    `;
})(CollectionList || (CollectionList = {}));
