import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { ButtonToolbar } from '../ButtonToolbar/ButtonToolbar';
import { BaseButton, ButtonTheme } from './BaseButton';
export * from './BaseButton';
export * from './UnstyledButton';
export const Button = Object.assign(Object.assign(BaseButton, {
    ButtonTheme,
    Base: BaseButton,
    Group: ButtonGroup,
    Toolbar: ButtonToolbar,
}), btnTypes({
    Edit: (props) => _jsx(BaseButton, { icon: 'edit', variant: 'outline', label: translate('btn.edit'), ...props }),
    Action: (props) => _jsx(BaseButton, { variant: 'outline', ...props }),
    New: (props) => _jsx(BaseButton, { icon: 'plus', variant: 'outline', label: translate('btn.new'), ...props }),
    Cancel: (props) => _jsx(BaseButton, { variant: 'linkFill', color: 'error', label: translate('btn.cancel'), ...props }),
    Save: (props) => _jsx(BaseButton, { label: translate('btn.save'), ...props }),
    Delete: (props) => _jsx(BaseButton, { variant: 'link', icon: 'trash', color: 'error', label: translate('btn.delete'), confirm: translate('confirm.message.delete'), ...props }),
}));
function btnTypes(components) {
    Object.values(components).forEach((c) => {
        c.displayName = 'Button';
    });
    return components;
}
