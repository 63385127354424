import { AbstractFormType, DateType, MoneyType, SelectType, TextType } from "@f2w/form";
import { translateAdmin } from "Services/App";
import { useEffect, useMemo, useRef, useState } from "react";
import { CustomerSubscriptionTypeServices } from "Pages/Administration/Customers/services";
import moment from "moment";
import { SubscriptionCreation } from "Pages/Administration/Customers/enums";
export class UpsertSubscriptionType extends AbstractFormType {
    plans = [];
    _useField(props) {
        super._useField(props);
        const initialLoad = useRef(true);
        const [planMap, setPlanMap] = useState(new Map());
        this.plans = useMemo(() => {
            return Array.from(planMap.values()).map(plan => {
                let disabled = false;
                switch (this.field.value.creationType) {
                    case SubscriptionCreation.UPGRADE: {
                        disabled = plan.order <= this.field.value.fromSubscriptionOrder;
                        break;
                    }
                    case SubscriptionCreation.DOWNGRADE: {
                        disabled = plan.order >= this.field.value.fromSubscriptionOrder;
                        break;
                    }
                }
                return {
                    label: `${plan.name} (${MoneyType.format(plan.price)})`,
                    value: plan.id,
                    isDisabled: disabled
                };
            });
        }, [planMap.size]);
        useEffect(() => {
            CustomerSubscriptionTypeServices.getAllActiveSubscriptionPlans()
                .then(data => {
                const planMap = new Map();
                data.forEach(plan => planMap.set(plan.id, plan));
                setPlanMap(planMap);
            });
        }, []);
        const { typeId, oneTimeDiscount, start } = this.field.value;
        useEffect(() => {
            this.children.invoicedPrice.field.setValue((planMap.get(typeId)?.price ?? 0.00) - (oneTimeDiscount ?? 0.00), false);
        }, [typeId, oneTimeDiscount, planMap.size]);
        useEffect(() => {
            if (initialLoad.current) {
                initialLoad.current = false;
                return;
            }
            if (this.field.value.id) {
                return;
            }
            const plan = planMap.get(typeId);
            this.children.end.field.setValue(moment(start)
                .add(plan.defaultLifetime, 'months')
                .add(-1, 'day')
                .endOf('month')
                .format('YYYY-MM-DD'));
            if (this.children.notes.field.touched) {
                return;
            }
            switch (this.field.value.creationType) {
                case SubscriptionCreation.DOWNGRADE: {
                    this.children.notes.field.setValue(translateAdmin('customer.subscription.downgrade.notes', {
                        fromSubscriptionName: this.field.value.fromSubscriptionName,
                        toSubscriptionName: plan.name
                    }), false, true);
                    break;
                }
                case SubscriptionCreation.UPGRADE: {
                    this.children.notes.field.setValue(translateAdmin('customer.subscription.upgrade.notes', {
                        fromSubscriptionName: this.field.value.fromSubscriptionName,
                        toSubscriptionName: plan.name
                    }), false, true);
                    break;
                }
            }
        }, [typeId]);
    }
    buildFields() {
        return {
            typeId: new SelectType({
                label: translateAdmin('customer.subscription'),
                choiceOptions: () => this.plans,
            }),
            start: new DateType({
                label: translateAdmin('customer.subscription.start'),
                constraints: {
                    test: ['is-before-end', translateAdmin('customer.subscription.dateRangeError'), (currentValue) => {
                            return this.field.value.end
                                ? moment(currentValue).isSameOrBefore(moment(this.field.value.end))
                                : true;
                        }]
                }
            }),
            end: new DateType({
                label: translateAdmin('customer.subscription.end'),
                constraints: {
                    test: ['is-after-start', translateAdmin('customer.subscription.dateRangeError'), (currentValue) => {
                            return this.field.value.start
                                ? moment(currentValue).isSameOrAfter(moment(this.field.value.start))
                                : true;
                        }]
                }
            }),
            oneTimeDiscount: new MoneyType({
                label: translateAdmin('customer.subscription.oneTimeDiscount'),
                defaultValue: 0.00,
                constraints: {
                    min: [0, translateAdmin('form.validation.positiveValue')],
                }
            }),
            invoicedPrice: new MoneyType({
                label: translateAdmin('customer.subscription.invoicedPrice'),
                required: false,
                defaultValue: 0.00,
                disabled: true
            }),
            notes: new TextType({
                label: translateAdmin('customer.subscription.notes'),
                required: false,
            })
        };
    }
}
