import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { ExportCreditorDocumentsType } from "../forms/ExportCreditorDocumentsType";
import { translate } from "Services/App";
import { Button, ButtonToolbar } from "Components/Atoms";
export const useExportCreditorDocuments = ({ portfolio, exportAttachments }) => useFormModal({
    name: 'export-creditor-documents-form',
    type: () => new ExportCreditorDocumentsType({
        portfolio
    }),
    title: translate('customerDocuments.export.title'),
    successMessage: translate('customerDocuments.exportSuccess.body'),
    onLoad: async (data) => {
        return {
            ...data
        };
    },
    onSave: async (values) => {
        return exportAttachments({
            dateRange: !values.dateRange?.start && !values.dateRange?.end
                ? null
                : values.dateRange,
            propertyId: values.propertyId
        });
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(ButtonToolbar, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: async () => await saveForm(true), children: translate('btn.export') })] });
    },
});
