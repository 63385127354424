import React from 'react';
import { FinancialAccountGroupTableModel, } from 'Pages/Accounting/AccountingRecord/models/FinancialAccountGroupTableModel';
export const useFinancialAccountGroupTable = ({ accountGroup, dateFilter, fiscalYear, openFinancialAccountStatement }) => {
    const startDate = dateFilter?.from || fiscalYear.starts;
    const getData = React.useCallback(({ filters }) => {
        let results = accountGroup.financialAccounts;
        let filterEmptyAccounts = filters.find(({ id }) => id === 'isEmpty');
        if (filterEmptyAccounts) {
            results = results.filter(({ used }) => used);
        }
        return new Promise(resolve => {
            resolve({ results });
        });
    }, [accountGroup]);
    const instance = FinancialAccountGroupTableModel.useTable({
        getData,
    }, {
        manualControl: true,
        accountGroup,
        fiscalYear,
        startDate,
        openFinancialAccountStatement
    });
    return {
        instance
    };
};
