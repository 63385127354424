import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { SelectType, StringType } from "@f2w/form";
import { translate } from "Services/App";
import { ReAssignCustomerDocumentType } from "Pages/CustomerDocuments/types/ReAssignCustomerDocumentType";
import { Body, Content, Container } from "Atoms/Layout/components";
import { MediaPreview } from "Atoms/Media";
import { categoryOptions } from "Pages/CustomerDocuments/utils";
export class EditCustomerDocumentType extends ReAssignCustomerDocumentType {
    buildFields() {
        const { propertyId, buildingId, rentalObjectId, contractId } = super.buildFields();
        return {
            fileName: new StringType({
                label: translate('documentsManager.documents.name'),
            }),
            category: new SelectType({
                label: translate('documentsManager.documents.category'),
                required: false,
                searchable: true,
                choiceOptions: () => categoryOptions
            }),
            propertyId,
            buildingId,
            rentalObjectId,
            contractId,
            fileSize: new StringType({
                label: translate('documentsManager.documents.size'),
                disabled: true
            })
        };
    }
    _renderWidget(_iProps) {
        const initialValue = this.field.initialValue;
        if (!initialValue.fileType.includes('image/') && !initialValue.fileType.includes('application/pdf')) {
            return (_jsxs(_Fragment, { children: [this.renderChild('fileName'), this.renderChild('category'), this.renderChild('propertyId'), this.renderChild('buildingId'), this.renderChild('rentalObjectId'), this.renderChild('contractId'), this.renderChild('fileSize')] }));
        }
        return (_jsxs(Body, { split: true, style: { height: '100%' }, children: [_jsxs(Container, { style: { width: '500px', padding: '24px' }, children: [this.renderChild('fileName'), this.renderChild('category'), this.renderChild('propertyId'), this.renderChild('buildingId'), this.renderChild('rentalObjectId'), this.renderChild('contractId'), this.renderChild('fileSize')] }), _jsx(Container, { style: { padding: initialValue.fileType.includes('application/pdf') ? 0 : '40px', background: '#D7D8DB' }, children: _jsx(Content, { style: { justifyContent: 'center', alignItems: 'center' }, children: _jsx(MediaPreview, { type: initialValue.fileType, name: initialValue.fileName, path: initialValue.filePath }) }) })] }));
    }
}
