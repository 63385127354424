import { createWizardType } from '@f2w/view';
import Portfolio from 'Models/Portfolio';
import contractServices from 'Pages/Contract/services/contractServices';
import { translate } from 'Services/App';
import { generate } from 'Services/Router';
import { ContractModificationWizardType } from '../types/ContractModificationWizardType';
import moment from 'moment';
import rentServices from "Pages/Rent/services";
export const useContractModificationWizard = () => {
    const loadData = async (id) => {
        const _loadData = async () => {
            const contracts = await contractServices.getModifiableContracts();
            const { date } = await rentServices.getLastAvailableReferenceInterestRateDate();
            return {
                contracts,
                referenceInterestRateDate: date
            };
        };
        const { contracts, referenceInterestRateDate } = { ...(await _loadData()) };
        let selectedData = {};
        if (id) {
            const portfolio = Portfolio.instance();
            const contract = contracts.find(c => c.id === id);
            const building = portfolio.getBuildingByRentalObjectId(contract.rentalObjectId);
            selectedData = {
                propertyId: building.propertyId,
                buildingId: building.id,
                rentalObjectId: contract.rentalObjectId,
                contractId: id,
                validFrom: moment(contracts.find(c => c.id === id)?.possibleSuggestedDate).toDate()
            };
        }
        const referenceInterestRateDateMoment = moment(referenceInterestRateDate);
        const today = moment();
        return {
            selectionStep: {
                settings: {
                    contracts,
                    referenceInterestRateDate
                },
                ...selectedData,
            },
            rentModificationStep: {
                rentPotentialDate: referenceInterestRateDateMoment.isBefore(today)
                    ? referenceInterestRateDateMoment.toDate()
                    : today.toDate()
            }
        };
    };
    const save = async (data) => {
        const saveData = {
            validFrom: data.selectionStep.validFrom,
            adjustmentAmount: data.rentModificationStep.adjustmentAmount,
            hasSubsidies: data.rentModificationStep.hasSubsidies,
            reason: data.rentModificationStep.reason,
            reservation: data.rentModificationStep.reservation,
            rentPotentialDate: moment(data.rentModificationStep.rentPotentialDate, "MM.YYYY").format('YYYY-MM-DD'),
            roundingMode: data.rentModificationStep.roundDifference,
        };
        return contractServices
            .createContractModification(data.selectionStep.contractId, saveData)
            .then(() => window.location.href = generate('contracts.overview'));
    };
    const c = createWizardType({
        id: 'contractModification',
        path: 'contract-modification/:id',
        onClose: ({ navigate }) => {
            window.location.href = generate('contracts.overview');
        },
        title: () => 'Contract modification wizard',
        load: async ({ params }) => {
            const { id } = params;
            const stepData = await loadData(id ? Number(id) : undefined);
            return {
                id,
                stepData: stepData
            };
        },
        save: async (values) => {
            return save(values);
        },
        steps: {
            selectionStep: {
                title: () => translate('contract.selection'),
                type: () => new ContractModificationWizardType.SelectionStep({ hideLabel: true }),
            },
            rentModificationStep: {
                title: () => translate('rentModification.title'),
                type: () => new ContractModificationWizardType.RentModificationStep({ hideLabel: true }),
            },
            summaryStep: {
                title: () => translate('rentModification.summary.title'),
                type: () => new ContractModificationWizardType.SummaryStep({ hideLabel: true }),
            },
        },
    })({});
    return c;
};
