import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import { Button, ButtonToolbar, Div, Dropdown, FlexCol, FlexRow, Icon, SpinnerNew, Text, Title } from 'Components/Atoms';
import { OverlayTrigger, Tooltip } from "Atoms/Overlay";
import moment from 'moment';
import ImageBox from 'Components/App/ImageBox';
import { setDebugVariable } from '@f2w/utils';
import { Header, Section } from 'Atoms/Layout/components';
import { CalculationServices, DistributionKeyServices, } from 'Pages/AncillaryExpense';
import { CalculationAccountTable, CalculationFeesTable } from 'Pages/AncillaryExpense/table';
import { CalculationWizardStyled } from '../CalculationWizardStyled';
import { DataTable } from '@f2w/data-table';
import { DistributionsSection, useDistributionSection } from '../CalculationDistributionSection';
import { updateFloatingContribution } from 'Pages/AncillaryExpense/services/calculationServices';
import generateRoute from "Services/Router";
import { useDispatcherApi, useModalDispatcherConfirm } from 'Components/Dispatcher';
import { VKAImportModal } from "Pages/AncillaryExpense/components/CalculationPage/components/VKAImportModal";
import { useCalculationWizardEditBasicsForm } from "Pages/AncillaryExpense/hooks/useCalculationWizardEditBasicsForm";
import { CalculationFinancialAccountStatement } from "Pages/AncillaryExpense/components/CalculationPage/components/CalculationFinancialAccountStatement";
import { DropdownButton } from "Components/Atoms/Button";
import * as accountServices from "../../services/accountServices";
import { VKAExportModal } from "Pages/AncillaryExpense/components/Export/VKAExportModal";
export const CalculationView = ({ calculationId, overviewData, calculationServices = CalculationServices, distributionKeyServices = DistributionKeyServices }) => {
    const { toast: toastApi } = useDispatcherApi();
    const [propertyDetails, setPropertyDetails] = React.useState();
    const [refreshData, setRefreshData] = React.useState();
    const [selectedRentalObjectType, setSelectedRentalObjectType] = React.useState();
    const [importModalOpen, setImportModalOpen] = React.useState(false);
    const [exportModalOpen, setExportModalOpen] = React.useState(false);
    const [exportAccounts, setExportAccounts] = React.useState(undefined);
    const [selectedFinancialAccount, setSelectedFinancialAccount] = React.useState(undefined);
    const ancillaryExpenseAccountForm = useCalculationWizardEditBasicsForm({
        initialValues: {
            administrativeCost: overviewData.administrativeCost
        },
        onSubmit: async (basicDetails) => { },
    });
    const reloadDistributions = React.useCallback(() => {
        refreshData?.();
    }, [refreshData]);
    const [calculationFeesEditMode, setCalculationFeesEditMode] = React.useState(false);
    const calculationFeesTable = CalculationFeesTable.use({
        calculationId,
        calculationServices,
        reloadDistributions,
        editMode: calculationFeesEditMode,
        setEditMode: setCalculationFeesEditMode,
    });
    const onCalculationFeesEdit = useModalDispatcherConfirm({
        onConfirm: async () => {
            calculationFeesTable.setEditMode(true);
            setCalculationFeesEditMode(true);
        },
        message: translate('vka.tableEditWarning.title'),
        help: translate('vka.tableEditWarning.body'),
        btnConfirm: translate('vka.tableEditWarning.confirm'),
        btnCancel: translate('vka.tableEditWarning.cancel')
    });
    const onViewFinancialAccount = (id, name) => {
        setSelectedFinancialAccount({ id, name });
    };
    const [calculationAccountsEditMode, setCalculationAccountsEditMode] = React.useState(false);
    const calculationAccountsTable = CalculationAccountTable.use({
        calculationId,
        calculationServices,
        reloadDistributions,
        onViewFinancialAccount,
        editMode: calculationAccountsEditMode,
        setEditMode: setCalculationAccountsEditMode,
    });
    const distributionSection = useDistributionSection({
        calculationId,
        calculationServices,
        selectedRentalObjectType
    });
    const onRecalcFloatingAndFlatFees = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: async () => {
            calculationFeesTable.updateApi.resetAll();
            await calculationServices.listCalculationFees(calculationId, { recalculate: true });
            await calculationFeesTable.refresh();
            await reloadDistributions();
        },
        message: translate('ancillaryExpense.recalcConfirmation.title'),
        help: translate('ancillaryExpense.recalcConfirmation.text'),
        btnConfirm: translate('ancillaryExpense.recalcConfirmation.actionAccept'),
        btnCancel: translate('ancillaryExpense.recalcConfirmation.actionCancel'),
    });
    const onRecalcAccounts = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: async () => {
            calculationAccountsTable.updateApi.resetAll();
            await calculationServices.listCalculationAccounts(calculationId, { recalculate: true });
            await calculationAccountsTable.refresh();
            await reloadDistributions();
        },
        message: translate('ancillaryExpense.recalcConfirmation.title'),
        help: translate('ancillaryExpense.recalcConfirmation.text'),
        btnConfirm: translate('ancillaryExpense.recalcConfirmation.actionAccept'),
        btnCancel: translate('ancillaryExpense.recalcConfirmation.actionCancel'),
    });
    const onCalculationAccountsEdit = useModalDispatcherConfirm({
        onConfirm: async () => {
            calculationAccountsTable.setEditMode(true);
            setCalculationAccountsEditMode(true);
        },
        message: translate('vka.tableEditWarning.title'),
        help: translate('vka.tableEditWarning.body'),
        btnConfirm: translate('vka.tableEditWarning.confirm'),
        btnCancel: translate('vka.tableEditWarning.cancel')
    });
    const onImport = () => {
        setImportModalOpen(true);
    };
    const onExport = () => {
        accountServices.listExportableAccountsForProperty(propertyDetails.id)
            .then((accounts) => {
            setExportAccounts(accounts);
            setExportModalOpen(true);
        });
    };
    setDebugVariable({ calculationFeesTable, calculationAccountsTable });
    React.useEffect(() => {
        calculationServices
            .getCalculationPropertyDetails(overviewData.propertyId)
            .then(setPropertyDetails);
    }, [overviewData.propertyId]);
    const globalLoading = (isGlobalLoading) => {
        calculationAccountsTable.setLoading(isGlobalLoading);
        calculationFeesTable.setLoading(isGlobalLoading);
        distributionSection.setIsLoading(isGlobalLoading);
    };
    const isLoading = calculationAccountsTable.isLoading || calculationFeesTable.isLoading || distributionSection.isLoading;
    const getBasics = async () => {
        await ancillaryExpenseAccountForm.submitForm();
        if (ancillaryExpenseAccountForm.isValid) {
            return ancillaryExpenseAccountForm.values;
        }
        return null;
    };
    const [isSaveDraftLoading, setSaveDraftLoading] = React.useState(false);
    const saveDraft = async () => {
        const basics = await getBasics();
        if (!basics) {
            return;
        }
        globalLoading(true);
        setSaveDraftLoading(true);
        try {
            await calculationServices.saveDraft(calculationId, basics);
            toastApi.success({ message: translate('ancillaryExpenseCalculation.actions.saveDraft.message.success') });
        }
        finally {
            setSaveDraftLoading(false);
            globalLoading(false);
        }
        window.location.href = generateRoute('ancillaryExpenses.overview');
    };
    const [isBillCalculationLoading, setBillCalculationLoading] = React.useState(false);
    const billCalculation = async () => {
        const basics = await getBasics();
        if (!basics) {
            return;
        }
        globalLoading(true);
        setBillCalculationLoading(true);
        try {
            await calculationServices.saveDraft(calculationId, basics);
            await calculationServices.saveAndBillCalculation(calculationId);
            toastApi.success({ message: translate('ancillaryExpenseCalculation.bill.success') });
            window.location.href = generateRoute('ancillaryExpenses.overview');
        }
        catch (requestError) {
            const errorMessage = (requestError.response.status === 400) ? requestError.response.data.message : translate('ancillaryExpenseCalculation.bill.error');
            toastApi.error({
                timeout: 5,
                message: errorMessage,
            });
        }
        finally {
            setBillCalculationLoading(false);
            globalLoading(false);
        }
    };
    const isSaving = isSaveDraftLoading || isBillCalculationLoading;
    if (!propertyDetails) {
        return _jsx(SpinnerNew, {});
    }
    return (_jsxs(CalculationWizardStyled, { children: [_jsxs(Header, { style: { display: "flex" }, children: [_jsxs(Title.H1, { h4: true, style: { flex: 1 }, children: [translate('ancillaryExpense'), " - ", translate('property'), " ", propertyDetails.name] }), _jsxs(Div, { style: { textAlign: "center", fontSize: 13 }, children: [_jsx(Div, { children: _jsx("a", { target: "_blank", href: "https://help.fairwalter.com/vka-export-und-import", children: translate('vka.info.about') }) }), _jsxs(DropdownButton, { disabled: isLoading, label: translate('vka.interface.button'), children: [_jsx(Dropdown.Item, { onClick: () => onExport(), children: translate('vka.action.export') }), _jsx(Dropdown.Item, { onClick: () => onImport(), children: translate('vka.action.import') })] })] }), exportModalOpen && _jsx(VKAExportModal, { calculationId: calculationId, exportAccounts: exportAccounts, modalOpen: exportModalOpen, onClose: () => {
                            setExportModalOpen(false);
                            setExportAccounts([]);
                        } }), importModalOpen && _jsx(VKAImportModal, { calculationId: calculationId, onClose: (needsReload) => {
                            setImportModalOpen(false);
                            if (needsReload) {
                                reloadDistributions();
                            }
                        } })] }), _jsx(Text, { large: 500, children: translate('fromTo', {
                    from: moment(overviewData.periodFrom).format('DD.MM.YYYY'),
                    to: moment(overviewData.periodTo).format('DD.MM.YYYY'),
                }) }), _jsx(Section, { children: _jsxs(FlexRow, { gap: 40, children: [_jsx(FlexCol, { gap: 15, flexGrow: 1, children: _jsx(FlexRow, { gap: 15, children: ancillaryExpenseAccountForm.render() }) }), _jsx(ImageBox, { style: { height: '100%', minHeight: 100 }, src: propertyDetails.primaryImage?.path })] }) }), _jsxs(Header.Sticky, { children: [_jsxs(Title.H2, { h6: true, children: [translate('ancillaryExpenseCalculation.floatingAndFlatFees.title'), _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: `tooltip-vla`, children: translate('ancillaryExpenseCalculation.floatingAndFlatFees.callout') }), children: _jsx(Icon, { style: { fontSize: "initial", marginLeft: 10 }, icon: "help-circle" }) })] }), _jsx(ButtonToolbar, { children: calculationFeesEditMode ?
                            _jsx(_Fragment, { children: _jsxs(FlexRow, { alignItems: 'center', gap: 15, children: [calculationFeesTable.updateApi.dirtyIds.length > 0 && _jsx("span", { children: translate('ancillaryExpenseCalculation.floatingAndFlatFees.pendingUpdates', { numPending: calculationFeesTable.updateApi.dirtyIds.length }) }), _jsxs(ButtonToolbar, { children: [_jsx(Button, { outline: true, color: 'error', style: { border: 'none' }, label: translate('btn.cancel'), onClick: () => {
                                                        setCalculationFeesEditMode(false);
                                                        calculationFeesTable.updateApi.resetAll();
                                                    } }), _jsx(Button, { label: translate('btn.save'), onClick: async () => {
                                                        const updateApi = calculationFeesTable.updateApi;
                                                        const updateData = updateApi.dirtyIds
                                                            .map((id) => {
                                                            const { ancillaryExpenseFloatingAmount } = updateApi.getData(id);
                                                            return {
                                                                contractId: Number(id),
                                                                amount: Number(ancillaryExpenseFloatingAmount)
                                                            };
                                                        })
                                                            .filter(({ amount }) => !isNaN(amount));
                                                        if (updateData.length > 0) {
                                                            await updateFloatingContribution(calculationId, updateData);
                                                            await calculationFeesTable.refresh();
                                                            updateApi.resetAll();
                                                        }
                                                        setCalculationFeesEditMode(false);
                                                    } })] })] }) })
                            :
                                _jsxs(_Fragment, { children: [_jsx(Button, { onClick: onRecalcFloatingAndFlatFees, disabled: calculationFeesTable.isLoading, children: translate('updateData') }), _jsx(Button, { onClick: async () => { await onCalculationFeesEdit(); }, disabled: calculationFeesTable.isLoading, children: translate('btn.edit') })] }) })] }), _jsx(Section, { children: _jsx(DataTable.SimpleTable, { instance: calculationFeesTable, filter: "inline" }) }), _jsxs(Header.Sticky, { children: [_jsxs(Title.H2, { h6: true, children: [translate('ancillaryExpenseCalculation.accountOverview'), _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'ancillary-expense-calculation-account-info', children: translate('ancillaryExpenseCalculation.accountOverview.info') }), children: _jsx(Icon, { style: { fontSize: "initial", marginLeft: 10 }, icon: "help-circle" }) })] }), _jsx(ButtonToolbar, { children: calculationAccountsEditMode ?
                            _jsx(_Fragment, { children: _jsxs(FlexRow, { alignItems: 'center', gap: 15, children: [calculationAccountsTable.updateApi.dirtyIds.length > 0 && _jsx("span", { children: translate('ancillaryExpenseCalculation.floatingAndFlatFees.pendingUpdates', { numPending: calculationAccountsTable.updateApi.dirtyIds.length }) }), _jsxs(ButtonToolbar, { children: [_jsx(Button, { outline: true, color: 'error', style: { border: 'none' }, label: translate('btn.cancel'), onClick: () => {
                                                        setCalculationAccountsEditMode(false);
                                                        calculationAccountsTable.updateApi.resetAll();
                                                    } }), _jsx(Button, { label: translate('btn.save'), onClick: async () => {
                                                        const updateApi = calculationAccountsTable.updateApi;
                                                        const updateData = updateApi.dirtyIds
                                                            .map((id) => {
                                                            const { expenseId: accountId, financialAccountId } = updateApi.getData(id);
                                                            const data = updateApi.getUpdateData(id);
                                                            const amount = parseFloat(data.amount + '');
                                                            return { accountId, financialAccountId, amount };
                                                        })
                                                            .filter(({ amount }) => !isNaN(amount));
                                                        if (updateData.length > 0) {
                                                            calculationServices
                                                                .updateCalculationAccounts(calculationId, updateData)
                                                                .then(async () => {
                                                                await calculationAccountsTable.refresh();
                                                                await updateApi.resetAll();
                                                                reloadDistributions();
                                                            });
                                                        }
                                                        setCalculationAccountsEditMode(false);
                                                    } })] })] }) })
                            :
                                _jsxs(_Fragment, { children: [_jsx(Button, { disabled: calculationAccountsTable.isLoading, onClick: onRecalcAccounts, children: translate('updateData') }), _jsx(Button, { disabled: calculationAccountsTable.isLoading, onClick: async () => {
                                                await onCalculationAccountsEdit();
                                            }, children: translate('btn.edit') })] }) })] }), _jsxs(Section, { children: [selectedFinancialAccount && (_jsx(CalculationFinancialAccountStatement, { financialAccountId: selectedFinancialAccount.id, calculationId: calculationId, periodFrom: overviewData.periodFrom, periodTo: overviewData.periodTo, onClose: () => setSelectedFinancialAccount(undefined), getFinancialAccountStatement: CalculationServices.getFinancialAccountStatement })), _jsx(DataTable.SimpleTable, { instance: calculationAccountsTable, filter: "inline" })] }), _jsx(Header.Sticky, { children: _jsx(Title.H2, { h6: true, children: translate('ancillaryExpenseCalculation.distributionAndCalculation') }) }), _jsx(Section, { pt: 0, pb: 80, children: _jsx(DistributionsSection, { distributionSection: distributionSection, calculationId: calculationId, overviewData: overviewData, isLoading: isSaving, getDistributionKeysForProperty: distributionKeyServices.getDistributionKeysForProperty, calculationServices: calculationServices, propertyId: propertyDetails.id, setRefreshData: setRefreshData, setSelectedRentalObjectType: setSelectedRentalObjectType, selectedRentalObjectType: selectedRentalObjectType }) }), _jsxs(Div, { style: { position: "fixed", zIndex: 101, right: 20, bottom: 0, padding: 20, width: "100%", background: "#fff", textAlign: "right" }, children: [_jsx(Button, { label: translate('btn.saveAndClose'), loading: isSaveDraftLoading, disabled: isLoading || (isSaving && !isSaveDraftLoading), onClick: () => saveDraft(), style: { marginRight: 20 } }), _jsx(Button, { label: translate('ancillaryExpenseCalculation.actions.billCalculation'), loading: isBillCalculationLoading, disabled: isLoading || (isSaving && !isBillCalculationLoading), onClick: () => billCalculation() })] })] }));
};
