import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import AbstractNavItem from 'Components/Base/AbstractNavItem';
import { Icon } from 'Atoms/Icon';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import { isString } from 'Utils/types';
import styled from 'styled-components';
import { navSlot } from './Nav';
import { ButtonSelector } from '../Button';
export const NavLink = forwardRef(({ disabled, className, href, eventKey, onSelect, nowrap, label, children, as = NavLinkRoot, ...rest }, ref) => {
    return (_jsxs(AbstractNavItem, { ...navSlot.attr('link'), ...rest, href: href, ref: ref, eventKey: eventKey, as: as, disabled: disabled, onSelect: onSelect, className: classNames(className, 'fw-nav-link', {
            'disabled': disabled
        }), children: [label && _jsx("span", { children: label }), React.Children.map(children, (child) => {
                if (isString(child)) {
                    return _jsx("span", { children: child });
                }
                return child;
            })] }));
});
NavLink.displayName = 'NavLink';
export const NavLinkRoot = styled(SafeAnchor)(({ nowrap, theme: { typography, palette }, }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    padding: 'var(--fw-nav-item-padding-y, 14px) var(--fw-nav-item-padding-x, 16px)',
    flexWrap: 'nowrap',
    whiteSpace: nowrap ? "nowrap" : "inherit",
    transitionDuration: '250ms',
    transitionTimingFunction: 'ease-in',
    transitionProperty: 'background-color, border-color, opacity, color',
    color: palette.dark.$500,
    gap: 8,
    ...typography.body.fontStyles,
    [`${Icon}`]: {
        fontSize: 'calc(1em + 4px)',
        marginTop: -2,
    },
    [`&${ButtonSelector.is('disabled')}`]: {
        pointerEvents: "none",
        cursor: "default",
        color: palette.dark.$200,
    },
    [`&${ButtonSelector.is('hover', 'focus')}`]: {
        textDecoration: 'none',
        color: palette.primary.$500,
    },
}));
