import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ButtonColorDeprecated, buttonSizeToken, ButtonVariantTokens, getButtonVariant, getButtonVariantStyles, } from './Button.tokens';
import { Icon } from 'Atoms/Icon';
import styled, { css } from 'styled-components';
import { toStr } from '@f2w/utils';
import { cssVars, ThemeProvider, tokenGetStyles } from '@f2w/theme';
import classnames from 'classnames';
export * from './Button.tokens';
export const ButtonTheme = ({ children, size, color, variant, }) => {
    const tokens = useMemo(() => ({ size, color, variant }), [size, color, variant]);
    return (_jsx(ThemeProvider, { tokens: tokens, children: children }));
};
const StyledButtonRoot = styled.button
    .attrs(({ variant, noGap, theme, ...rest }) => {
    variant = getButtonVariant(variant, theme?.tokens?.variant, rest);
    return ({
        variant,
        className: classnames('fw-btn', {
            'fw-btn-nogap': noGap || variant === 'link',
        }),
    });
})
    .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['color', ...Object.keys(ButtonVariantTokens)].includes(prop),
})((props) => [baseStyles(props), buttonTokens(props)]);
StyledButtonRoot.defaultProps = {
    truncate: true
};
StyledButtonRoot.displayName = 'StyledButton';
export { StyledButtonRoot as StyledButton };
function buttonTokens(props) {
    const { $color, theme: { palette, tokens }, $size = tokens?.size, color: _colorName = tokens?.color, variant: _variant = tokens?.variant, rounded, reverse, theme, block, dark, ...rest } = props;
    const styleDeps = Object.keys(ButtonVariantTokens).map(key => rest[key]);
    const [_cssVars, _css] = useMemo(() => {
        const colorName = palette?.hasOwnProperty(_colorName) ? _colorName : 'primary';
        const color = palette[colorName];
        const variantCss = (toStr($color)
            ? tokenGetStyles(ButtonColorDeprecated, $color, 'primary')?.(theme)
            : getButtonVariantStyles(_variant, rest, 'fill')?.(theme, {
                color,
                colorName,
                dark
            }));
        const { lineHeight, borderRadius, paddingX, paddingY, height, ...styles } = buttonSizeToken.getStyles($size, { theme });
        if (block) {
            styles.width = '100%';
            styles.justifyContent = 'center';
            styles.flex = 1;
        }
        return [
            cssVars({
                lineHeight,
                borderRadius: rounded ? '50%' : borderRadius,
                paddingX,
                paddingY,
                borderSize: 1,
                minHeight: height,
            }, 'btn-'),
            {
                ...styles,
                ...variantCss,
            }
        ];
    }, [_colorName, _variant, $size, block, $color, ...styleDeps]);
    return {
        ..._cssVars,
        flexDirection: reverse ? 'row-reverse' : 'row',
        transitionProperty: 'color, background-color, border-color, box-shadow',
        transitionDuration: '150ms',
        transitionTimingFunction: 'ease-in',
        gap: '.65em',
        ..._css,
        [`${Icon}, .fw-icon`]: {
            fontSize: 'calc(1em + 2px)',
        },
    };
}
function baseStyles(p) {
    return css `
        display: inline-flex;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        flex-wrap: nowrap;
        white-space: nowrap;

        font-size: var(--btn-font-size, inherit);
        line-height: var(--btn-line-height, 1.25em);

        padding: var(--btn-padding-y, 0) var(--btn-padding-x, 0);
        margin: 0;
        gap: .5em;
        min-height: var(--btn-min-height, auto);
        min-width: var(--btn-min-height, auto);

        user-select: none;
        cursor: pointer;
        color: inherit;

        outline: none;
        border: var(--btn-border-size, 0) solid transparent;
        border-radius: var(--btn-border-radius, 0);
        background-color: transparent;

        ${p.truncate ? css `
            max-width: 100%;
            > span {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                min-width: 0;
            }
        ` : undefined};


        &.fw-btn-icon {
            --btn-padding-x: 0;
        }

        &:hover {
            outline: 0;
            text-decoration: none;
        }

        &:is(:focus, .focus) {
            outline: 0;
        }

        &:is(.disabled, :disabled, [aria-disabled=true], [aria-busy=true]) {
            user-select: none;
            cursor: default;
        }
    `;
}
