import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon } from "Components/App/Icons";
import { translate } from "Services/Translator";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const Actions = ({ cell, row, refresh, onEditDamage, onDeleteDamage }) => {
    const value = cell.value;
    return (_jsx(ActionsDropdown, { id: row.id, quickActions: [{
                icon: 'edit',
                title: translate('btn.edit'),
                onClick: () => onEditDamage(value)
            }], children: _jsxs(ActionsDropdown.Link, { onClick: (e) => {
                e.preventDefault();
                onDeleteDamage(value, refresh);
            }, children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }) }));
};
export default Actions;
