import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { useAncillaryExpenseCalculationsTable } from '../../table';
import { CalculationServices } from '../../index';
export const CalculationsTab = ({ calculationServices = CalculationServices }) => {
    const { openViewModal } = useObjectViewModalContext();
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    React.useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            instance.refresh();
        }
    }, [activeView]);
    const { instance } = useAncillaryExpenseCalculationsTable({
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        calculationServices,
    });
    return (_jsx(_Fragment, { children: _jsx(PageOverview, { instance: instance, inlineFilters: true }) }));
};
