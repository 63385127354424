import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from "Services/Translator";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import BankAccountTableModel from "Pages/BankAccount/tables/BankAccountTableModel";
import { BankAccountView } from "Pages/BankAccount/components/DetailView/BankAccountView";
import { useCreateBankAccountForm } from "Pages/BankAccount/hooks/useCreateBankAccountForm";
import { FormModal } from "@f2w/form";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { MergeBankAccountsModal } from "Pages/BankAccount/components/MergeBankAccountsModal";
export const BankAccountPage = ({ bankAccountId: editBankId, bankAccountServices, openCreateModal, iban }) => {
    const [fixedBankType, setFixedBankType] = React.useState(openCreateModal && typeof openCreateModal !== 'number'
        ? openCreateModal
        : undefined);
    const [mergeBankAccount, setMergeBankAccount] = React.useState();
    const [bankAccountData, setBankAccountData] = React.useState(editBankId
        ? { id: editBankId, asEdit: true }
        : undefined);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const loadBankAccounts = React.useCallback(() => {
        table?.setLoading(true);
        bankAccountServices
            .listBankAccounts()
            .then(data => {
            setBankAccounts(data);
        })
            .finally(() => {
            table?.setLoading(false);
        });
    }, []);
    const { error: errorToast } = useToastDispatcherApi();
    const deleteBankAccount = React.useCallback((bankAccountId) => {
        bankAccountServices
            .deleteBankAccount(bankAccountId)
            .then(loadBankAccounts)
            .catch((error) => {
            if (error.response.status === 400) {
                errorToast({
                    timeout: 3,
                    message: error.response.data
                });
            }
        });
    }, []);
    const table = BankAccountTableModel.useCreateTable({
        deleteBankAccount,
        openCreateForm: () => form.openForm(),
        openDetailsView: (id, asEdit = false) => setBankAccountData({ id, asEdit }),
        data: bankAccounts,
        openMergeModal: (bankAccount) => setMergeBankAccount({
            id: bankAccount.id,
            iban: bankAccount.iban || bankAccount.qrIban,
            type: bankAccount.type,
            label: bankAccount.label
        })
    });
    const form = useCreateBankAccountForm({
        createBankAccount: bankAccountServices.createBankAccount,
        onSubmit: () => {
            loadBankAccounts();
            setFixedBankType(undefined);
        },
        fixedBankType: fixedBankType,
        initialIban: iban
    });
    React.useEffect(() => {
        loadBankAccounts();
        if (openCreateModal && !bankAccountData) {
            form.openForm();
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: table, title: translate('bankAccounts.title'), inlineFilters: true }), _jsx(FormModal, { modal: form, onClose: () => setFixedBankType(undefined) }), bankAccountData &&
                _jsx(BankAccountView, { reloadTable: loadBankAccounts, bankAccountData: bankAccountData, onClose: () => setBankAccountData(undefined), updateBankAccountProperties: bankAccountServices.updateBankAccountProperties, getBankAccountDetails: bankAccountServices.getBankAccountDetails, updateRentBankAccount: bankAccountServices.updateRentBankAccount, updatePersonBankAccount: bankAccountServices.updatePersonBankAccount, updateCreditBankAccount: bankAccountServices.updateCreditBankAccount }), mergeBankAccount && (_jsx(MergeBankAccountsModal, { sourceBankAccount: mergeBankAccount, onClose: () => {
                    setMergeBankAccount(undefined);
                    loadBankAccounts();
                }, getBankAccounts: bankAccountServices.getBankAccounts, mergeBankAccounts: bankAccountServices.mergeBankAccounts }))] }));
};
