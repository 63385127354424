import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import AutoInvoicingTableModel from './AutoInvoicingTableModel';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import YearSelect from 'Components/App/YearSelect';
import MonthSelect from 'Components/App/MonthSelect';
import { FlexRow } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { useToastDispatcherApi } from "Components/Dispatcher";
import { formatYearMonthInverse } from "Services/DateHelper";
import moment from "moment/moment";
import { useAutomaticInvoicingFormModal } from "Pages/AutoInvoicing/hooks/useAutomaticInvoicingFormModal";
import { FormModal } from "@f2w/form";
import { generateAutoInvoiceForPeriods } from "Pages/Contract/services/autoInvoicingServices";
import { getScopeFilters } from "Services/ScopeManager";
import { usePortfolio } from "Components/Base/PortfolioContext";
function useAutoInvoicingTable({ showAddModal, downloadInvoices }) {
    const tableModel = React.useMemo(() => AutoInvoicingTableModel.create({
        manualControl: true,
        FiltersLayout: ({ renderFilter, instance }) => {
            const currentYear = (new Date()).getFullYear();
            const year = instance.state.filters.find(({ id }) => id === 'year')?.value;
            const month = instance.state.filters.find(({ id }) => id === 'month')?.value;
            return _jsx(_Fragment, { children: _jsxs(FlexRow, { gap: 20, alignCenter: true, children: [_jsxs(Text, { small: 500, children: [translate('automaticInvoices.period'), ":"] }), _jsx("div", { style: { minWidth: 200 }, children: _jsx(YearSelect, { min: currentYear - 6, max: currentYear + 5, isClearable: true, value: year, onChange: (option) => {
                                    instance.setFilter('year', option?.value ?? null);
                                } }) }), _jsx("div", { style: { minWidth: 200 }, children: _jsx(MonthSelect, { isClearable: true, value: month, onChange: (option) => {
                                    instance.setFilter('month', option?.value ?? null);
                                } }) })] }) });
        },
    }), []);
    const getData = React.useCallback(async ({ request }) => {
        const url = generateRoute('api.automaticInvoicing.list');
        return (await client.get(url, { params: request })).data;
    }, []);
    return tableModel.useTable({
        getData,
        downloadInvoices,
        showAddModal,
    });
}
const AutoInvoicingPage = () => {
    const [dates, setDates] = useState({ years: { min: 2020, max: 2030 } });
    const toastApi = useToastDispatcherApi();
    const portfolio = usePortfolio();
    const instance = useAutoInvoicingTable({
        showAddModal: () => form.openForm(),
        downloadInvoices: async (periodIds, withQR) => {
            const { propertyId, rentalObjectId } = getScopeFilters(portfolio);
            await generateAutoInvoiceForPeriods(periodIds, withQR, propertyId, rentalObjectId);
            toastApi.success({
                message: translate('autoInvoicing.downloadScheduled'),
            });
        }
    });
    useEffect(() => {
        client.get(generateRoute('api.automaticInvoicing.dates'))
            .then(response => response.data)
            .then(setDates);
    }, []);
    const { success } = useToastDispatcherApi();
    const form = useAutomaticInvoicingFormModal({
        periodMin: dates.years.min,
        periodMax: dates.years.max,
        onSave: (period, nbInvoices, nbIgnoredProperties) => {
            success({
                message: translate('automaticInvoices.success', {
                    period: formatYearMonthInverse(moment(period)),
                    nbInvoices: nbInvoices,
                }) + (nbIgnoredProperties > 0
                    ? translate('automaticInvoices.success.ignoredProperties', { nbIgnoredProperties: nbIgnoredProperties })
                    : ''),
                timeout: 5,
                showRemove: true
            });
            instance.refresh();
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, title: translate('automaticInvoices.title'), inlineFilters: true }), _jsx(FormModal, { modal: form })] }));
};
export default AutoInvoicingPage;
