import { isString } from 'lodash';
import { translate } from 'Services/Translator';
import { SimpleValue, SimpleValueType, } from './base';
import { Yup } from '../base';
export class BooleanValueType extends SimpleValueType {
    __fw_type_name = 'boolean';
    constructor(props) {
        super(props);
    }
    createValue(props) {
        return new SimpleValue(this._type, props);
    }
    get schema() {
        return super.schema;
    }
    _createSchema() {
        return new Yup.BooleanSchema();
    }
    _parse(value) {
        if (value != null) {
            return !['no', 'false', 'nein', '0', ''].includes(String(value).toLowerCase());
        }
        if (this.isNullable)
            return null;
        return false;
    }
    _typeCheck(v) {
        if (v instanceof Boolean)
            v = v.valueOf();
        return typeof v === 'boolean';
    }
    getConstraints(props) {
        const { mutate } = props;
        const getMessageTrans = (value, message, fallback) => ((isString(value) ? value : message));
        return {
            ...super.getConstraints(props),
            isFalse(value, message) {
                mutate((s) => s.isFalse(getMessageTrans(value, message, translate('form.validation.noLessThan'))));
                return value;
            },
            isTrue(value, message) {
                mutate((s) => s.isTrue(getMessageTrans(value, message, translate('form.validation.noLessThan'))));
                return value;
            },
        };
    }
}
