const dot = require('dot-object');
const Mustache = require('mustache');
function escapeRegExp(str) {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
function replaceVars(str, vars) {
    const varNames = Object.keys(vars)
        .map((varName) => escapeRegExp(varName))
        .join('|');
    const re = new RegExp('{{(' + varNames + ')}}', 'gi');
    return str.replace(re, function (_, templateVar) {
        return vars[templateVar];
    });
}
export function renderTemplate(template, vars) {
    let html = Mustache.render(template, vars);
    const dotVars = dot.dot(vars);
    html = replaceVars(html, dotVars);
    return html;
}
