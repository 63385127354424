import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import * as ObjectViewModalServices from './services/objectViewModalServices';
import { ObjectViewModal } from './ObjectViewModal';
const UseObjectViewModalContext = React.createContext({
    openViewModal: () => () => {
    },
    openViewModalObj: () => {
    },
});
export const UseObjectViewModalProvider = ({ children, objectViewModalServices = ObjectViewModalServices, }) => {
    const [activeViewInner, setActiveViewInner] = React.useState();
    const openViewModal = React.useCallback((view) => (id) => {
        setActiveViewInner({ id: id, view: view });
    }, []);
    const openViewModalObj = React.useCallback((activeView) => {
        setActiveViewInner(activeView);
    }, []);
    return (_jsxs(UseObjectViewModalContext.Provider, { value: {
            openViewModal,
            openViewModalObj,
            activeView: activeViewInner
        }, children: [children, _jsx(ObjectViewModal, { activeView: activeViewInner, objectViewModalServices: objectViewModalServices, onClose: () => setActiveViewInner(undefined) })] }));
};
export const useObjectViewModalContext = () => React.useContext(UseObjectViewModalContext);
export const useOpenObjectViewModal = (view) => {
    const { openViewModalObj } = useObjectViewModalContext();
    return useCallback((id, asEdit = false) => {
        return openViewModalObj({
            view,
            id,
            asEdit
        });
    }, [view, openViewModalObj]);
};
