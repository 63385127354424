import { isPlainObject, isString } from 'lodash';
import { parseJson } from 'Utils/parseJson';
import { isBool } from '@f2w/utils';
import { BaseValueType, createSpecsApi, TypeSpecs, Yup, } from '../base';
import { ObjectMap } from './ObjectMap';
import { ObjectValue } from './ObjectValue';
export class ObjectValueType extends BaseValueType {
    __fw_type_name = 'object';
    _shape;
    _specs;
    static define(props) {
        const t = new ObjectValueType(props.type, props);
        return t.setShape(props.shape());
    }
    constructor(type, props) {
        super(props);
        this._type = type;
        this._shape = ObjectMap.create({
            onAdd: (key, child) => {
                return TypeSpecs.getValueType(child)?.$$bindParent(this._type, key);
            }
        });
        const s = createSpecsApi({
            schemaType: () => (new Yup.ObjectSchema()),
            defaultOptions: () => ({ ...props?.defaultOptions?.() }),
            getOptionsModifiers: (p) => {
                const own = p.own;
                return {
                    ...super.getOptionsModifiers(p),
                    inherit: {
                        getDefault: () => ({}),
                        set: (value, prev) => {
                            return isBool(value) ? { view: value, data: value } : { ...prev, ...value };
                        },
                        getters: {
                            inheritData: () => own.inherit?.data ?? false,
                            inheritView: () => own.inherit?.view ?? false,
                            inheritView2: (own) => own?.view ?? false,
                        },
                    },
                };
            },
            getConstraints: (props) => {
                const { mutate } = props;
                return {};
            },
        });
        this._specs = s;
    }
    setInherit(inherit) {
        this.options.inherit = inherit;
    }
    get inherit() {
        return this.inheritView && this.inheritData;
    }
    get inheritView() {
        return !!this.options.inherit.view;
    }
    get inheritData() {
        return !!this.options.inherit.data;
    }
    get shape() {
        return this._shape;
    }
    get fields() {
        return this._shape.fields;
    }
    setShape(shape) {
        this._shape.reset(shape);
        return this;
    }
    createValueType(type, props) {
        const objValue = new ObjectValue(type, { ...props });
        this.shape?.forEach((child, key) => objValue.register(key, TypeSpecs.getValueType(child)));
        return objValue;
    }
    createValue(props) {
        return this.createValueType(this._type, props);
    }
    _typeCheck(v) {
        return isPlainObject(v) || typeof v === 'function';
    }
    _parse(value) {
        if (value) {
            if (isString(value))
                value = parseJson(value);
            if (this.test(value))
                return value;
        }
        if (this.isNullable)
            return null;
        return {};
    }
    static parse(value, options) {
        if (value) {
            if (isString(value))
                value = parseJson(value);
            if (isPlainObject(value))
                return value;
        }
        if (options?.nullable)
            return null;
        return {};
    }
}
