import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ObjectViewModalServices } from 'Components/ObjectViewModal';
import { useDispatcherApi } from 'Components/Dispatcher';
import * as StatsService from 'Services/BaseDataStatsService';
import { translate } from 'Services/Translator';
import { useEventCallback } from '@restart/hooks';
import { isAccountOwner } from "Services/UserManager";
import RentServices from "Pages/Rent/services";
export function createObjectDeleteConfirm({ message, containingMessage, notDeletableMessage, title = translate('modalDispatcher.areYouSure'), stats: { nbProperties, nbBuildings, nbRentalObjects, nbContracts, nbCreditorInvoices, nbDebitorInvoices, nbAncillaryExpenseDistribution, nbAccountingRecords, nbDamages, nbCreditorInvoicesWithDistributedPositions, creditorInvoicesWithDistributedPositions }, entity, ...props }) {
    const confirm = {
        ...props,
        message: title,
        severity: 1,
    };
    if (entity === 'property' && !isAccountOwner()) {
        confirm.severity = 4;
        confirm.onConfirm = undefined;
        confirm.content = translate('property.delete.errors.onlyOwnerCanDelete');
        return confirm;
    }
    const notDeletable = nbAncillaryExpenseDistribution > 0
        || nbCreditorInvoices > 0
        || nbDebitorInvoices > 0
        || nbAccountingRecords > 0;
    if (notDeletable) {
        if (entity === 'property') {
            if (nbCreditorInvoicesWithDistributedPositions > 0) {
                confirm.severity = 4;
                confirm.onConfirm = undefined;
                confirm.content = _jsx(_Fragment, { children: renderPropertyNotDeletableMessage(translate('property.delete.errors.distributedCreditorInvoicePositions', { numberOfInvoices: nbCreditorInvoicesWithDistributedPositions }), creditorInvoicesWithDistributedPositions) });
            }
            else {
                confirm.severity = 4;
                confirm.doubleConfirm = translate('modalDispatcher.delete.keywords');
                confirm.content = _jsx(_Fragment, { children: renderInfoList(notDeletableMessage, containingMessage, {
                        nbBuildings,
                        nbRentalObjects,
                        nbCreditorInvoices,
                        nbDebitorInvoices,
                        nbAncillaryExpenseDistribution,
                        nbAccountingRecords,
                        nbDamages
                    }) });
            }
        }
        else {
            confirm.onConfirm = undefined;
            confirm.severity = 4;
            confirm.content = _jsx(_Fragment, { children: renderInfoListForNotDeletable(notDeletableMessage, {
                    nbCreditorInvoices,
                    nbDebitorInvoices,
                    nbAncillaryExpenseDistribution,
                    nbAccountingRecords
                }) });
        }
    }
    else {
        confirm.content = _jsx(_Fragment, { children: renderInfoList(message, containingMessage, {
                nbBuildings,
                nbRentalObjects,
                nbContracts,
                nbDamages
            }) });
        if (nbContracts) {
            confirm.severity = 3;
        }
        else if (nbRentalObjects) {
            confirm.severity = 2;
        }
    }
    return confirm;
}
const renderReferencingEntitiesList = (list) => {
    return _jsx("ul", { children: Object.entries(list).map(([key, value]) => {
            if (value > 0) {
                return _jsx("li", { children: translate(`modalDispatcher.${key}`, { nb: value }) }, `spec-${key}`);
            }
        }) });
};
const renderInfoList = (message, containingMessage, list) => {
    const showAdditional = Object.entries(list)
        .map(([key, value]) => value)
        .filter(val => val > 0)
        .length > 0;
    return _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx("b", { children: showAdditional ? containingMessage : message }) }), renderReferencingEntitiesList(list), showAdditional && _jsx("p", { dangerouslySetInnerHTML: { __html: translate('modalDispatcher.delete.recordsMessage') } })] });
};
const renderInfoListForNotDeletable = (message, list) => {
    return _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx("b", { children: message }) }), renderReferencingEntitiesList(list)] });
};
const renderPropertyNotDeletableMessage = (message, list) => {
    return _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx("b", { children: message }) }), _jsx("ul", { children: list.map((value, key) => {
                    return _jsx("li", { children: translate('modalDispatcher.creditorInvoicesWithDistributedPositions', { reference: value.reference }) }, `spec-${key}`);
                }) })] });
};
export function useObjectDeleteApi({ objectService = ObjectViewModalServices, rentService = RentServices, statsService = StatsService, onSuccess } = {}) {
    const { modal: modalApi, toast: toastApi } = useDispatcherApi();
    const deleteProperty = useEventCallback((id) => {
        modalApi.show({
            severity: 1,
            asyncLoad: async () => {
                const { nbProperties, ...stats } = await statsService.getPropertyStats(id);
                return createObjectDeleteConfirm({
                    stats,
                    message: translate('modalDispatcher.deleteProperty.message'),
                    containingMessage: translate('modalDispatcher.deleteProperty.containingMessage'),
                    notDeletableMessage: translate('modalDispatcher.deleteProperty.notDeletableMessage'),
                    onConfirm: async () => {
                        objectService.deleteProperty(id)
                            .then(() => {
                            toastApi.success({
                                timeout: 5,
                                message: translate('property.successfullyDeleted'),
                            });
                            onSuccess?.();
                        })
                            .catch((e) => {
                            if (e.isAxiosError && e.response.status === 400) {
                                toastApi.error({
                                    message: translate(e.response.data.message)
                                });
                                return;
                            }
                            toastApi.error({
                                timeout: 5,
                                message: translate('baseData.property.delete.error'),
                            });
                        });
                    },
                    entity: 'property',
                });
            },
        });
    });
    const deleteBuilding = useEventCallback((id) => {
        modalApi.show({
            severity: 1,
            asyncLoad: async () => {
                const { nbProperties, nbBuildings, ...stats } = await statsService.getBuildingStats(id);
                return createObjectDeleteConfirm({
                    stats,
                    message: translate('modalDispatcher.deleteBuilding.message'),
                    containingMessage: translate('modalDispatcher.deleteBuilding.containingMessage'),
                    notDeletableMessage: translate('modalDispatcher.deleteBuilding.notDeletableMessage'),
                    onConfirm: (...args) => {
                        objectService.deleteBuilding(id)
                            .then(() => {
                            toastApi.success({
                                timeout: 5,
                                message: translate('building.successfullyDeleted'),
                            });
                            onSuccess?.();
                        })
                            .catch(() => {
                            toastApi.error({
                                timeout: 5,
                                message: translate('baseData.building.delete.error'),
                            });
                        });
                    },
                    entity: 'building',
                });
            },
        });
    });
    const deleteRentalObject = useEventCallback((id) => {
        modalApi.show({
            severity: 1,
            asyncLoad: async () => {
                const { nbProperties, nbBuildings, nbRentalObjects, ...stats } = await statsService.getRentalObjectStats(id);
                return createObjectDeleteConfirm({
                    stats,
                    message: translate('modalDispatcher.deleteRentalObject.message'),
                    containingMessage: translate('modalDispatcher.deleteRentalObject.containingMessage'),
                    notDeletableMessage: translate('modalDispatcher.deleteRentalObject.notDeletableMessage'),
                    onConfirm: (...args) => {
                        objectService.deleteRentalObject(id)
                            .then(() => {
                            toastApi.success({
                                timeout: 5,
                                message: translate('rentalObject.successfullyDeleted'),
                            });
                            onSuccess?.();
                        })
                            .catch(({ response }) => {
                            if (response.data) {
                                toastApi.error({
                                    timeout: 5,
                                    message: translate('rentalObject.delete.associationsBlocking', {
                                        linkages: response.data.map(error => translate(error)).join(', '),
                                    }),
                                });
                            }
                            else {
                                toastApi.error({
                                    timeout: 5,
                                    message: translate('baseData.rentalObject.delete.error'),
                                });
                            }
                        });
                    },
                    entity: 'rentalObject',
                });
            },
        });
    });
    const deleteRentalObjectRoom = useEventCallback((id) => {
        modalApi.show({
            message: translate('baseData.areYouSure'),
            help: translate('rentalObjectRoom.delete.help'),
            onConfirm: (...args) => {
                objectService.deleteRentalObjectRoom(id)
                    .then(() => {
                    toastApi.success({
                        timeout: 5,
                        message: translate('rentalObjectRoom.successfullyDeleted'),
                    });
                    onSuccess?.();
                });
            },
        });
    });
    const deleteRent = useEventCallback((id) => {
        modalApi.show({
            message: translate('rent.delete.help'),
            onConfirm: (...args) => {
                rentService.deleteRent(id)
                    .then(() => {
                    toastApi.success({
                        timeout: 5,
                        message: translate('rent.successfullyDeleted'),
                    });
                    onSuccess?.();
                })
                    .catch((e) => {
                    if (e.isAxiosError && e.response.status === 400) {
                        toastApi.error({
                            message: translate(e.response.data.message)
                        });
                        return;
                    }
                    throw e;
                });
            },
        });
    });
    return {
        deleteProperty,
        deleteBuilding,
        deleteRentalObject,
        deleteRentalObjectRoom,
        deleteRent,
    };
}
